.shippingOptionsContainer {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: -80px;
    left: -10px;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(12px);
    z-index: 2;
}

.cartStoreDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    margin: 10px 0 0 0;
    background-color: #6D0F0D;
  
    border-radius: 20px;
    height: fit-content;
    font-family: 'Poppins';
  }

  
.cartStoreDivHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
  }
  
.cartStoreName, .cartStoreTotal {
    display: flex;

    margin: 10px 10px 0 10px;
    font-weight: bold;
    padding: 5px;
    color: #FEC09A;
    cursor: default;
}
  
.cartStoreProducts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 20px;
    background-color: #FEC09A;
    width: calc(100% - 20px);
    margin: 10px;
}
  
.cartStoreOptionDiv {
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin: 0 0 20px 10px;
}

.cartShippingOptionsDiv {
    display: flex;
    flex-direction: row-reverse;
    width: calc(100% - 20px);
    margin: 0 10px 10px 10px;    
    box-sizing: border-box;
}
  
.cartStoreShippingOptions {
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 14px;
    background-color: #000000;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    padding: 3px 15px 3px 15px;
    width: fit-content;
    color: white;
    cursor: pointer;
}
  
.cartExpandFiltersBtn {
    float: right;
    border-radius: 0px 0px 10px 10px;
    width: fit-content;
    background-color: #ED8951;
    padding: 3px 15px 3px 15px;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    font-family: 'Poppins';
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    margin-left: 15px;
}
  
.cartDropdownIcon {
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 7.5px;
  
    cursor: pointer;
    transform: rotate(180deg);
}
  
.cartDropdownIcon2 {
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 7.5px;
  
    cursor: pointer;
}

.shippingOptionsDiv {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 10px;
    left: 0;
    right: 0;
    width: 95vw;
    margin: 0 auto;
    max-height: 90vh;
    overflow-y: scroll;
    height: fit-content;
    background: linear-gradient(to right, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.7) 100%);
    backdrop-filter:  saturate(180%)  blur(40px);
    -webkit-backdrop-filter: blur(40px);
    border-radius: 25px;
    padding: 8px 8px 0px 8px;
    box-sizing: border-box;
    box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.4), rgba(0, 0, 0, 0.4) 0px 30px 35px -5px, rgba(0, 0, 0, 0.2) 0px 10px 10px -5px;
    z-index: 1005;
}

.bundlingButtonContainer {
    display: flex;
    justify-content: flex-end;
}

.bundlingOptionsButton {
    float: right;
    display: flex;
    flex-direction: row;
    width: fit-content;
    font-family: 'Poppins';
    height: fit-content;
    border-radius: 10px 15px 10px 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
    padding: 5px;
    margin-bottom: 10px;
    cursor: pointer;
}

.bundlingOptionsButton:hover {
    box-shadow: none;
}

.bundlingOptionsButtonText {
    margin: 0px 5px;
    font-size: 14px;
}

.bundlingOptionsButtonIcon {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-right: 5px;
}

.bundledOptionsDiv {
    display: flex;
    flex-direction: column;
    width: fit-content;
    font-family: 'Poppins';
    height: fit-content;
    border-radius: 15px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
    padding: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    height: fit-content;
}

.bundlingOptionsDivText {
    font-size: 14px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 15px;
    background-color: #f67c3a7c;
    text-align: center;
    margin-bottom: 5px;
}

.bundledOptionComponentPrice {
    font-weight: bold;
    margin: 5px 0px 0px 0px;
}

.bundledOptionComponentText {
    margin-left: 5px;
}

.bundledOptionComponentDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: 5px;
    background: #f5f5f55e;
    height: fit-content;
    border-radius: 15px;
    box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.5), 2px 2px 4px rgba(0, 0, 0, 0.45);
    box-sizing: border-box;
}

.bundlingDivTextAndLogo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 15px;
}

.bundledOptionComponentLogo {
    height: 60px;
    width: 85px;
    margin-right: 10px;
    margin: 5px;
    border-radius: 15px;
}

.bundledOptionComponentText {
    font-size: 12px;
    height: fit-content;
}

.bundledOptionComponentCount {
    padding: 5px 10px;
    box-sizing: border-box;
    margin-right: 10px;
    background-color: #ED8951;
    border-radius: 5px;
}

.productShippingOptions {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 10px;
    box-sizing: border-box;
}

.productShippingOptionBlock {
    flex-grow: 1;
    width: 100%;
}

.shippingOptionContainer {
    display: flex;
    flex-direction: column;
    width: calc(100% - 80px);
    margin-left: 10px;
}

.shippingSubgroupSelect {
    width: 100%;
}

.shippingSubgroupButton {
    padding: 10px 5px 10px 8px;
    background-color: white;
    border-radius: 15px;
}

.shippingMethod {
    display: flex;
    flex-direction: column;
    align-items: top;
    width: 100%;
    margin-bottom: 10px;
    margin-right: 10px;
    background: #f5f5f55e;
    height: fit-content;
    border-radius: 15px;
    box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.5), 2px 2px 4px rgba(0, 0, 0, 0.45);
}

.dropdown-open {
    height: 100%;
}

.shippingOptionDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 10px; 
}

.cartShippingOptionLogo {
    width: 65px;
    height: 50px;
    margin: 5px 10px 5px 5px;
    border-radius: 12px;
}

.shippingSubgroup {
    font-family: 'Poppins';
    font-size: 12px;
    width: 100%;
}

.shippingOptionTextContainer {
    width: 100%;
}

.shippingOptionComponentDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.shippingOptionTextAndLogo {
    display: flex;
    align-items: center;
}

.shippingOptionText {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 12px;
    font-family: 'Poppins';
    color: black;
}

.shippingOptionName {
    margin-bottom: 5px;
    cursor: default;
}

.shippingOptionPrice {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 5px;
}

.productShippingOptionImg {
    height: 80px;
    width: 80px;
    border-radius: 12px;
    object-fit: cover;
    margin-right: 10px;
}

.shippingMethodCheckbox {
    width: 20px;
    height: 20px;
    border: none;
    margin: 10px;
    background-color: white;
    border-radius: 5px;
    box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
    cursor: pointer;
}

.closeShippingPopupButton {
    width: 100%;
    background-color: black;
    font-family: 'Poppins';
    border-radius: 15px;
    color: white;
    margin-bottom: 10px;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
}

.closeShippingPopupButton:hover {
    filter: invert(1);
}

.totalStoreShippingDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-family: 'Poppins';
    border-radius: 15px;
    box-sizing: border-box;
    margin-bottom: 10px;
    background-color: #f67c3ae4;
    padding: 5px;
    justify-content: space-between;
}

.totalStoreShippingText, .totalStoreShippingValue {
    padding: 5px;
    font-size: 14px;
    font-weight: bold;
    width: fit-content;
    cursor: default;
} 

.estimatedTime {
    background-color: #0000002c;
    margin: 5px;
    padding: 5px;
    border-radius: 10px;
    font-family: 'Poppins';
    font-size: 14px;
}