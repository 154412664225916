.favouritesHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 80px;
}

.favouritesHeaderContainer1 {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 80px;
}

.favouritesHeadingDiv {
  white-space: nowrap;
  border-radius: 15px;
  padding: 5px 10px;
  margin-left: 5px;
  height: 45px;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.8);
  background-color: #D75759;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
}

.favouritesHeadingText {
  font-family: 'Poppins', sans-serif;
  height: fit-content;
  margin: 0px;
  font-weight: 900;
  color: #841f21;
  cursor: default;
}

.favouritesBack {
  white-space: nowrap;
  border-radius: 15px;
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.8);
  background-color: #D75759;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
}

.favouritesBackIcon {
  width: 35px;
  height: 35px;
  padding: 5px 15px;
  filter: opacity(0.6);
}

.favouritesBack:hover {
  filter: brightness(50%);
  box-shadow: none;
}

.favouritesBackIcon:hover {
  filter: opacity(0.8);
}

.favouritesNotificationIcon {
  width: 35px;
  height: 35px;
  filter: brightness(0) saturate(100%) invert(13%) sepia(80%) saturate(3980%) hue-rotate(349deg) brightness(71%) contrast(85%);
  margin-right: 5px;
  cursor: pointer;
}

.favouritesNotificationIcon:hover {
  filter: invert(14%) sepia(55%) saturate(4162%) hue-rotate(344deg) brightness(82%) contrast(90%);
}

.favouritesNotificationBadge {
  position: absolute;
  top: -10px;    /* Adjust as necessary */
  right: -10px;  /* Adjust as necessary */
  padding: 5px;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.favouritesLoadingDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  filter: brightness(0);
  margin: 40px auto;
  animation: spin 2s infinite linear;
}

.favouritesLoadingIcon {
  width: 40px;
  height: 40px;
}

.emptyFavouritesStoresDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Poppins';
  position: absolute;
  cursor: default;
  left: 0;
  width: 100%;
  text-align: center;
  height: inherit;
}

.emptyFavouritesDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Poppins';
  position: absolute;
  cursor: default;
  left: 0;
  width: 100%;
  text-align: center;
  height: 70px;
}

.favouritesStoreHeading {
  font-family: 'Poppins';
  font-weight: 900;
  font-size: 18px;
  color: #841F21;
  margin: 10px 15px 0px 15px;
  cursor: default;
}

.favouritesAssests {
  position: absolute;
  height: fit-content;
  width: 100%;
  z-index: 3;
  left: 0;
}

.favouritesStoresSection {
  height: 180px;
  align-self: flex-start;

  padding: 0 0 10px 7px;
  overflow-x: scroll;

  display: flex;
  flex-direction: column;
  height: 165px;
  background: #D75759;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
  z-index: 3;
}

.favouritesStoresSectionShadow {
  position: absolute;
  left: 0;
  top: 45px;
  z-index: -2;
  display: flex;
  flex-direction: row;

  margin: 0px;
  width: 100%;
  height: 140px;
  background: #841F21;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
}

.favouritesProductsSection {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); 
  gap: 10px;
  margin: 20px 0 20px 10px;
}

/* Ensures images do not stretch */
.favouritesProductsSection img {
  width: 100%;
  height: auto;
}


@media only screen and (min-width: 600px) {
  .favouritesProductsSection {
    column-count: 3;
    -moz-column-count: 3; 
    -webkit-column-count: 3;
    column-width: 33%;
  }
}

@media only screen and (min-width: 800px) {
  .favouritesProductsSection {
    column-count: 4;
    -moz-column-count: 4; 
    -webkit-column-count: 4;
    column-width: 33%;
  }
}

@media only screen and (min-width: 1000px) {
  .favouritesProductsSection {
    column-count: 4;
    -moz-column-count: 4; 
    -webkit-column-count: 4;
    column-width: 19%;
  }
}

.favouritesLogin {
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 4;
  background: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  overflow: hidden;
  touch-action: none;
}

.favouritesLoginDiv {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.favouritesLoginTextDiv {
  width: 97%;
  border-radius: 15px;
  font-family: 'Poppins';
  text-align: center;
  font-size: 14px;
  background-color: #C9DCB3;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px;
}

@media only screen and (min-width: 450px) {
  .favouritesLoginTextDiv {
    width: 439px;
  }
}