/* Alert.css */
.alert {
  position: fixed;
  left: 0;
  transform: translateX(-50%);
  padding: 16px 24px;
  border-radius: 15px;
  color: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 15px;
  font-size: 14px;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  box-sizing: border-box;
  font-family: 'Poppins'
}

.alert-success {
  background-color: #C9DCB3; /* Green */
}

.alert-error {
  background-color: #FF5757; /* Red */
}
