/* src/admin/components/Transactions.css */

.transactions-component {
  padding: 10px;
  font-family: 'Poppins';
}

.transactions-component h2 {
  margin-bottom: 10px;
}

.transactions-component p {
  margin-bottom: 20px;
}

.error-message {
  color: red;
}

.transactions-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.transaction-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  background-color: #f9f9f9;
}

.transaction-card h3 {
  margin-bottom: 10px;
}

.transaction-details {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.detail-group {
  flex: 1 1 200px;
}

.detail-group h4 {
  margin-bottom: 5px;
}

.mark-paid-button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #4CAF50; /* Green */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.mark-paid-button:hover {
  background-color: #45a049;
}

.mark-paid-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 600px) {
  .transaction-details {
    flex-direction: column;
  }

  .detail-group {
    flex: 1 1 100%;
  }
}
