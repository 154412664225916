/* PendingProduct.css */

.pendingProductContainer {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  background-color: #fff;
}

.productHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.storesProductName, .storesProductPrice {
  text-align: left;
  margin: 0;
}



.productName {
  font-size: 1.2em;
  font-weight: bold;
}

.productPrice {
  color: #555;
}

.ordersDropdownIcon {
  width: 12px;
  height: 7.5px;
  margin-right: 12px;
  cursor: pointer;
  transform: rotate(180deg)
}

.ordersDropdownIcon.rotate {
  transform: none
}

.buyerInfoFields {
  display: flex;
  flex-direction: column;
}

.buyerInfoField {
  margin-bottom: 8px;
}

.trackingNumContainer {
  margin-top: 16px;
}

.trackingNumInput {
  box-sizing: border-box;
  height: 30px;
  margin: 0px 5px;
  width: calc(100% - 10px);
  border-radius: 5px;
  border: none;
  background-color: #f5f5f5;
  padding-left: 5px;
  font-family: 'Poppins';
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);

}

.parcelImageUpload {
  margin-top: 16px;
}

.submitButtonContainer {
  margin-top: 16px;
}

.sendInfoButton {
  padding: 10px;
  background-color: black;
  font-family: 'Poppins';
  width: calc(100% - 10px);
  margin: 0 5px;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.sendInfoButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.ordersErrorMessage {
  box-sizing: border-box;
  background: #E87674;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: 'Poppins';
  margin: 10px 5px;
}

.successMessage {
  color: green;
  margin-top: 8px;
}

.storeOrdersProduct {
  display: flex;
  flex-direction: column;

  align-items: center;
  background: #f5f5f55e;
  min-height: 65px;
  max-height: fit-content;
  padding: 5px;
  border-radius: 15px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  margin: 10px 0 0 0;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.storeProductSummaryDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.shippingInfoContainerHeader {
  font-weight: bold;
  margin: 5px 0 10px 0;
}

.buyerInfoContainerHeader {
  font-weight: bold;
  margin: 5px 0 10px 0;
}

.productBuyerInfo {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  margin: 10px;
}

.buyerInfoFields, .shippingInfoContainer {
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  background-color: #b894ca8e;
}
