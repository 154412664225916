.colorPickerWrapper {
  position: relative;
  display: inline-block;
}

.selectedColorSquare {
  width: 36px;
  height: 36px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25),
              2px 2px 4px rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  margin: 5px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.selectedColorSquare:hover {
  transform: scale(1.05);
  box-shadow: -1px -1px 3px rgba(255, 255, 255, 0.3),
              1px 1px 6px rgba(0, 0, 0, 0.5);
}

.selectedColorHeading {
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  margin-left: 10px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
}

.colorPickerTrigger:hover {
  border-color: #888;
}

.colorPickerIcon {
  font-size: 16px;
}

.colorPickerPopover {
  position: absolute;
  z-index: 2;
  top: 40px; /* Adjust position as needed */
  left: 0;
}

.colorPickerCover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

/* src/components/ColorPicker.css */

.colorPickerContainer {
  position: relative;
  display: inline-block;
}

.chooseSpecificColourContainer {
  border-radius: 15px;
}

.colorPickerTriggerSection1 {
  display: flex;
  flex-direction: row;
}

.colorPickerTrigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 15px;

  padding: 5px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
}

.colorPickerDropdownArrow {
  width: 15px;
  height: 7.5px;
  margin-right: 10px;
  margin-bottom: 2px;
  cursor: pointer;
  transform: rotate(180deg);
    filter: opacity(0.7);
}

.chrome-picker {
  border-radius: 12px; /* Rounded edges */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.chromePickerWrapper {
  position: absolute;
  top: 105px; /* Adjust as needed */
  z-index: 2;

  border-radius: 12px;
  width: calc(100% - 45px);
  padding: 10px;

  background: rgba(208, 215, 215, 0.7);
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  
  border-radius: 18px;
  border: solid rgba(255, 255, 255, 0.4) 2px;
  box-shadow: 
    rgba(0, 0, 0, 0.5) 15px 15px 15px -10px, 
    rgba(0, 0, 0, 0.45) 0px 5px 10px -10px, 
    rgba(0, 0, 0, 0.4) 0px 25px 80px -5px, 
    rgba(0, 0, 0, 0.21) 0px 35px 30px -10px, 
    rgba(0, 0, 0, 0.08) 0px 15px 15px -10px;
}

.chromePickerHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0 0 0;
}

.colorDoneBtn, .colorCancelBtn {
  border-radius: 10px;
  background-color: black;
  padding: 5px;
  width: 48.5%;
  font-size: 14px;
  font-family: 'Poppins';
  border: none;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.colorDoneBtn:hover, .colorCancelBtn:hover {
  filter: invert(1)
}

/* Optional: Additional styling for the ChromePicker */

