.frameBar {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: fixed;

  width: 99.9%;
  top: 0;
  left: 0;
  
  border: solid rgba(255, 255, 255, 0.1) 2px;
  border-top: none;
  background: rgba(208, 215, 215, 0.6);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 1500;

  border-radius: 0 0 20px 20px;
  padding: 10px 0;

  box-shadow: 
    rgba(0, 0, 0, 0.18) 10px 5px 15px -10px, 
    rgba(0, 0, 0, 0.05) 0px 5px 10px -10px, 
    rgba(0, 0, 0, 0.155) 0px 25px 40px -5px, 
    rgba(0, 0, 0, 0.01) 0px 35px 10px -10px, 
    rgba(0, 0, 0, 0.08) 0px 15px 15px -10px;
}

.frameBarIconsDiv1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-left: 10px;
}

.frameTypeSection, .frameTypeColour {
  border-radius: 15px;
  height: fit-content;
  box-sizing: border-box;
  background: #f5f5f5;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  margin: 5px
}

.frameTypeSection {
  padding: 5px;
}

.frameTypeSectionOptions {
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.blankFrameBtn, .glassFrameBtn, .lineFrameBtn {
  margin: 0 3px;
  cursor: pointer;
}

.blankFrameBtn {
  height: 30px;
  width: 30px;
}

.glassFrameBtn, .colourFrameBtn {
  height: 35px;
  width: 35px;
}

.colourFrameBtn {
  margin: 0 1px;
  cursor: pointer;
  margin-top: -2px;
}

.frameBorderBtn, .frameOpacityBtn {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.textAlignDiv {
  height: fit-content;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.frameBorderPopupDiv {
  width: fit-content;
}

/* .frameTypesPopup {
  width: 350px;
} */

.frameBorderPopupDiv {
  padding: 5px;
}

.frameBorderPopupDiv, .frameTypesPopup {
  position: fixed;
  top: 70px;
  left: 0;
  font-family: 'Poppins';
  height: fit-content;
  max-height: calc(100vh - 100px);
  width: calc(100% - 20px);
  max-width: 600px;
  box-sizing: border-box;
  margin: 10px;
  
  background: rgba(208, 215, 215, 0.7);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  
  border-radius: 20px;
  border: solid rgba(255, 255, 255, 0.3) 2px;
  box-shadow: 
    rgba(0, 0, 0, 0.5) 15px 15px 15px -10px, 
    rgba(0, 0, 0, 0.25) 0px 5px 10px -10px, 
    rgba(0, 0, 0, 0.155) 0px 25px 40px -5px, 
    rgba(0, 0, 0, 0.01) 0px 35px 10px -10px, 
    rgba(0, 0, 0, 0.08) 0px 15px 15px -10px;
  
  z-index: 1005;
  overflow: scroll;
  cursor: pointer;
}


.noBorderBtn, .lineBorderBtn, .dashedBorderBtn, .dottedBorderBtn {
  height: 58px;
  width: 58px;
  cursor: pointer;
  margin: 5px;
  padding: 5px;
}

.noBorderBtnActive, .lineBorderBtnActive, .dashedBorderBtnActive, .dottedBorderBtnActive {
  height: 58px;
  width: 58px;
  cursor: pointer;
  margin: 5px;

  border-radius: 8px;
  padding: 5px;
  font-family: 'Poppins';
  text-align: center;
  border: none;
  background-color: white;
  border-radius: 15px;
  box-shadow: -2px -2px 2px rgb(255 255 255 / 25%), 2px 2px 4px rgb(0 0 0 / 45%); 
}

.frameBorderWidthInput, .frameBorderRadiusInput {
  height: 25px;
  width: 25px;
  border-radius: 5px;
  border: none;
}

.frameBorderWidth {
  justify-content: space-between;
}

.frameBorderWidth, .frameBorderRadius {
  display: flex;
  flex-direction: row;
  margin: 8px 0;
}

.frameBorderStyleText, .frameBorderWidthText, 
.frameBorderRadiusText, .frameBorderColorText {
  font-weight: bold;
  margin-left: 10px;
}

.frameBorderColorText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;
}

.frameTypeSectionHeader {
  font-weight: bold;
  margin: 5px 10px;
}

.frameBorderRadiusInput, 
.topLeftCornerInput, .topRightCornerInput, 
.bottomLeftCornerInput, .bottomRightCornerInput {
  border-radius: 5px;
  font-family: 'Poppins';
  text-align: center;
  height: 25px;
  width: 25px;
  border: none;
  margin-right: 5px
}

.frameBorderWidthInput, .frameBorderRadiusInput {
  box-shadow: -2px -2px 2px rgb(255 255 255 / 25%), 2px 2px 4px rgb(0 0 0 / 45%); 
}

.topLeftCornerInput, .topRightCornerInput, 
.bottomLeftCornerInput, .bottomRightCornerInput {
  margin-left: 10px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.15), 2px 2px 4px rgb(0 0 0 / 45%); 
}

.frameBorderWidth {
  display: flex;
  border-radius: 12px;
  background-color: rgb(240, 238, 238);
  box-shadow: -2px -2px 2px rgb(255 255 255 / 25%), 2px 2px 4px rgb(0 0 0 / 45%); 
  padding-top: 8px;
  padding-bottom: 8px;
}

.frameBorderRadius, .frameBorderColor {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: rgb(240, 238, 238);
  box-shadow: -2px -2px 2px rgb(255 255 255 / 25%), 2px 2px 4px rgb(0 0 0 / 45%); 
  padding-top: 8px;
  padding-bottom: 8px;
}

.frameBorderRadiusFirstSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.frameBorderRadiusCornerDropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  margin: 5px 5px 0px 5px;
  border-radius: 10px;
  box-shadow: -2px -2px 2px rgb(255 255 255 / 25%), 2px 2px 4px rgb(0 0 0 / 45%); 
  width: calc(100% - 10px);
  box-sizing: border-box
}

.frameCornerSectionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.cornerDropdownContainer {
  width: 100%;
  margin: 10px 5px;
  box-sizing: border-box;
}

.cornerDropdownTopSection, .cornerDropdownBottomSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
}

.frameCornerSection1 {
  display: flex;
  flex-direction: row;
}

.frameCornerIcon {
  height: 20px;
  width: 20px;
  margin: 5px;
  filter: invert(1)
}

.frameCornerSectionText {
  margin: 2px;
}

.frameCornerSectionDropdownIcon, .borderColourSectionDropdownIcon {
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 7.5px;

  cursor: pointer;
  transform: rotate(180deg);
  margin: 5px;
}

.frameCornerSectionDropdownIcon {
  filter: invert(1);
}

.frameBorderWidthInputSection, .frameBorderRadiusInputSection {
  display: flex;
  flex-direction: row;
}

.frameBorderWidthInput, .frameBorderWidthPixelText, 
.frameBorderRadiusInput, .frameBorderRadiusPixelText {
  margin-right: 5px;
}

.topLeftCornerIcon, .topRightCornerIcon, 
.bottomLeftCornerIcon, .bottomRightCornerIcon {
  filter: invert(1);
  height: 30px;
  width: 30px;
}

.topLeftCornerDiv, .topRightCornerDiv, 
.bottomLeftCornerDiv, .bottomRightCornerDiv {
  display: flex;
  align-items: center;
  height: fit-content;
  margin: 10px;
}

.frameTypeColour, .frameBorderColour {
  margin-top: 10px
}

.frameBorderColour {
  width: 350px;
}
