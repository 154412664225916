.storePopupInfoDiv {
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 97.5%;
  height: fit-content;
  z-index: 12;
  left: 5px;
  top: 175px;
  overflow-y: visible;
  border-radius: 20px;
  background: rgba(244, 246, 246, 0.5);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
}

@media only screen and (min-width: 450px) {
  .storePopupInfoDiv {
    width: 440px;
    margin: auto;
  }
}

.closeStorePopup {
  position: absolute;
  top: 10px;
  right: 13px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.marketPopupStoreName {
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 900;
  width: calc(95% - 40px);

  margin: 10px 10px 0 10px;
  color: rgba(0, 0, 0, 0.9);
  cursor: default;
}

.emptyStoreProductsDiv {
  font-family: 'Poppins';
  cursor: default;
}

.marketPopupStoreProductDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  margin: 15px auto;
  height: fit-content;
}

.marketPopupStoreProduct {
  width: 48%;
  max-width: 470px;
  height: 240px;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.visitWebsiteBtn {
  font-family: 'Poppins';
  width: 95%;
  padding: 5px 0 5px 0;
  border-radius: 15px;
  background: #B28666;
  text-align: center;
  color: white;
  margin: auto;
  margin-bottom: 10px;
  cursor: pointer;
}

.visitWebsiteBtn:hover {
  background: #7e5d46;
}
