.websiteInfoDiv {
  display: flex;
  position: fixed;

  flex-direction: column;
  justify-content:center;
  align-items: center;


  bottom: 1.5%;
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 10px;
  height: fit-content;

  border: solid rgba(255, 255, 255, 0.05) 2px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.5);
  font-family: 'Poppins';
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  z-index: 1004;
  cursor: pointer;
  
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 55px 75px -12px, 
              rgba(0, 0, 0, 0.4) 0px 15px 30px -15px,
              rgba(255, 255, 255, 0.1) 0px -5px 25px inset

}

.websiteInfoClose {
  position: absolute;
  top: 15px;
  right: 13px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.websiteInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  gap: 10px
}

.websiteStoreLogo {
  width: 80px;
  height: 80px;
  border-radius: 14px;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
}

.websiteStoreName, .websiteSocialMediaLinks {
  border-radius: 8px;
  padding: 5px;
}

.websiteStoreName {
  font-weight: bold;
}

.websiteDescription {
  border-radius: 10px;
  padding: 10px;
}

.websiteSocialMediaLinks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  padding: 5px 0;
  box-sizing: border-box;
}

.websiteSocialMediaLinkDiv {
  background-color: black;
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
  font-family: 'Poppins';
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  cursor: pointer;
  border: none
}

.websiteSocialMediaLinkDiv:hover {
  background-color: white;
  color: black;
}

.websiteStoreName, .websiteDescription {
  width: 100%;
  background: #f5f5f55e;
  height: fit-content;

  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  box-sizing: border-box;
}