.textPopupFullSection {
  display: flex;
  flex-direction: column;
  width: calc(95% - 135px);
  position: relative;
  border-radius: 15px;
}

.textPopupFullSectionDraggableFrames {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  border-radius: 15px;
}

.textPopupDiv {
  display: flex;
  flex-direction: column;
  align-items: center;

  border: solid rgba(255, 255, 255, 0.2) 2px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  border-radius: 15px;
  background: rgba(208, 215, 215, 0.5);

  -webkit-backdrop-filter: blur(12px);
  -moz-backdrop-filter: blur(12px);
  -o-backdrop-filter: blur(12px);
  -ms-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  z-index: 199;
}

.textPopupTopSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: inherit;
  margin: 5px 10px;
}

.frameTypesDiv {
  display: flex;
  flex-direction: row;
}

.frameTypeButton {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.img:hover::after {
  content: attr(title);
  position: absolute;
  font-family: 'Poppins';
  left: 0;
  top: 100%;
  background: #000;
  color: #fff;
  padding: 4px 8px;
  white-space: nowrap;
}

.leftAlignBtn, .centreAlignBtn, .rightAlignBtn {
  width: 25px;
  height: 25px;
  margin: 0 3px;
  cursor: pointer;
  padding: 5px;
}

.selectedBtn { 
  background: #f5f5f5;
  box-shadow: -2px -2px 2px rgb(255 255 255 / 25%), 2px 2px 4px rgb(0 0 0 / 45%);
  border: 0px;
  border-radius: 10px;
}

.leftAlignBtn:hover, .centreAlignBtn:hover, .rightAlignBtn:hover {
  background: #f5f5f5;
  box-shadow: -2px -2px 2px rgb(255 255 255 / 25%), 2px 2px 4px rgb(0 0 0 / 45%);
  border: 0px;
  border-radius: 10px;
}

.textPopupBottomSection {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  height: 50px;
}

.fontColourIcon {
  width: 40px;
  height: 40px;
  margin: 5px 5px 0 10px;
  cursor: pointer;
}

.fontSelector {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;

  font-family: 'Poppins';
  background: #f5f5f5;
  width: calc(100% - 95px);
  height: 36px;
  margin: 0 5px;
  border-radius: 10px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  text-align: left;
  cursor: pointer;

  box-sizing: border-box;
}

.fontSizeSelector {
  font-family: 'Poppins';
  background: #f5f5f5;
  width: 45px;
  height: 36px;
  margin: 0 8px 0 5px;
  padding-left: 10px;
  border-radius: 10px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  text-align: left;
  cursor: pointer;
}

.currentTextColourOption {
  background-color: black;
  width: 36px;
  height: 36px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  margin: 5px;
  cursor: pointer;
}

.textColourPopupContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(14px);
  height: fit-content;
  border-radius: 25px;
  width: 100%;
} 