
.deliveryNotificationsHeader {
  font-weight: bold;
  font-size: 19.2px;
  font-family: 'Poppins';
  margin: 10px;
  color: #BFB25F;
}

.deliveryNotifications {
  position: relative;
  padding: 5px;
  margin: 10px;
  border-radius: 20px;
  background-color: #585123
}

.parcelImagesSection, .waybillSection, .deliveryNotificationsShippingInfo {
  margin: 5px 0;
  background-color: rgba(0, 0, 0, 0.218);
  border-radius: 10px;
  padding: 10px;
}

.waybillSection {
  display: flex;
  flex-direction: column;
}

.deliveryTrackingBtn, .orderReceivedBtn {
  background-color: black;
  border-radius: 10px;
  color: white;
  width: 100%;
  border: none;
  padding: 10px;
  margin-top: 10px;
  font-family: 'Poppins';
  font-weight: bold;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
}

.noPastOrdersDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: rgb(203, 192, 124);
  font-family: 'Poppins';
  position: relative;
  border-radius: 12px;
  margin: 5px 10px 10px 10px;
}

.productInfoSection {
  display: flex;
  flex-direction: column;

  background: #f5f5f55e;
  padding: 10px;
  border-radius: 10px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  margin: 10px 0;
  font-family: 'Poppins';
  cursor: pointer;
  width: inherit;
}

.deliveryProductDetailItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  width: fit-content;
  border-radius: 8px;
  padding: 5px;
  margin: 0;
  background-color: #585123;
  color: #BFB25F;
  cursor: pointer;
}

.deliveryParcelImage {
  height: 120px;
  width: 100px;
  position: left;
  object-fit: cover;
  border-radius: 5px;
  margin: 10px 0 0 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  z-index: 1;
} 