.paymentPopupLoadingDiv {
  position: absolute;
  top: 0px;

  display: flex;
  justify-content: center;

  width: 100vw;
  height: 100vh;
  padding-top: 30px;
}

.cartLoadingIcon {
  width: 40px;
  height: 40px;
  animation: spin2 2s infinite linear;
}

.paymentPopupContainer {
  position: absolute;
  /* width: 100vw;
  height: 100vh; */
  left: 0;
  top: 0;
  z-index: 1500;
}

.paymentPopupDiv {
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 400px;
  background-color: white;
  border-radius: 20px 20px 0px 0px;
  font-family: 'Poppins';
}

.paymentPopupText {
  margin: 10px;
  font-weight: bold;
  font-size: 32px;
}