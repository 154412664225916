.tagsDiv, .imageTagsDiv{
  display: flex;
  flex-direction: column;
  
  align-items: center;
  position: top;

  background: rgba(208, 215, 215, 0.5);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  box-shadow: -0.5px -0.5px 1px rgba(255, 255, 255, 0.2), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: solid rgba(255, 255, 255, 0.1) 2px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  border-radius: 20px;

  z-index: 4;
  height: fit-content;
  
  top: 5px;
  font-family: 'Poppins';
}

.tagsDiv {
  width: 100%;
  margin: 10px;
}

.imageTagsDiv {
  margin-top: 10px;
}

.activeTagsHeader, .suggestedTagsHeader, .generalTagsHeader, .specificTagsHeader {
  font-size: 16px;
  margin: 5px;
  color: rgba(0, 0, 0, 0.7);
  
}

.styleTagsHeader, .materialTagsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding-left: 8px;
  padding-top: 4px;
  background: linear-gradient(-60deg, #ee0979, #ff6a00);
  background-size: 200%;
  width: 100%;
  height: 30px;
  border-radius: 8px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
}

.generalTagsHeader, .specificTagsHeader, .materialTagsHeader {
  width: 100%;
}

.activeTagsDescription, .suggestedTagsDescription {
  margin: 0 0 5px 5px;
  font-size: 12px;
  font-weight: 500;
}

.activeTagsDescription {
  text-align: center;
  width: 90%;
  color: rgba(0, 0, 0, 0.7);
}

.activeTags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 95%;
}

.generalTags, .specificTags, .styleTags, .materialTags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 98%;
}

.tagItem {
  border-radius: 10px;
  margin: 3px 8px 3px 0;
  background: white;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  padding: 4px 5px 4px 10px;
  text-align: center;
  align-content: center;
  font-size: 14px;
  font-weight: 600;
}

@keyframes flow {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.styleTagsDiv, .materialTagsDiv {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: wrap;
  flex-wrap: wrap;
}

.dropdownIcon {
  width: 15px;
  height: 7.5px;
  margin-right: 10px;
  margin-bottom: 2px;
  cursor: pointer;
  transform: rotate(180deg);
}

.dropdownIcon2 {
  width: 15px;
  height: 7.5px;
  margin-right: 10px;
  margin-bottom: 3px;
  cursor: pointer;
}

.suggestedTagItem {
  border-radius: 10px;
  margin: 5px 8px 5px 0;
  background-color: black;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  color: white;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
}

.styleTagItem, .materialTagItem {
  border-radius: 25px;
  margin: 3px 8px 3px 0;
  background-color: black;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  color: white;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
}

.suggestedTagItem:hover {
  color: black;
  background-color: white;
  box-shadow: none;
}

.tagItem:hover {
  filter: brightness(80%);
  font-weight: bold;
  cursor: pointer;
}

/* .tagText {
  margin: 0 2px 0 10px;
} */

.styleTagText {
  margin: 2px 6px 2px 8px;
}

.suggestedTagText {
  margin: 0 10px;
}

.deleteTag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  
  height: 20px;
  width: 20px;
  background-color: rgba(128, 128, 128, 0.572);
  border-radius: 4px;

  margin-left: 6px;
  margin-top: 2px;
  font-size: 18px;
  cursor: pointer;
}

.deleteTag:hover {
  filter: invert(1);
}

.deleteTagIcon {
  width: 10px;
  height: 10px;
}

.addNewTagDiv {
  margin: 20px 5px 5px 5px;
}

.imageAddNewTagDiv {
  margin: 10px;
}

.addNewTagDiv, .imageAddNewTagDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 96%;
  height: 40px;

  background: white;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  cursor: pointer
}

#addNewTagText {
  align-content: center;
  width: 100%;
  border: none;
  background-color: transparent;
  font-family: 'Poppins';
  margin: 5px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-weight: bold;
}

#addNewTagText:focus {
  outline: none
}

#addNewTagIcon {
  height: 22px;
  align-self: center;
  margin: 5px;
  cursor: pointer
}

.suggestedTags {
  margin: 8px;
  display: flex;

  flex-direction: column;
  padding: 5px;
  padding-bottom: 15px;

  background: white;
  border-radius: 10px;
  height: fit-content;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
}

