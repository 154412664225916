.draggableTextPopupContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  height: fit-content;
  max-width: 500px;
  overflow: visible;

  pointer-events: none; /* Prevents the popup from affecting underlying elements */
}

.draggableTextPopupContainer > * {
  pointer-events: auto; /* Allows interaction with the popup's children */
}

.draggableTextareaOptionsContainer {
  display: flex;
  justify-content: flex-end; /* Changed to flex-end for better alignment */
  height: fit-content;
  z-index: 1000;
}

.draggableTextareaOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  height: 50px;
  padding-left: 5px;
  margin-left: 10px;

  border: solid rgba(255, 255, 255, 0.2) 2px;
  border-radius: 15px;
  background: rgba(208, 215, 215, 0.5);

  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

.draggableTextPopupContainerSection1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: visible
}


