@font-face {
  font-family: 'abigateDesgo';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FabigateDesgo.ttf?alt=media&token=c5572d4e-2a96-441c-a2ad-2cc64748d71b') format('truetype');
}

@font-face {
  font-family: 'abingtonBoldItalic';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FabingtonBoldItalic.ttf?alt=media&token=08348b81-a7d4-40ef-a042-a750d4225721') format('truetype');
}

@font-face {
  font-family: 'aeternus_nano_thin_static';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Faeternus_nano_thin_static.otf?alt=media&token=f64b09ad-785c-4391-997f-ce6f5d810b0b') format('opentype');
}

@font-face {
  font-family: 'ActuallyGrotesk-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FActuallyGrotesk-Regular.otf?alt=media&token=e22e77c0-2457-41b0-b291-7bf7308a0f87') format('opentype');
}

@font-face {
  font-family: 'Ageya';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FAgeya.ttf?alt=media&token=b6e94bd9-bf0c-43e0-ae3f-de45d12c13b5') format('truetype');
}

@font-face {
  font-family: 'AgfiustorFree';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FAgfiustorFree.ttf?alt=media&token=5a76f41c-6888-4787-9792-299752f393fa') format('truetype');
}

@font-face {
  font-family: 'Aion-Bold';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FAion-Bold.otf?alt=media&token=176ca1b1-0173-4b01-bcb5-af6802026210') format('opentype');
}

@font-face {
  font-family: 'Aoudax-Display';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FAoudax-Display.otf?alt=media&token=99531335-6339-4251-85e3-32ef4a1ae647') format('opentype');
}

@font-face {
  font-family: 'ApriAsh_Extended';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FApriAsh_Extended.otf?alt=media&token=5dad67ce-8b1c-4fa7-a9fc-70f1ff47f43a') format('opentype');
}

@font-face {
  font-family: 'Avalancheno';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FAvalancheno.ttf?alt=media&token=3c28496f-e2f8-4d2f-9745-021a49d96d65') format('truetype');
}

@font-face {
  font-family: 'Bagias';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FBagias.ttf?alt=media&token=f18de3ee-2ffa-4cca-b7a6-56a3102f7002') format('truetype');
}

@font-face {
  font-family: 'BarnebokBold';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FBarnebokBold.ttf?alt=media&token=8b4c489d-90ca-4be0-9e14-a4fc4200e0c6') format('truetype');
}

@font-face {
  font-family: 'Baymanat';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FBaymanat.ttf?alt=media&token=778ed4af-16c3-4e12-b485-f2e3f40ca8df') format('truetype');
}

@font-face {
  font-family: 'Behila';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FBehila.ttf?alt=media&token=bb7856fc-2f3f-48ca-b9ad-1c3f89f34223') format('truetype');
}

@font-face {
  font-family: 'Berzulis-GAILA-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FBerzulis-GAILA-Regular.ttf?alt=media&token=379b4d15-d079-47dc-9d7e-77de1d0b4990') format('truetype');
}

@font-face {
  font-family: 'bilboinc-webfont';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Fbilboinc-webfont.ttf?alt=media&token=368c5959-2a30-4de0-a946-91b987a7d901') format('truetype');
}

@font-face {
  font-family: 'BipHop';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FBipHop.otf?alt=media&token=2b2c983d-7946-4259-bb68-b9f6b90efa82') format('opentype');
}

@font-face {
  font-family: 'Black_M';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FBlack_M.otf?alt=media&token=725359d4-ec03-4ba4-9f5e-c20d33935d71') format('opentype');
}

@font-face {
  font-family: 'Blocus';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FBlocus.otf?alt=media&token=9e5ffd00-3f82-4de4-837f-2903853fb376') format('opentype');
}

@font-face {
  font-family: 'blup-Regular_final';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Fblup-Regular_final.ttf?alt=media&token=f2a57add-a8f8-4e8e-bb8e-ac5bfd9d83a7') 
  format('truetype');
}

@font-face {
  font-family: 'Boeticher-Roman';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FBoeticher-Roman.otf?alt=media&token=35dc782d-91fc-4c68-a60e-dea86c8a5029') 
  format('opentype');
}

@font-face {
  font-family: 'Bogam';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FBogam.ttf?alt=media&token=a85d8f1a-0035-47b0-a169-3d28d3a97c78') 
  format('truetype');
}

@font-face {
  font-family: 'Bolgarus';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FBolgarus.otf?alt=media&token=bc9a12da-c407-47ed-bd81-42bc4386033c') 
  format('truetype');
}

@font-face {
  font-family: 'Bolinger';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FBolinger.ttf?alt=media&token=8f67703d-6a73-4def-961f-d88ba79bdfff') 
  format('truetype');
}

@font-face {
  font-family: 'Boxer';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FBoxer.otf?alt=media&token=04ef72be-cbdf-4b7e-b38c-1d20bdef22e4') 
  format('opentype');
}

@font-face {
  font-family: 'Broom';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FBroom.otf?alt=media&token=2502432c-2f8b-4605-986f-a00d089582ea') 
  format('opentype');
}

@font-face {
  font-family: 'celattinfont';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Fcelattinfont.ttf?alt=media&token=f939845e-d746-4ece-a945-914570d4b677') 
  format('truetype');
}

@font-face {
  font-family: 'Chaumont';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FChaumont.otf?alt=media&token=adf9b33d-4416-4112-9055-71ff58103b3f') 
  format('truetype');
}

@font-face {
  font-family: 'Circle';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FCircle.ttf?alt=media&token=f131c466-68ba-40a3-8d5c-27d61578e5f8') 
  format('truetype');
}

@font-face {
  font-family: 'CitationRegular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FCitationRegular.ttf?alt=media&token=528f5b67-fe30-420c-a53d-64b0457113d5') 
  format('truetype');
}

@font-face {
  font-family: 'Collision-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FCollision-Regular.otf?alt=media&token=2626b2a4-c2c0-4d12-8fe3-8c9fb939da02') 
  format('opentype');
}

@font-face {
  font-family: 'Compute';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FCompute.otf?alt=media&token=b0116efa-0cca-4646-83f6-f4ad41f20f4f') 
  format('opentype');
}

@font-face {
  font-family: 'Coqnegre';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FCoqnegre.otf?alt=media&token=c3d35517-bbe8-4fed-bfb8-bd50c6670f6b') 
  format('opentype');
}

@font-face {
  font-family: 'CuteChars';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FCuteChars.otf?alt=media&token=d3eee630-03ac-4374-ba3c-780794a60340') 
  format('opentype');
}

@font-face {
  font-family: 'DAMN';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FDAMN.ttf?alt=media&token=94381ff7-2a00-4832-aadb-5f0d1ce1a35d') 
  format('truetype');
}

@font-face {
  font-family: 'Danzantetypeface-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FDanzantetypeface-Regular.ttf?alt=media&token=2cad2add-f143-4dd4-b416-0bde203f514c') 
  format('truetype');
}

@font-face {
  font-family: 'davidcarson-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Fdavidcarson-Regular.otf?alt=media&token=b25a9d31-56f2-491d-98c4-93b29ffb539b') 
  format('opentype');
}

@font-face {
  font-family: 'Derojela';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FDerojela.ttf?alt=media&token=1859dbec-f4ce-4079-bb3a-b90322c95523') 
  format('truetype');
}

@font-face {
  font-family: 'Distrikt';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FDistrikt.otf?alt=media&token=8a634752-7502-43f1-a451-0ba9348b349f') 
  format('opentype');
}

@font-face {
  font-family: 'DonorType-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FDonorType-Regular.otf?alt=media&token=b864844f-4c6a-4af3-945a-44b6165ca0c1') 
  format('opentype');
}

@font-face {
  font-family: 'DRUZHOK';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FDRUZHOK.otf?alt=media&token=860f9cb7-38b2-4714-ab01-382ce82e2d65') 
  format('opentype');
}

@font-face {
  font-family: 'DTRandomDisplay-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FDTRandomDisplay-Regular.otf?alt=media&token=2daa534c-78ef-4138-bdc0-2516c1e1b7f2') 
  format('opentype');
}

@font-face {
  font-family: 'Elektron';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FElektron.otf?alt=media&token=5c7a51cb-d250-4d86-8fc9-592d24044d11') 
  format('opentype');
}

@font-face {
  font-family: 'EngageRegular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FEngageRegular.ttf?alt=media&token=5877cd24-ec72-4b25-9b78-7df841b19c56') 
  format('truetype');
}

@font-face {
  font-family: 'eunoia';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Feunoia.ttf?alt=media&token=f597f05f-45a3-4431-951e-d89dc9db0d0f') 
  format('truetype');
}

@font-face {
  font-family: 'Excelsiorama-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FExcelsiorama-Regular.ttf?alt=media&token=1f66abef-e6e2-4ec7-b9f6-ca44a4ee3d93') 
  format('truetype');
}

@font-face {
  font-family: 'Experitypo5-MOxv';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FExperitypo5-MOxv.ttf?alt=media&token=2a8d87d0-3746-4001-a2b8-1ca583481f4f') 
  format('truetype');
}

@font-face {
  font-family: 'fabuliste';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Ffabuliste.otf?alt=media&token=7e9ec9ee-87dc-44f4-ab40-69f71b6e6d23') 
  format('opentype');
}

@font-face {
  font-family: 'Fantome-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FFantome-Regular.otf?alt=media&token=059fb4b3-591d-42e2-8dd8-8782f8d73a24') 
  format('opentype');
}

@font-face {
  font-family: 'Fashionable';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FFashionable.ttf?alt=media&token=638e4783-72bd-4c2b-8dc3-6cdfd9955f93') 
  format('truetype');
}

@font-face {
  font-family: 'Firenze';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FFirenze.otf?alt=media&token=46ee704b-37b9-4524-bae1-f91482e340ab') 
  format('opentype');
}

@font-face {
  font-family: 'Five';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FFive.otf?alt=media&token=3227309d-7c97-492c-847d-81f72064bea4') 
  format('opentype');
}

@font-face {
  font-family: 'FleuronMixed';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FFleuronMixed.otf?alt=media&token=bd71e881-745d-4cd5-a2d1-b372c0a1e8c7') 
  format('opentype');
}

@font-face {
  font-family: 'FramesPartOne-Bold';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FFramesPartOne-Bold.otf?alt=media&token=b973baf0-2fe2-4f8d-8e54-7a6628d29af0') 
  format('opentype');
}

@font-face {
  font-family: 'free_jazz';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Ffree_jazz.otf?alt=media&token=26038afe-4304-4ef0-adbc-88c9e5e31c2e') 
  format('opentype');
}

@font-face {
  font-family: 'Galgo';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FGalgo.ttf?alt=media&token=ad5ca699-7393-4522-b709-6fca95ac3e25') 
  format('truetype');
}

@font-face {
  font-family: 'GAWKER-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FGAWKER-Regular.otf?alt=media&token=aca20947-c024-4a41-8991-81c3967270ca') 
  format('opentype');
}

@font-face {
  font-family: 'Geek';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FGeek.otf?alt=media&token=6c96c8f4-51b5-4034-8f65-a78fd03332b6') 
  format('opentype');
}

@font-face {
  font-family: 'GENTODREAM';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FGENTODREAM.ttf?alt=media&token=91bdb1b5-bb6b-439c-a9dd-dc0646aeaefd') 
  format('truetype');
}

@font-face {
  font-family: 'Geodesic-v1';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FGeodesic-v1.otf?alt=media&token=ce5d30ba-63af-422a-809c-071fa9207bbb') 
  format('opentype');
}

@font-face {
  font-family: 'Giga';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FGiga.otf?alt=media&token=237a8150-c795-4a7d-ae3b-99a6628ea726') 
  format('opentype');
}

@font-face {
  font-family: 'Git-Sans';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FGit-Sans.ttf?alt=media&token=1862fc4c-226d-4f7e-834c-e005df3d2f40') 
  format('truetype');
}

@font-face {
  font-family: 'Goat';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FGoat.ttf?alt=media&token=99b5f019-ecb5-4c66-9fea-023f27611932') 
  format('truetype');
}

@font-face {
  font-family: 'GoldenMetaforRegular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FGoldenMetaforRegular.ttf?alt=media&token=294c1d2c-f5cf-436a-bee3-c9c5da9feb83') 
  format('truetype');
}

@font-face {
  font-family: 'Gorki';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FGorki.ttf?alt=media&token=c9d9d5c9-749e-4daa-9db2-8638eb86b43c') 
  format('truetype');
}

@font-face {
  font-family: 'Gourmandise';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FGourmandise.ttf?alt=media&token=7ef4b4a9-a144-4631-b22e-9febbd506e2c') 
  format('truetype');
}

@font-face {
  font-family: 'Grey_Charles';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FGrey_Charles.otf?alt=media&token=8ee9798c-25c7-4499-8865-f0b99e60822e') 
  format('opentype');
}

@font-face {
  font-family: 'Grotesk';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FGrotesk.otf?alt=media&token=48fbe31d-d8e7-4d77-93ce-30a00e731cde') 
  format('opentype');
}

@font-face {
  font-family: 'GTEAPAC';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FGTEAPAC.ttf?alt=media&token=be1ea0af-50f5-4413-9ff6-61c8a3469a6e') 
  format('truetype');
}

@font-face {
  font-family: 'Gulax-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FGulax-Regular.otf?alt=media&token=64892ec9-68f3-4a28-b038-01776905e200') 
  format('opentype');
}

@font-face {
  font-family: 'H2ODisplay';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FH2ODisplay.otf?alt=media&token=30b92b62-1c4d-4bfb-8433-d25b8d103bef') 
  format('opentype');
}

@font-face {
  font-family: 'Hallucinda-Variable';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FHallucinda-Variable.ttf?alt=media&token=37193065-063b-4efc-87b5-8a80a48d8dbf') 
  format('truetype');
}

@font-face {
  font-family: 'HCGleam-Reg';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FHCGleam-Reg.otf?alt=media&token=0a7a541c-b0e3-4517-a583-603e24855325') 
  format('opentype');
}

@font-face {
  font-family: 'Hemingway-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FHemingway-Regular.otf?alt=media&token=af82c634-5cc8-4e2f-b061-8965307aabfd') 
  format('opentype');
}

@font-face {
  font-family: 'Hemis';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FHemis.otf?alt=media&token=def6fed9-fb40-4189-ab8c-975301c5ea06') 
  format('opentype');
}

@font-face {
  font-family: 'Histeria';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FHisteria.ttf?alt=media&token=f7d8d819-1351-42d8-b72e-08fe8b9f4d2f') 
  format('truetype');
}

@font-face {
  font-family: 'hngl';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Fhngl.otf?alt=media&token=3d80699f-a190-4ccb-9c87-1d62419141ac') 
  format('opentype');
}

@font-face {
  font-family: 'Ikapito';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FIkapito.otf?alt=media&token=61f31ea1-db70-4755-b57a-e86dfbd75a10') 
  format('opentype');
}

@font-face {
  font-family: 'Ink';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FInk.otf?alt=media&token=f194a7ff-ada6-4b94-9789-fb2e31465dbf') 
  format('opentype');
}

@font-face {
  font-family: 'Inky';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FInky.otf?alt=media&token=e60bfbeb-00f5-4a6b-988b-5dfb5c084ee9') 
  format('opentype');
}

@font-face {
  font-family: 'Intervals_Variable';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FIntervals_Variable.ttf?alt=media&token=4676fca1-85e0-4573-9890-864fb70b37fd') 
  format('truetype');
}

@font-face {
  font-family: 'Jasper';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FJasper.otf?alt=media&token=d4019465-5b8d-4379-9369-6dd24c552355') 
  format('opentype');
}

@font-face {
  font-family: 'Jax';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FJax.otf?alt=media&token=75f6a2ff-b647-4630-8222-2a0b46315407') 
  format('opentype');
}

@font-face {
  font-family: 'Jester-00-FOOL';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FJester-00-FOOL.otf?alt=media&token=69c9ddab-8c9b-4b5e-bcd0-68f0172154c9') 
  format('opentype');
}

@font-face {
  font-family: 'Jester-08-STRENGTH';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FJester-08-STRENGTH.otf?alt=media&token=a8003e69-6b39-4c71-a2ff-3461e6d0ca90') 
  format('opentype');
}

@font-face {
  font-family: 'Jimmy';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FJimmy.otf?alt=media&token=b465f2bd-1814-499d-9878-b36beb04c1b3') 
  format('opentype');
}

@font-face {
  font-family: 'Jolene';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FJolene.otf?alt=media&token=4d691c32-0b5e-4f17-a50c-f6344e28cb11') 
  format('opentype');
}

@font-face {
  font-family: 'kaerukaeru-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Fkaerukaeru-Regular.otf?alt=media&token=1d292e74-ed35-42f4-b3b7-074e27ab3c5b') 
  format('opentype');
}

@font-face {
  font-family: 'kalmunt';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Fkalmunt.ttf?alt=media&token=478c7aa4-edc6-40e4-be08-08a0915ffced') 
  format('truetype');
}

@font-face {
  font-family: 'kandinsky';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Fkandinsky.ttf?alt=media&token=69938d3d-c72b-4a2e-9a49-399266ceb654') 
  format('truetype');
}

@font-face {
  font-family: 'Kegisiko';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FKegisiko.ttf?alt=media&token=d7e32eb6-e5c5-44d4-8bb7-01c69def078a') 
  format('truetype');
}

@font-face {
  font-family: 'KIK-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FKIK-Regular.ttf?alt=media&token=109ff7f8-5c52-49b7-8eac-eeec0932c4f2') 
  format('truetype');
}

@font-face {
  font-family: 'KUPOLE-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FKUPOLE-Regular.ttf?alt=media&token=12a6e9b8-1dfb-444d-b276-592fb5bcd8d4') 
  format('truetype');
}

@font-face {
  font-family: 'KyivTypeSans-Heavy';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FKyivTypeSans-Heavy.otf?alt=media&token=02b31195-8d26-4c24-aa7c-4bc6079d1800') 
  format('opentype');
}

@font-face {
  font-family: 'KyivTypeSans-Thin';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FKyivTypeSans-Thin.otf?alt=media&token=fe9bd7ef-4a3c-413d-a0a4-85d3ecfe8751') 
  format('opentype');
}

@font-face {
  font-family: 'KyivTypeTitling-VarGX';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FKyivTypeTitling-VarGX.ttf?alt=media&token=b2c2480d-00ac-42da-98b9-784d86062b0d') 
  format('truetype');
}

@font-face {
  font-family: 'KYLTYRE-TECHNICAL';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FKYLTYRE-TECHNICAL.otf?alt=media&token=eda7eedf-8518-48d8-a365-236d2998097b') 
  format('opentype');
}

@font-face {
  font-family: 'Lment2';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FLment2.otf?alt=media&token=c051af8b-b78f-45da-a340-a16dbba8959b') 
  format('opentype');
}

@font-face {
  font-family: 'Lobular-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FLobular-Regular.otf?alt=media&token=2e99a21b-2c01-47f6-8e5a-38a2ba68003a') 
  format('opentype');
}

@font-face {
  font-family: 'lorean';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Florean.otf?alt=media&token=f6f43714-295f-48b2-a12f-696cb3e2fa10') 
  format('opentype');
}

@font-face {
  font-family: 'MangoGrotesque-Bold';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMangoGrotesque-Bold.ttf?alt=media&token=a4d40960-eaed-4c44-814d-81a27479f1e3') 
  format('truetype');
}

@font-face {
  font-family: 'MangoGrotesque-Light';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMangoGrotesque-Light.ttf?alt=media&token=8d3afe9c-c4e0-4fec-9994-5e561dc6ba8d') 
  format('truetype');
}

@font-face {
  font-family: 'MangoGrotesque-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMangoGrotesque-Regular.ttf?alt=media&token=42647124-3d13-485b-8e73-e9c8b7d2c20a') 
  format('truetype');
}

@font-face {
  font-family: 'Martin_Fek_Thirtysixdays';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMartin_Fek_Thirtysixdays.otf?alt=media&token=71f2fa56-3bc1-4a0c-810d-a41aa2a663a1') 
  format('opentype');
}

@font-face {
  font-family: 'Mash-Ultrabold';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMash-Ultrabold.otf?alt=media&token=11623a69-4a2d-4f2b-90c7-53d56904abbd') 
  format('opentype');
}

@font-face {
  font-family: 'Mash-VariableVF';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMash-VariableVF.ttf?alt=media&token=9fe76c9f-0493-42a4-9a42-31c9f23957b3') 
  format('truetype');
}

@font-face {
  font-family: 'Megare';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMegare.otf?alt=media&token=b7efe22b-9296-4e4e-a688-33aaf7e16172') 
  format('opentype');
}

@font-face {
  font-family: 'MegastaSignateriaSignature';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMegastaSignateriaSignature.ttf?alt=media&token=60d0ef2d-3bc5-48b9-b328-b507772ea790') 
  format('truetype');
}

@font-face {
  font-family: 'Metamorphosis';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMetamorphosis.otf?alt=media&token=581fde2a-98a1-4e07-8f53-bcd82931c392') 
  format('opentype');
}

@font-face {
  font-family: 'Metropolis';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMetropolis.otf?alt=media&token=11aa5c1f-6c77-4425-8658-ecd78606d545') 
  format('opentype');
}

@font-face {
  font-family: 'MGGeometricColor-NoColor';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMGGeometricColor-NoColor.otf?alt=media&token=b9b412b0-47cd-48fb-a0df-4b21ada8d0e5') 
  format('opentype');
}

@font-face {
  font-family: 'MGZebra';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMGZebra.otf?alt=media&token=a286580f-a607-4af0-bec4-51101accc685') 
  format('opentype');
}

@font-face {
  font-family: 'MinosBold';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMinosBold.otf?alt=media&token=7474bb1c-49dc-4646-8c03-a00fe44d467f') 
  format('opentype');
}

@font-face {
  font-family: 'MinosRegular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMinosRegular.otf?alt=media&token=992d0465-6634-4163-942c-75cca650c145') 
  format('opentype');
}

@font-face {
  font-family: 'MoaiFreebie-Regular2400';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMoaiFreebie-Regular2400.otf?alt=media&token=992d3d26-2040-4505-993a-c799d6590107') 
  format('opentype');
}

@font-face {
  font-family: 'MolluscaScript';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMolluscaScript.ttf?alt=media&token=bb0d8829-875b-4172-a903-4dba11ab5ed1') 
  format('truetype');
}

@font-face {
  font-family: 'MonkegRegular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMonkegRegular.ttf?alt=media&token=2dd0e307-0638-40ae-baab-89ff1e70ef94') 
  format('truetype');
}

@font-face {
  font-family: 'Montchauve';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMontchauve.otf?alt=media&token=68be792c-5a7a-462d-ba3d-c75c278c923b') 
  format('opentype');
}

@font-face {
  font-family: 'MudClub-FranklintheGoth';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMudClub-FranklintheGoth.otf?alt=media&token=b3dc30f9-3395-42a9-9d49-530d60311ef4') 
  format('opentype');
}

@font-face {
  font-family: 'Munsteria';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FMunsteria.ttf?alt=media&token=9e20b2af-5e40-471f-bb64-cd8fbf52d2b0') 
  format('truetype');
}

@font-face {
  font-family: 'mx-salamander';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Fmx-salamander.ttf?alt=media&token=ea7b0ca8-e3af-4a5d-a9f0-3d2cd410e34a') 
  format('truetype');
}

@font-face {
  font-family: 'Neoptera-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FNeoptera-Regular.ttf?alt=media&token=b9447579-4302-4728-8170-85d98098e79b') 
  format('truetype');
}

@font-face {
  font-family: 'Normant';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FNormant.otf?alt=media&token=34196c9b-4e18-4622-a93a-ab3694a86291') 
  format('opentype');
}

@font-face {
  font-family: 'nuitBlanche';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FnuitBlanche.otf?alt=media&token=4cc940fa-15cf-4a0f-9461-9a99a20508b5') 
  format('opentype');
}

@font-face {
  font-family: 'NuMonoFree';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FNuMonoFree.otf?alt=media&token=2447567c-4e48-46d1-a663-fbbe5ce07e5b') 
  format('opentype');
}

@font-face {
  font-family: 'NW';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FNW.otf?alt=media&token=bb5e3690-9517-45d2-9c5d-4d553ce823ad') 
  format('opentype');
}

@font-face {
  font-family: 'Oceanus_Variable';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FOceanus_Variable.ttf?alt=media&token=41e781e5-441c-4302-9f15-b79d4930b869') 
  format('truetype');
}

@font-face {
  font-family: 'outward-block';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Foutward-block.ttf?alt=media&token=cd185ed0-b65c-4dc0-a352-971c59256a37') 
  format('truetype');
}

@font-face {
  font-family: 'Pierrafeu';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FPierrafeu.otf?alt=media&token=81bf2c95-dad8-45ae-854b-7322779addc7') 
  format('opentype');
}

@font-face {
  font-family: 'Pilowlava-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FPilowlava-Regular.otf?alt=media&token=e9e5f058-667a-422d-bdab-43d4d21912d1') 
  format('opentype');
}

@font-face {
  font-family: 'PsychicFortunes';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FPsychicFortunes.ttf?alt=media&token=2d86df91-ba0d-4c62-98ee-d13dec75ffe6') 
  format('truetype');
}

@font-face {
  font-family: 'Qegor';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FQegor.ttf?alt=media&token=f85e0724-cfcf-4cd5-b30e-12c53037b2d5') 
  format('truetype');
}

@font-face {
  font-family: 'Quarantine';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FQuarantine.otf?alt=media&token=4c44a272-ee17-4134-b5a9-dfa2d725b8e7') 
  format('opentype');
}

@font-face {
  font-family: 'Radikal';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FRadikal.otf?alt=media&token=980e375c-88b7-4049-9f53-f2df0d0b9d8f') 
  format('opentype');
}

@font-face {
  font-family: 'Ramka-Bold';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FRamka-Bold.otf?alt=media&token=2fdbfe49-dca5-4632-8443-43c4867dd62e') 
  format('opentype');
}

@font-face {
  font-family: 'Ramka';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FRamka.otf?alt=media&token=325dc224-4b47-4cdf-9724-a46b604804ff') 
  format('opentype');
}

@font-face {
  font-family: 'RemboyRegular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FRemboyRegular.ttf?alt=media&token=6278d670-2e20-44ff-ada1-8e853382dcc4') 
  format('truetype');
}

@font-face {
  font-family: 'ROLLERALIKA-DemiBold';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FROLLERALIKA-DemiBold.ttf?alt=media&token=85072f82-879c-4fbf-8923-4e9f89e0c865') 
  format('truetype');
}

@font-face {
  font-family: 'Rotunda';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FRotunda.otf?alt=media&token=05bf09bc-d932-44bd-8c56-f55cc8df8f3e') 
  format('opentype');
}

@font-face {
  font-family: 'Rubik-SeaCamouflage-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FRubik-SeaCamouflage-Regular.ttf?alt=media&token=cf0dc2ad-3b16-4aff-a773-a224e1dc41c8') 
  format('truetype');
}

@font-face {
  font-family: 'Semicir';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FSemicir.otf?alt=media&token=8ed12987-6b86-4dc3-9b4f-49bdcf713a99') 
  format('opentype');
}

@font-face {
  font-family: 'Slang';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FSlang.ttf?alt=media&token=366965eb-90bd-43d5-ab32-6c5e2fe9748c') 
  format('truetype');
}

@font-face {
  font-family: 'Soulcraft';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FSoulcraft.ttf?alt=media&token=89536fa3-5f08-4689-a9d2-717a7961742a') 
  format('truetype');
}

@font-face {
  font-family: 'SpaceCowgirl-Bold';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FSpaceCowgirl-Bold.otf?alt=media&token=903e2eca-c32c-4f60-a7fe-7acfacb9d5dd') 
  format('opentype');
}

@font-face {
  font-family: 'SpicyCaps';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FSpicyCaps.otf?alt=media&token=2b4a787c-e2fd-4d79-b7a8-26d7a266cdb7') 
  format('opentype');
}

@font-face {
  font-family: 'Spirt-O_font_beta';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FSpirt-O_font_beta.ttf?alt=media&token=f948f9de-fa40-4afc-b0b1-fb0370b98893') 
  format('truetype');
}

@font-face {
  font-family: 'StarBlackInline';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FStarBlackInline.ttf?alt=media&token=82f332e1-a6ae-40e0-99de-5ec2a3fe5809') 
  format('truetype');
}

@font-face {
  font-family: 'StraratEleganteFont';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FStraratEleganteFont.ttf?alt=media&token=5afd7ed0-5e35-4bff-a217-3b630c9e3fbc') 
  format('truetype');
}

@font-face {
  font-family: 'StreetSignSans';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FStreetSignSans.otf?alt=media&token=88bb059b-6be8-4728-8412-53f5ee21d1fc') 
  format('opentype');
}

@font-face {
  font-family: 'Suspicion_Variable';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FSuspicion_Variable.ttf?alt=media&token=0e019fde-725a-45c1-9cdd-0605637e44a1') 
  format('truetype');
}

@font-face {
  font-family: 'terminal-grotesque';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Fterminal-grotesque.ttf?alt=media&token=92b701f3-0831-4b42-b033-695868228020') 
  format('truetype');
}

@font-face {
  font-family: 'therow';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Ftherow.otf?alt=media&token=99731984-980a-43bd-8ca1-0cd5dd6c6327') 
  format('opentype');
}

@font-face {
  font-family: 'Thunder-BoldHC';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FThunder-BoldHC.ttf?alt=media&token=cf058f17-2db4-4ed0-97ea-e34de9a88757') 
  format('truetype');
}

@font-face {
  font-family: 'Turismo';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FTurismo.otf?alt=media&token=e8c7d403-84c7-4c4b-8449-65c56237c6f7') 
  format('opentype');
}

@font-face {
  font-family: 'Typefesse_Claire-Obscure';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FTypefesse_Claire-Obscure.otf?alt=media&token=59ac86d6-095d-4092-a9e9-85d225ad9fb5') 
  format('opentype');
}

@font-face {
  font-family: 'Typefesse_Pleine';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FTypefesse_Pleine.otf?alt=media&token=03d8f517-7fb5-4fea-9225-6942350dc6ab') 
  format('opentype');
}

@font-face {
  font-family: 'ultraprint';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Fultraprint.otf?alt=media&token=b68eaf21-f637-4893-9e13-5eac9d19166a') 
  format('opentype');
}

@font-face {
  font-family: 'vasilek';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Fvasilek.otf?alt=media&token=5324608f-da4f-4230-bb9e-308abcc49de9') 
  format('opentype');
}

@font-face {
  font-family: 'VTF_Mixo';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FVTF_Mixo.otf?alt=media&token=f151757e-c128-42a2-a3a0-4bd148f6226c') 
  format('opentype');
}

@font-face {
  font-family: 'wakile';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2Fwakile.ttf?alt=media&token=6e969cc0-a897-4e84-9f2e-618e79c701f1') 
  format('truetype');
}

@font-face {
  font-family: 'WARNING';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FWARNING.otf?alt=media&token=be3d07ec-5840-44d1-96e1-962e720f9041') 
  format('opentype');
}

@font-face {
  font-family: 'WataFreeFont';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FWataFreeFont.otf?alt=media&token=aeb4c5db-7c15-432b-a127-7288c76a804a') 
  format('opentype');
}

@font-face {
  font-family: 'WavefontBetaV001-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FWavefontBetaV001-Regular.otf?alt=media&token=d88edf7a-39e6-44e7-b070-edc766ef423d') 
  format('opentype');
}

@font-face {
  font-family: 'Yunke-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FYunke-Regular.otf?alt=media&token=99580ebb-fd09-4bb2-8596-ee5d75083e97') 
  format('opentype');
}

@font-face {
  font-family: 'ZALTYS-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FZALTYS-Regular.ttf?alt=media&token=af823e92-3129-4b2c-93c5-689bf29269dd') 
  format('truetype');
}

@font-face {
  font-family: 'Zimra-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FZimra-Regular.otf?alt=media&token=3d165f3a-2421-4a61-ad10-5a7b0d41b4c8') 
  format('opentype');
}

@font-face {
  font-family: 'Zorz';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FZorz.otf?alt=media&token=08b5dcc4-b096-495f-b6dc-0de2e229555d') 
  format('opentype');
}

@font-face {
  font-family: 'ZtshagoExtrabold';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2FZtshagoExtrabold.otf?alt=media&token=e6369b63-378d-401a-aa19-c4ae4cd2c8a4') 
  format('opentype');
}
