.textColourDiv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: inherit;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(14px);
  border-radius: 15px;
  z-index: inherit;
  padding-bottom: 12px;
} 

.textColourOptions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 330px;
  width: 460px;
}