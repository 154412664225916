/* src/components/InstructionsPopup.css */

.instructionPopupBackground {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.384);
  backdrop-filter: blur(30px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.instructionsPopup {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 60px;
  margin: 10px;
  width: fit-content;
  height: fit-content;
  background-color: #C9DCB3;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 55px 75px -12px, 
              rgba(0, 0, 0, 0.4) 0px 15px 30px -15px,
              rgba(255, 255, 255, 0.1) 0px -5px 25px inset
}

.instructionsContentHeader {
  font-weight: bold;
  font-size: 18px;
  font-family: 'Poppins';
  padding: 10px 0;
  color: rgba(0, 0, 0, 0.595);
  text-align: center;
}

.instructionsContent {
  padding: 10px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.instructionsContentItem {
  margin: 5px 0;
  padding: 5px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  font-family: 'Poppins';
}

.instructionsButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  gap: 10px
}

.closeInstructionPopupBtn,
.remindInstructionPopupBtn {
  flex: 1;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  font-family: 'Poppins';
  background-color: black;
  color: white;
  cursor: pointer;
}

.remindInstructionPopupBtn {
  background-color: #555; /* Different color for distinction */
}


.scoutInfoIcon {
  width: 25px;
  height: 25px;
  filter: opacity(70%);
}

.scoutInfoIconDiv {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;

  bottom: 1.5%;
  right: 1.5%;
  height: 30px;
  width: 30px;
  padding: 5px;
  z-index: 100;
  background-color: #C9DCB3;
  border-radius: 10px;

  cursor: pointer;
}
