.imageGalleryDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(40px);
  z-index: 2005;
}

.closeScoutGalleryBtn:hover {
  filter: invert(1);
}

.closeScoutGalleryBtn {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 10px;
  top: 10px;
  border-radius: 15px;
  width: 50px;
  height: 50px;

  border: solid rgba(255, 255, 255, 0.05) 2px;
  background: rgba(255, 255, 255, 0.5);

  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 1004;

  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  cursor: pointer;
}

.imageGalleryReturnIcon {
  height: 30px;
  width: 30px;
  padding: 5px;
  cursor: pointer;
}

.imageGalleryImageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 130px);
  width: 100%;
}

.imageGalleryMainImage {
  width: 95%;
  height: auto;
  border-radius: 15px
}

.imageGalleryThumbnailContainer {
  border-radius: 10px;
  height: 50px;
  width: fit-content;
  border: solid 2px white;
  box-sizing: border-box;
  overflow: hidden;
  object-fit: contain;

  position: absolute;
  bottom: 70px;
}

.imageGalleryThumbnail {
  width: 50px;
  height: 50px;
  cursor: pointer;
  opacity: 50%;
}

.imageGalleryThumbnail:hover {
  opacity: 100%;
}

.imageGalleryLoadingDiv {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Invert the icon to white */
  filter: brightness(0) invert(1);
  margin: 40px auto;


  left: 50%;

  z-index: 10; /* Ensure it's above the image */
}

.scoutLoadingDiv.hidden {
  opacity: 0;
  pointer-events: none;
}

.scoutLoadingIcon {
  width: 40px;
  height: 40px;
  transition: opacity 1s ease-in-out;
  animation: spin 2s infinite linear;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}