.homeExplainerDiv {
  /* Additional styles if needed */
  overflow: hidden;
  position: relative;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px
}

.explainerContent {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
}

.explainerImg1 {
  position: absolute;
  width: 230px;
  height: 230px;
  bottom: -100px;
  left: -55px;
  z-index: 0;
}

.explainerImg2 {
  position: absolute;
  width: 180px;
  height: 170px;
  top: -30px;
  left: -25px;
  rotate: 4.1deg;
  z-index: 1;
}

.explainerImg3 {
  position: absolute;
  width: 520.6px;
  height: 354.7px;
  top: 100px;
  left: -336px;
  rotate: -100.4deg;
}

.explainerImg4 {
  position: absolute;
  width: 140px;
  height: 500px;
  bottom: -30px;
  z-index: 1;
}

.explainerImg5 {
  position: absolute;
  width: 400px;
  height: 400px;
  top: -150px;
  right: -85px;
  z-index: 0;
  transform: rotate(10deg);
}

.forwardArrowIcon {
  rotate: 180deg;
}

.explainerStep1Container {
  position: absolute;
  width: calc(100% - 20px);
  top: 10px;
  left: 10px;
  height: 250px;
  background-color: #ED8951;
  border-radius: 15px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);

  z-index: 0;
}

.explainerStep1ContainerTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
  
}

.explainerStep1Group1 {
  width: fit-content;
}

.explainerHeaderIconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: fit-content;
  height: fit-content;
}

.explainerEyeIcon, .explainerScoutIcon {
  width: 30px;
  height: 30px;
  opacity: 0.7;
}

.explainerEditIcon {
  width: 27px;
  height: 27px;
  opacity: 0.7;
}

.explainerScoutIcon {
  filter: brightness(0%);
}

.explainerStep1Container2 {
  position: absolute;
  width: calc(100% - 20px);
  top: 270px;
  left: 10px;
  height: calc(100% - 280px);
  background-color: #dba686df;
  backdrop-filter: blur(10px);
  border-radius: 15px;
  z-index: -1;
}

.explainerStep1Description {
  position: absolute;
  top: 40px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 60px);
  backdrop-filter: blur(10px);
  padding: 10px;
  color: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  margin: 10px;
  font-size: 15px;
  font-weight: normal
}

.explainerStep1Header {
  font-weight: bold;
  position: absolute;
  font-size: 25px;
  color: rgba(0, 0, 0, 0.6);
  left: 50px;
  top: 5px;
}

.closedExplainerDiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.closedExplainerArrow {
  width: 20px;
  height: 20px;
  rotate: 180deg;
  filter: invert(69%) sepia(51%) saturate(356%) hue-rotate(14deg) brightness(92%) contrast(90%)
}

.explainerBackArrowButton:hover {
  opacity: 1;
}

.explainerStep2Div {
  position: absolute;
  top: 250px;
}

.explainerStep1C2TextDiv {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: calc(100% - 170px);
  border-radius: 10px;
  right: 0;
  height: calc(100% - 20px);
  margin: 10px;
  z-index: 1;
  gap: 5px;
}

.explainerS1C2Highlight {
  font-weight: normal;
  font-size: 14px;
  color: #FFE5D9;
  background-color: #402d1c;
  padding: 10px;
  border-radius: 10px
}

.explainerS2C1Highlight {
  position: relative;
  top: 120px;
  font-weight: normal;
  font-size: 14px;
  color: #100b07;
  background-color: #8f9693;
  padding: 10px;
  border-radius: 10px;
  z-index: 1;
}

.explainerS2C1Contact {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: fit-content;
  height: 100px;
  font-weight: normal;
  font-size: 14px;
  color: #100b07;
  background-color: #8f9693;
  padding: 10px;
  border-radius: 10px;
  z-index: 1;
}

@media (max-width: 470px) {
  .explainerStep1Description {
    font-size: 13px;
  }

  .explainerS1C2Highlight {
    font-size: 13px;
  }
}