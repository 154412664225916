.dashboardBody {
  padding-bottom: 30px;
}

.dashboardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
}

.dashboardHeaderContainer1 {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 80px;
}

.dashboardHeadingDiv {
  white-space: nowrap;
  border-radius: 15px;
  padding: 5px 10px;
  margin-left: 5px;
  height: 45px;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.8);
  background-color: #B083C6;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
}

.dashboardHeadingText {
  font-family: 'Poppins', sans-serif;
  height: fit-content;
  margin: 0px;
  font-weight: 900;
  color: #473550;
  cursor: default;
}

.dashboardBack {
  white-space: nowrap;
  border-radius: 15px;
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.8);
  background-color: #B083C6;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
}

.dashboardBackIcon {
  width: 35px;
  height: 35px;
  padding: 5px 15px;
  filter: opacity(0.6);
}

.dashboardBack:hover {
  filter: brightness(50%);
  box-shadow: none;
}

.dashboardBackIcon:hover {
  filter: opacity(0.8);
}

.dashboardLogo, .dashboardLogoPlaceholder {

  height: 60px;
  border-radius: 10px;
  margin-right: 2%;
}

.dashboardLogo {
  width: 60px;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
}

.dashboardLogoPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: calc(60px + 2%);
}

.dashboardLogoPlaceholder:hover {
  background-color: #B083C6;
}

.storeInfo {
  width: 95%;
  min-height: auto;
  height: auto;
  padding: 10px;
  border-radius: 20px;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  background: rgba(208, 215, 215, 0.3);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  margin-top: 20px;
  margin-right: 2%;

  font-family: 'Poppins';
  font-size: 14px;
  overflow-y: auto;
}

.storeInfoTextSection {
  display: flex;
  flex-direction: column;
  height: auto;
}

.dashboardContent {
  margin: 0 10px;
}

.dashboardStoreName, .dashboardEditStoreName {
  margin: 5px;
  background: #f5f5f55e;
  height: 25px;
  padding: 5px;
  border-radius: 10px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;

  outline: none;
  resize: none;
}

.dashboardStoreDescription, .dashboardEditStoreDescription {
  margin: 5px;
  padding: 5px;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: initial;
  resize: none;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.dashboardEditStoreDescription {
  height: auto;
  min-height: 50px; /* Set a minimum height to accommodate for initial content */
}

.dashboardStoreNameExistsDiv, .noBuyerInfo {
  margin: 10px 5px;
}

.noShippingInfo {
  margin: 0 5px;
}

.countryUploadNote {
  margin: 15px 5px;
}

.dashboardStoreNameExistsDiv, .countryUploadNote, .noBuyerInfo, .noShippingInfo {
  box-sizing: border-box;
  background: #E87674;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: 'Poppins'
}

.storeEditInfo, .editProduct {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.storeEditInfo {
  margin-left: calc(100% - 25px)
}

.storeEditInfo:hover, .editProduct:hover {
  filter: invert(28%) sepia(38%) saturate(1624%) hue-rotate(246deg) brightness(88%) contrast(84%);
}

.editTextBtnDiv {
  display: flex;
  flex-direction: row;
  margin-left: calc(100% - 160px);
  width: fit-content;
  justify-content: space-between;
}

.submitTextBtn, .cancelTextBtn {
  cursor: pointer;
  background-color: black;
  font-family: 'Poppins';
  font-size: 14px;
  color: white;
  border-radius: 10px;
  margin: 5px 0px 2px 10px;
  cursor: pointer;
  border: none;
  padding: 5px 10px;
}

.submitTextBtn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none; /* prevents clicking when disabled */
}

.uploadNewProduct, .bundlingEditButton {
  position: absolute;
  width: 22px;
  z-index: 101;
  right: 10px;
  cursor: pointer;
}

.uploadNewProduct:hover, .bundlingEditButton:hover {
  filter: invert(28%) sepia(38%) saturate(1624%) hue-rotate(246deg) brightness(88%) contrast(84%);
}


.productOptionsPopupContainer {
  width: fit-content;
  height: fit-content;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 100;

  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  border-radius: 15px;
}

.productOptionsPopup {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100px;
  height: 50px;

  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  
  border: solid rgba(255, 255, 255, 0.05) 2px;
  border-radius: 15px;
  background: rgba(208, 215, 215, 0.8);
  margin-bottom: 0;
  
  z-index: 101;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

#dashboardDelete {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;
  left: 110px;
  right: 0;

  background: rgba(208, 215, 215, 0.6);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 25px -5px, rgba(0, 0, 0, 0.25) 0px 10px 10px -5px;

  margin-top: -5px;

  height: 25px;
  width: 25px;
  border-radius: 15px;
  cursor: pointer;
  z-index: 110;
  
}

#dashboardDelete:hover {
  filter: invert(1);
}

#dashboardDeleteIcon {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 10px;
  width: 10px;
}

.noEditingProducts {
  text-align: center;
  margin: 10px;
}

.dashboardLogin {
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  background: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  overflow: hidden;
  touch-action: none;
}

.dashboardLoginDiv {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.dashboardLoginTextDiv {
  border-radius: 15px;
  font-family: 'Poppins';
  text-align: center;
  font-size: 14px;
  background-color: #C9DCB3;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px;
}

@media only screen and (min-width: 450px) {
  .dashboardLoginTextDiv {
    width: 439px;
  }
}

/* Position container at bottom center.  Adjust z-index if needed. */
.deleteNotificationsContainer {
  position: fixed;
  bottom: 1.5%;
  left: 0;
  width: 100%;
  pointer-events: none; /* so clicks pass through if desired */
  z-index: 9999;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

/* Each individual popup */
.deleteNotificationPopup {
  background-color: #C9DCB3; 
  position: fixed;
  left: 0;
  transform: translateX(-50%);
  padding: 16px 24px;
  border-radius: 15px;
  color: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 15px;
  font-size: 14px;
  width: calc(100% - 20px);
  margin: 10px;
  text-align: center;
  box-sizing: border-box;
  font-family: 'Poppins'
}
