.backgroundSelectorPopup {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  box-sizing: border-box;

  border: solid rgba(255, 255, 255, 0.2) 2px;
  border-radius: 25px;
  
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);

  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  z-index: 1001;
  font-family: 'Poppins';
  overflow-y: scroll;
} 

.backgroundSelectorHeader {
  font-family: 'Poppins';
  font-size: 20px;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  color: rgba(0, 0, 0, 0.8);
}

.backgroundButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 98%;
  margin-bottom: 15px;
}

.brandColorSectionHeader, .commonColorsHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
}

.bCSection1 {
  display: flex;
  flex-direction: row;
}

.bCSection2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.brandColorsDiv, .commonColorsDiv {
  border-radius: 15px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
}

.brandColorsDiv {
  margin: 10px;
}

.commonColorsDiv {
  margin: 5px 10px 0px 10px;
}

.brandColorsDiv, .backgroundOptions {
  height: fit-content;
}

.brandColorsDiv, .backgroundOptions, .commonColorsDiv, .commonColorsSection {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.brandColorsDivHeader, .commonColorsDivHeader {
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  margin-left: 10px;
  /* margin: 0px 0px 0px 10px; */
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
}

.commonColorsSection {
  background: #ffffff73;
  border-radius: 12px;
  margin-top: 7px;
}

.websiteLogoSquare {
  width: 36px;
  height: 36px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  margin: 5px;
  cursor: pointer;
}

.brandColorDropdownIcon, .commonColorsDropdownIconActive {
  width: 15px;
  height: 7.5px;
  margin-right: 10px;
  margin-bottom: 2px;
  cursor: pointer;
  transform: rotate(180deg);
    filter: opacity(0.7);
}

.brandColorDropdownIconActive, .commonColorsDropdownIcon {
  width: 15px;
  height: 7.5px;
  margin-right: 10px;
  margin-bottom: 3px;
  cursor: pointer;
  filter: opacity(0.7);
}

.brandColorSection {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.brandColorSquare {
  width: 36px;
  height: 36px;
  margin: 5px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  margin: 5px;
  cursor: pointer;
}

.colourOptions, .textureOptions {
  background-color: black;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 14px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  width: 48%;
  height: 35px;
} 

.colourOptions:hover, .textureOptions:hover {
  filter: invert(1);
}

.chooseSpecificColourContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.expandedTextColourDoneDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Pushes content to the bottom */
  align-items: flex-start; /* Aligns items to the left */
  width: 100%;
  height: 100%;
  margin-top: 15px
}

.expandedTextColourDoneBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: black;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  width: fit-content;
  height: 40px;
  padding: 0 5px 0 10px;
  margin-left: auto;
}

.expandedTextColourDoneCheckIcon {
  width: 20px;
  height: 20px;
  filter: invert(1);
  margin: 5px 5px 5px 10px;
}