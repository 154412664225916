.elementContainer {
  width: fit-content;
  height: fit-content;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1010;
}

.elementBar {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;
  width: 212px;

  border: solid rgba(255, 255, 255, 0.2) 2px;
  border-radius: 15px;
  background: rgba(208, 215, 215, 0.5);

  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

#framePlusIcon {
  position: absolute;
  cursor: pointer;
}

#websiteImageUpload {
  position: absolute;
  top: 200px;
}

#eBIcons {
  width: 30px;
  margin: 5px;
  cursor: pointer;
}

.eBDeleteIcon:hover {
  filter: drop-shadow(0px 5px 5px #831d2580);
}

.eBStickerIcon:hover {
  filter: drop-shadow(0px 5px 5px #5328be80);
}

.imagePopupContainer {
  top: 10px;
  backdrop-filter: blur(14px);
}

.imagePopupContainer, .textPopupContainer {
  height: fit-content; 
  width: fit-content;
  position: relative;
}

/* ElementBar.css */

.frameOptionsPopup, .changeToTextPopup {
  position: relative;
  top: 100%; /* Positions below the ElementBar */
  left: 0;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  gap: 10px;
  padding: 10px;
  font-family: 'Poppins';
  border-radius: 10px;
  z-index: 1000; 
  margin-top: 10px;
  width: 240px
}

.changeToTextPopup {
  display: flex;
  flex-direction: row
}

.frameOption , .changeToTextPopupYes, .changeToTextPopupNo {
  cursor: pointer;
  background-color: black;
  color: white;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  transition: background-color 0.3s;
}

.frameOption {
  padding: 8px 12px;
}

.changeToTextPopupNo, .changeToTextPopupYes {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-weight: bold;
  width: 50px;
  height: 50px;
  padding: 0 20px
}

.frameOption:hover, .changeToTextPopupNo:hover, .changeToTextPopupYes:hover {
  background-color: #e0e0e0;
  color: black
}
