.scoutBody {
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  overflow-y: scroll;

  width: 100vw;
  top: 0;
  left: 0;
  z-index: 0;
}

.scoutLoadingDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  filter: brightness(0);
  margin: 40px auto;
  transition: opacity 1s ease-in-out;
  animation: spin 2s infinite linear;
}

.scoutLoadingDiv.hidden {
  opacity: 0;
  pointer-events: none;
}

.scoutLoadingIcon {
  width: 40px;
  height: 40px;
}

.scoutSearchOverlayContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;

  z-index: 101;
  width: 100%;
  margin-top: 10px;
}

.scoutSearchOverlay {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%
}

.scoutBackArrowButton {
  position: relative;
  border-radius: 12px;

  background: rgba(208, 215, 215, 0.5);
  box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.35) 0px 10px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;

  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  z-index: 3;
  border: none;
  padding: 0 10px;
  cursor: pointer;
}

.scoutBackArrowButton:hover {
  filter: invert(1)
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

.scoutHeader {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  /* scroll-behavior: smooth; */
}

.scoutLogin {
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 150;
  background: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  overflow: hidden;
  touch-action: none;
}

.scoutLoginDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  margin: 0 10px;
}

.searchContainer {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  width: 90vw;
  top: 10px;
  /* left: 50%; */
  /* transform: translate(0, -50%); */
  margin: 0px auto;
  z-index: 100;
}

.searchBar {
  height: 100%;
  width: inherit;
}

#searchIcon:hover {
  filter: brightness(0%);
  cursor: pointer;
}
/* 
body {
  background: #e39e50;
} */

::-webkit-scrollbar {
  display: none;
}

.paperTexture {
  position: fixed;
  height: 110%;
  width: 100%;
  z-index: 1;
  mix-blend-mode: multiply; /* Experiment with different blend modes like overlay, soft-light, etc. */
  filter: contrast(150%) opacity(50%) brightness(100%) invert(15%);
  -webkit-touch-callout: none;
  pointer-events: none; /* prevents the image from capturing touch events */
}

.scoutBodyGradientCanvas {
  height: 105vh;
  width: 110vw;
  
  display: block;
  position: absolute;
  top: -2vh;
  left: -5vw;

  margin: 0;
  filter: blur(15px);
  
  background: #FFFFFF;
  z-index: -1;
}

.scoutDiv {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 0;
  align-items: center;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  grid-row: 1;
  grid-column: 1;
  
  z-index: -1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scoutDiv::-webkit-scrollbar {
  display: none;
}

.scoutCardWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  position: relative;
  top: 0;
  box-sizing: border-box;
}

.noMoreProductsPopup, .textDivMessage {
  border-radius: 12px;
  font-family: 'Poppins';
  z-index: 200;

  max-width: 90vw;

  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  height: fit-content;
  top: 40px;
  margin: 0px;

  border: solid rgba(255, 255, 255, 0.05) 2px;
  background: rgba(255, 255, 255, 0.5);

  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  cursor: pointer;
}

.noMoreProductsPopup {
  width: 330px;
  padding: 2.5px 0;
}

.textDivMessage {
  /* width: 350px; */
  padding: 5px 10px;
}

.scoutLoginTextDiv {
  border-radius: 15px;
  font-family: 'Poppins';
  text-align: center;
  font-size: 14px;
  background-color: #C9DCB3;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px;
}

@media only screen and (min-width: 450px) {
  .scoutLoginTextDiv {
    width: 439px;
  }
}

.wideScreenArrowContainer {
  position: absolute;
  bottom: 20px;
  right: 20px;
  height: fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  background: rgba(255, 255, 255, 0.5);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  z-index: 1003;
}

.wideScreenArrowDiv {
  display: flex;
  flex-direction: column;
  height: inherit;
  width: inherit;
}

.scoutUpArrowIcon, .scoutDownArrowIcon {
  height: 30px;
  width: 30px;
  margin: 3px;
  padding: 5px
}

.scoutDownArrowIcon {
  transform: rotate(180deg);
}

.arrow-up-active:hover, .arrow-down-active:hover {
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 15px 25px -12px inset, rgba(0, 0, 0, 0.2) 0px 18px 36px -18px inset, 
    rgba(0, 0, 0, 0.1) inset 0 2px 10px 0,   /* Top border */
    rgba(0, 0, 0, 0.1) inset 2px 0 10px 0,   /* Left border */
    rgba(0, 0, 0, 0.04) inset 0 -1px 0 0,  /* Bottom border */
    rgb(0, 0, 0, 0.04) inset -1px 0 0 0  /* Right border */ ;
}

.alreadyInCartDiv {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  top: 5px;
  left: 0;
  height: fit-content;
  z-index: 10000;
}

.alreadyInCartDivText {
  width: 95vw;
  border-radius: 15px;
  font-family: 'Poppins';
  text-align: center;
  font-size: 14px;
  background-color: #C9DCB3;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 0px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px;
}