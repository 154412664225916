.logoUploadOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.logoUploadHeading {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
  font-family: 'Poppins';
  text-align: left;
  margin: 0 0 20px 0
}

.logoUploadModal {
  position: relative;
  background: rgba(255, 255, 255, 0.6);
  border: solid rgba(255, 255, 255, 0.1) 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  backdrop-filter: blur(7px);
  padding: 10px;
  border-radius: 20px;
  width: 85%;
  max-width: 500px;
  text-align: center;
}

.logoUploadModal h2 {
  margin-bottom: 20px;
}

.logoImagePreview {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
}

.dashboardUploadCloseIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.uploadButton {
  width: 180px;
  padding: 5px 10px 5px 15px;
}

.dashboardUploadSaveBtn {
  padding: 10px 15px;
}

.uploadButton, .dashboardUploadSaveBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  height: 42px;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: bold;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: -2px -1px 2.5px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.dashboardUploadButtonIcon {
  filter: invert(1);
}

.uploadButton img {
  width: 20px;
}

.uploadActions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.deleteButton,
.cancelButton {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.deleteButton {
  background-color: #ff4d4f;
  color: #fff;
  display: flex;
  align-items: center;
}

.deleteButton img {
  width: 20px;
  margin-right: 5px;
}

.cancelButton {
  background-color: #f0f0f0;
  color: #000;
}

.errorMessage {
  box-sizing: border-box;
  background: #E87674;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: 'Poppins';
  margin: 10px;
}
