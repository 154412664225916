.framePopup  {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;

  user-select: none;

  font-family: 'Poppins';
  font-weight: bold;
  padding: 10px 10px 0px 10px;
  margin: 0 5px;
  top: 70px;

  width: calc(100% - 10px);
  height: calc(100% - 150px);
  border: solid rgba(255, 255, 255, 0.2) 2px;
  border-radius: 25px;
  background: rgba(208, 215, 215, 0.5);
  
  box-sizing: border-box;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);

  box-shadow: 
    rgba(0, 0, 0, 0.3) 0px 40px 30px -5px, 
    rgba(0, 0, 0, 0.2) 0px 10px 10px -5px;
  
  z-index: 1000;
  font-family: 'Poppins';
  overflow-y: scroll;
}

.frameSelectorHeader {
  font-family: 'Poppins';
  font-size: 20px;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.8);
}

.frameTypeOptionsDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  border-radius: 12px;
  background-color: rgba(43, 37, 37, 0.7);
  padding: 7px;
}

.fixedFrameBtn, .floatingFrameBtn {
  display: flex;
  border-radius: 10px;
  padding: 4px;
  justify-content: center;
  width: 47%;
  background-color: none;
  color: white;
  cursor: pointer;
}

.fixedFrameBtn.Selected, .floatingFrameBtn.Selected {
  background-color: white;
  color: rgb(43, 37, 37);
  box-shadow: -2px -2px 2.5px rgba(255, 255, 255, 0.2),
              2px 2px 4px rgba(0, 0, 0, 0.45);
}

.frameOptions {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  overflow-y: scroll;
  padding: 20px 10px;
  margin: 10px 0px;
  border-radius: 15px;
  background-color: #5c5c5cbd;
  border: none;
  /* backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px); */
}

.frameOption1 {
  border-radius: 25px;
  height: 50px;
  width: 47%;
  aspect-ratio: 350/60;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: auto;
}

.frameOption2 {
  border-radius: 10px;
  height: 60px;
  width: 47%;
  aspect-ratio: 350/60;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 5px;
}

.frameOption3 {
  border-radius: 10px;
  height: 100px;
  width: 47%;
  aspect-ratio: 350/100;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: auto;
}

.frameOption4 {
  border-radius: 10px;
  height: 173.89px;
  width: 47%;
  aspect-ratio: 350/200;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px 5px 10px 5px;
}

.frameOption5 {
  border-radius: 10px;
  height: 250px;
  width: 47%;
  aspect-ratio: 350/250;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px 5px 10px 5px;
}

.frameOption6 {
  border-radius: 130px 130px 10px 10px;
  height: 250px;
  width: 47%;
  aspect-ratio: 350/250;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px auto;
}

.frameOption7 {
  border-radius: 15px;
  height: 220px;
  width: 30%;
  aspect-ratio: 350/220;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px 5px 10px 5px;
}

.frameOption8 {
  border-radius: 130px 130px 15px 15px;
  height: 220px;
  width: 30%;
  aspect-ratio: 350/220;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px 5px 10px 5px;
}

.frameOption9 {
  border-radius: 130px;
  height: 220px;
  width: 30%;
  aspect-ratio: 350/220;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px 5px 10px 5px;
}

.frameOption10 {
  border-radius: 10px;
  height: 170px;
  width: 30%;
  aspect-ratio: 350/220;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px 5px 10px 5px;
}

.frameOption11 {
  border-radius: 85px 85px 10px 10px;
  height: 170px;
  width: 30%;
  aspect-ratio: 350/220;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px 5px 10px 5px;
}

.frameOption12 {
  border-radius: 60px;
  height: 170px;
  width: 30%;
  aspect-ratio: 350/220;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px 5px 10px 5px;
}

.frameOption13 {
  border-radius: 10px;
  height: 111px;
  width: 30%;
  aspect-ratio: 350/220;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px 5px 10px 5px;
}

.frameOption14 {
  border-radius: 60px 60px 10px 10px;
  height: 111px;
  width: 30%;
  aspect-ratio: 350/220;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px 5px 10px 5px;
}

.frameOption15 {
  border-radius: 60px;
  height: 111px;
  width: 30%;
  aspect-ratio: 350/220;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px 5px 10px 5px;
}

.frameOption16 {
  border-radius: 50%;
  height: 170.41px;
  width: 47%;
  aspect-ratio: 350/200;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 5px;
}

.frameOption17 {
  border-radius: 50%;
  height: 145.03px;
  width: 40%;
  aspect-ratio: 350/200;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: auto;
}

.frameOption18 {
  border-radius: 50%;
  height: 60px;
  width: 47%;
  aspect-ratio: 350/60;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: auto;
}

.frameOption19 {
  border-radius: 50%;
  height: 80px;
  width: 47%;
  aspect-ratio: 350/60;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 5px;
}

.frameOption20 {
  border-radius: 50% 10px 10px 10px;
  height: 140px;
  width: 47%;
  aspect-ratio: 350/200;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px auto;
}

.frameOption21 {
  border-radius: 10px 50% 10px 10px;
  height: 140px;
  width: 47%;
  aspect-ratio: 350/200;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px auto;
}

.frameOption22 {
  border-radius: 10px 10px 50% 10px;
  height: 140px;
  width: 47%;
  aspect-ratio: 350/200;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px auto;
}

.frameOption23 {
  border-radius: 10px 10px 10px 50%;
  height: 140px;
  width: 47%;
  aspect-ratio: 350/200;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px auto;
}

.frameOption24 {
  border-radius: 50% 10px 50% 10px;
  height: 140px;
  width: 47%;
  aspect-ratio: 350/200;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px auto;
}

.frameOption25 {
  border-radius: 10px 50% 10px 50%;
  height: 140px;
  width: 47%;
  aspect-ratio: 350/200;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px auto;
}

.frameOption26 {
  border-radius: 15px 15px 130px 130px ;
  height: 220px;
  width: 30%;
  aspect-ratio: 350/220;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: 10px 5px 10px 5px;
}

.frameOption27 {
  border-radius: 50%;
  height: 173.89px;
  width: 30%;
  aspect-ratio: 350/200;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: auto;
}

.frameOption28 {
  border-radius: 10px 10px 85px 85px;
  height: 170px;
  width: 30%;
  aspect-ratio: 350/220;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: auto;
}

.frameOption29 {
  border-radius: 10px;
  height: 40px;
  width: 40%;
  aspect-ratio: 350/60;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: auto;
}

.frameOption30 {
  border-radius: 10px 10px 60px 60px;
  height: 111px;
  width: 30%;
  aspect-ratio: 350/220;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 15px;
}

.frameInfo {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  visibility: hidden;
  position: absolute;

  top: 45px;
  width: 350px;
  padding: 10px;
  font-size: 14px;

  background: #E87674;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
  z-index: 100;
}

.frameInfoText {
  font-family: 'Poppins';
  font-weight: normal;
  margin: 0 0 10px 0;
  padding: 10px;
  border-radius: 10px;
  background: #e19a98;
}

.frameExample {
  height: 170px;
  width: 300px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.closeFrameInfoBtn {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: bold;

  background: #000000;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  border: none;
}

.closeFrameInfoBtn:hover {
  filter: invert(1);
}

.framesInfoIcon {
  width: 20px;
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 8px;
}

.framesInfoIcon:hover {
  filter: invert(0.5);
}