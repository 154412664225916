.imageDivtrue {
  position: relative;
  height: 24%;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 8px;
}

.imageDivfalse, .imageDiv {
  position: absolute;
  top: 0;
  box-sizing: border-box;
  margin-bottom: 5px;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  height: 77%;
}

@media (min-width: 670px) {
  .imageDivfalse, .imageDiv {
    height: 100%;
    width: 50%;
  }
}

.scoutCardImage,
.scoutCardImagetrue,
.scoutCardImagefalse {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  -webkit-user-drag: none;
  cursor: pointer;
}

.vintage-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  
  background:
    radial-gradient(circle at 10% 10%, rgba(255, 200, 150, 0.3), transparent 15%),
    radial-gradient(circle at 25% 5%, rgba(255, 180, 130, 0.25), transparent 20%),
    radial-gradient(circle at 40% 10%, rgba(255, 220, 170, 0.2), transparent 15%),
    radial-gradient(circle at 60% 5%, rgba(255, 200, 150, 0.25), transparent 20%),
    radial-gradient(circle at 80% 10%, rgba(255, 180, 130, 0.3), transparent 15%),
    radial-gradient(circle at 90% 25%, rgba(255, 220, 170, 0.2), transparent 20%),
    radial-gradient(circle at 85% 40%, rgba(255, 200, 150, 0.25), transparent 15%),
    radial-gradient(circle at 75% 55%, rgba(255, 180, 130, 0.3), transparent 20%),
    radial-gradient(circle at 65% 70%, rgba(255, 220, 170, 0.2), transparent 15%),
    radial-gradient(circle at 50% 80%, rgba(255, 200, 150, 0.25), transparent 20%),
    radial-gradient(circle at 35% 75%, rgba(251, 247, 244, 0.3), transparent 15%),
    radial-gradient(circle at 20% 70%, rgba(255, 220, 170, 0.2), transparent 20%);
  
  mix-blend-mode: screen;
  opacity: 0.3;
  /* transform: rotate(-5deg); */
}

.vintage-edge-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Allows clicks to pass through */

  /* Multiple small radial gradients positioned near the edges */
  background:
    /* Top Edge */
    radial-gradient(circle at 10% 0%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),
    radial-gradient(circle at 30% 0%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),
    radial-gradient(circle at 50% 0%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),
    radial-gradient(circle at 70% 0%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),
    radial-gradient(circle at 90% 0%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),

    /* Bottom Edge */
    radial-gradient(circle at 10% 100%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),
    radial-gradient(circle at 30% 100%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),
    radial-gradient(circle at 50% 100%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),
    radial-gradient(circle at 70% 100%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),
    radial-gradient(circle at 90% 100%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),

    /* Left Edge */
    radial-gradient(circle at 0% 10%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),
    radial-gradient(circle at 0% 30%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),
    radial-gradient(circle at 0% 50%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),
    radial-gradient(circle at 0% 70%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),
    radial-gradient(circle at 0% 90%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),

    /* Right Edge */
    radial-gradient(circle at 100% 10%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),
    radial-gradient(circle at 100% 30%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),
    radial-gradient(circle at 100% 50%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),
    radial-gradient(circle at 100% 70%, rgba(255, 255, 255, 0.3) 0%, transparent 3px),
    radial-gradient(circle at 100% 90%, rgba(255, 255, 255, 0.3) 0%, transparent 3px);

  /* Blend the overlay with the image */
  mix-blend-mode: lighten;
  opacity: 1;
}


/* Responsive Adjustments */
@media (max-width: 768px) {
  /* Adjust overlay positions or sizes if necessary */
}

.bleached-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 5px solid rgba(236, 155, 62, 0.3); /* Lightened border */
  filter: blur(4px); /* Slight blur */
  mix-blend-mode: lighten; /* Blend mode to lighten the image */
  pointer-events: none; /* Allows clicks to pass through */
  border-radius: 20px;
}

/* Peeling Effect Overlay */
.peeling-effect {
  position: absolute;
  top: -10px; /* Adjust as needed */
  left: 50%; /* Centered horizontally */
  transform: translateX(-50%);
  width: 20px; /* Size of the peeled area */
  height: 20px;
  pointer-events: none; /* Allows clicks to pass through */
  border-radius: 15px;
  /* The SVG is defined inline in JSX */
}