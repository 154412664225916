.pageNotFound {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #C9DCB3;
}

.pageNotFoundWarning {
  display: flex;
  flex-direction: column;
  background-color: #5f7940cc;
  border-radius: 20px;
  margin: 10px;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  box-sizing: border-box;
}

.pageNotFoundHeader {
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 20px;
  margin: 10px 10px 0 10px;
}

.pageNotFoundText {
  font-family: 'Poppins';
  font-size: 14px;
  margin: 10px;
}

.userWebsiteBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: absolute;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 0;
  left: 0;
  padding-top: 10px;
  padding-bottom: 140px;
  overflow-x: hidden;
}


.websiteFramesDiv {
  position: relative;
  width: 100%;
  padding-bottom: 140px;
  padding-left: 0px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.websiteLoadingDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  filter: brightness(0);
  margin: 40px auto;
  animation: spin 2s infinite linear;
}

.websiteLoadingIcon {
  width: 40px;
  height: 40px;
}

.websiteInProgress {
  position: relative;
  font-family: 'Poppins';
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(88, 81, 35);

  padding: 15px 30px 10px 30px;
  border-radius: 20px;
  margin: 0 10px;

  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.08) 0px 10px 10px -5px
}

.websiteInProgressText {
  font-weight: bold;
  color: rgb(191, 178, 95);
}

.websiteInProgressImg {
  width: 200px;
  height: 200px;
  margin: 20px 0 10px 0;
}

.websiteStickerDiv {
  height: 100%;
  z-index: 99;
}

.websiteOptions1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  left: 10px;
  bottom: 1.5%;
  width: 115px;
  z-index: 1004;
}

.websiteStoreButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

  border: solid rgba(255, 255, 255, 0.05) 2px;
  background: rgba(255, 255, 255, 0.5);

  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 14px;
}

.websiteBackArrow {
  border-radius: 15px;
  width: 50px;
}

.websiteBackArrow:hover {
  background-color: white;
  filter: invert(1);
}

.websiteOptions2 {
  position: fixed;
  right: 10px;
  bottom: 1.5%;
  border-radius: 15px;
  width: fit-content;
}

.websiteOptions2, .websiteBackArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

  border: solid rgba(255, 255, 255, 0.05) 2px;
  background: rgba(255, 255, 255, 0.5);

  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 1004;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.websiteFramesDiv {
  position: absolute;
  width: 100%;
  padding-bottom: 120px;
  top: 10px;

  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.websiteHeartIcon, .websiteInfoIcon {
  height: 30px;
  width: 30px;
  margin: 5px;
  cursor: pointer;
}

.websiteBackArrowIcon {
  height: 28px;
  width: 28px;
  margin: 0 10px;
  cursor: pointer;
}

.websiteOptionsContainer {
  position: fixed;
  display: flex;
  justify-content: space-between;
  height: 54px;
  width: 100%;
  z-index: 100;
  bottom: 1.5%;
}

#websiteHeartIcon, #websiteRedHeartIcon {
  width: 30px;
  height: 30px;
  margin: 4px 6px 0px 8px;
  cursor: pointer;
}

#websiteStoreIcon {
  width: 30px;
  height: 30px;
  margin: 0px 10px;
  cursor: pointer;
  filter: brightness(0)
}

#websiteRedHeartIcon {
  filter: drop-shadow(1px 3px 5px #db2e3c80);
}

.websiteLoginDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  margin: 0 10px;
}

.websiteLogin {
  position: absolute;
  display: flex;
  justify-content: center;

  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1002;
  backdrop-filter: blur(14px);
  background: rgba(0, 0, 0, 0.4);
}

.websiteLoginTextDiv {
  border-radius: 15px;
  font-family: 'Poppins';
  text-align: center;
  font-size: 14px;
  background-color: #C9DCB3;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 15px;
}

@media only screen and (min-width: 450px) {
  .websiteLoginTextDiv {
    width: 439px;
    /* margin-right: 10px; */
  }
}