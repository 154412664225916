.imagePopupDiv {
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
  height: fit-content;
  width: 300px;
  border: solid rgba(255, 255, 255, 0.2) 2px;
  border-radius: 15px;
  background: rgba(208, 215, 215, 0.5);

  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  padding: 10px;
  z-index: 3;
}

.imagePopupNoImage {
  font-family: 'Poppins';
  font-size: 14px;
  margin: 10px 10px 10px 5px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.714);
  cursor: default;
}

.imagePopupChooseImageButton {
  display: flex;
  flex-direction: row;
  font-family: 'Poppins';
  align-items: center;
  width: fit-content;
  font-size: 14px;
  background-color: black;
  border-radius: 10px;
  color: white;
  margin-bottom: 10px;
  padding: 6px 12px;
  cursor: pointer;
}

.imagePopupPlusIcon {
  margin-left: 10px;
  width: 14px;
  height: 14px;
  filter: invert(1);
}

.imagePopupPlusIcon:hover {
  filter: invert(32%) sepia(79%) saturate(1574%) hue-rotate(5deg) brightness(102%) contrast(107%);
}

.uploadedImages, .uploadedImage, .imagePreview {
  width: 200px;
  object-fit: cover;
  margin: 10px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
}

.imagePreviewContainer {
  height: fit-content;
}

.imageFrameProgressBar {
  width: 180px;
  height: 10px;
  border-radius: 5px;
  position: relative;
  z-index: 3;
  -webkit-appearance: none;
  appearance: none;
  bottom: 40px;
  left: 20px;
}

.imageFrameProgressBar::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.imageFrameProgressBar::-moz-progress-bar {
  background-color: rgba(238, 238, 238, 0.651);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.imageFrameProgressBar::-webkit-progress-value {
  background-color: #D75759;
  box-shadow: 1px 1px 5px 3px rgba( 0, 0, 0, 0.1 );
  border-radius: 5px;
}

.imageFrameProgressBar::-moz-progress-value {
  background-color: #D75759;
  box-shadow: 1px 1px 5px 3px rgba( 0, 0, 0, 0.1 );
  border-radius: 5px;
}

.websiteImageUploadButton {
  display: flex;
  flex-direction: row;
  font-family: 'Poppins';
  width: fit-content;
  font-size: 14px;
  background-color: black;
  border-radius: 12px;
  color: white;
  margin: 10px 0px 10px 10px;
  padding: 6px;
  cursor: pointer;
}

.uploadMediaButton, .closeImagePopupBtn {
  background-color: black;
  font-family: 'Poppins';
  font-size: 14px;
  color: white;
  border-radius: 10px;
  margin: 5px 10px 10px 10px;
  cursor: pointer;
  border: none;
}

.uploadMediaButton:hover {
  filter: invert(1);
}

/* .uploadMediaButton:disabled {
  background-color: rgba(0, 0, 0, 0.615);
  font-family: 'Poppins';
  cursor: not-allowed;
  border-radius: 10px;
  border: none;
} */

.imagePopupLoadingDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  filter: brightness(0);
  margin: 40px auto;
  animation: spin 2s infinite linear;
}

.imagePopupLoadingIcon {
  width: 40px;
  height: 40px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}