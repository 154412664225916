.positionPopup {
  position: absolute;
  top: 70px;
  left: 10px;
  font-family: 'Poppins';
  height: fit-content;
  width: fit-content;
  background: rgba(208, 215, 215, 0.7);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 1005;
  border-radius: 15px;
  padding: 5px;
  cursor: pointer;
  box-shadow: 
    rgba(0, 0, 0, 0.4) 10px 5px 15px -10px, 
    rgba(0, 0, 0, 0.4) 0px 5px 10px -10px, 
    rgba(0, 0, 0, 0.25) 0px 35px 40px -5px, 
    rgba(0, 0, 0, 0.05) 0px 35px 10px -10px, 
    rgba(0, 0, 0, 0.08) 0px 15px 15px -10px;
}

.stretchFrameDiv, .marginDiv, .layerDiv {
  background: #f5f5f5;
  border-radius: 10px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  cursor: pointer;
  box-sizing: border-box;
}

.stretchFrameDiv, .marginDiv {
  margin: 5px 5px 10px 5px;
  padding: 5px;
}

.marginTopDiv, .marginBottomDiv, .marginTopInputSection, .marginBottomInputSection {
  display: flex;
  flex-direction: row;
}

.marginTopDiv, .marginBottomDiv {
  justify-content: space-between;
  padding: 5px 5px 5px 10px;
  width: 190px
}

.marginTopInput, .marginBottomInput {
  border-radius: 5px;
  font-family: 'Poppins';
  text-align: center;
  height: 25px;
  width: 25px;
  border: none;
  box-shadow: -2px -2px 2px rgb(255 255 255 / 25%), 2px 2px 4px rgb(0 0 0 / 45%); 
  margin: 0 5px;
}

.layerDiv {
  margin: 5px 5px 5px 5px;
  padding: 5px 10px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}


.stretchFrameHeading, .marginHeading, .layerHeading {
  font-weight: bold;
  margin: 5px 5px 5px 10px;
}

.stretchFrameBtnDiv {
  display: flex;
  flex-direction: row;
  margin: 5px 10px;
  gap: 10px;
  box-sizing: border-box;
}

.stretchFrameYes, .stretchFrameNo {
  padding: 5px 10px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  width: 100%
}

#positionOptionBtn {
  margin: 5px 5px 5px 0;
  padding: 5px 5px 5px 10px;
}

#positionOptionBtn:hover {
  color: white;
  background-color: black;
  border-radius: 10px;
}

.stretchFrameYes.selected, .stretchFrameNo.selected{
  background-color: rgb(178, 220, 231);
  color: rgba(0, 0, 0, 0.7);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.2) 0px 10px 10px -5px;
}