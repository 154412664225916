.homeBody {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; 
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.homeLoadingDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  filter: brightness(0);
  margin: 40px auto;
  animation: spin 2s infinite linear;
}

.homeLoadingIcon {
  width: 40px;
  height: 40px;
}

.homeHeader {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;


  padding: 10px;
  height: 44px;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

#logo {
  width: 35px;
  height: 35px;
}

.homeSpace {
  width: 95%;
  height: 44px;
}

.loggedInHomeIcons {
  display: flex;
  flex-direction: row;
  gap: 5px
}

#favourites {
  width: 35px;
  height: 35px;
  margin-right: 2px;
  cursor: pointer;
}

#login, #homeNotifications {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

#homeNotifications:hover {
  filter: invert(28%) sepia(69%) saturate(321%) hue-rotate(15deg) brightness(94%) contrast(97%);
}

#login {
  right: 0px;
}

#favourites:hover {
  filter: invert(15%) sepia(66%) saturate(2651%) hue-rotate(339deg) brightness(90%) contrast(94%);
}

#login:hover, #login:active {
  filter: invert(53%) sepia(34%) saturate(3938%) hue-rotate(344deg) brightness(101%) contrast(102%);
}

#login:focus, #homeNotifications:focus {
  outline: none;
}

.loginPopup {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: calc(100% - 20px);
  margin: 0 10px;
  top: 60px;
  z-index: 20;
}

.platformExplainerDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 20px;
  width: 100%;
  padding: 10px;
  height: 70px;
  margin: 16px 10px 10px 10px;
  border: inset 2px rgba(255, 255, 255, 0.001);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px;
  background: #EFC6A9;
  overflow: hidden;
}

.explainerLogoPattern {
  position: absolute;
  width: 69.25px;
  height: 33.25px;
  top: 10px;
  right: 10px;
}

@media only screen and (min-width: 450px) {
  .platformExplainerDiv {
    width: 435px;
  }
}

.platformExplainerGradientCanvas {
  position: relative;
  height: 300%;
  width: 150%;
  margin: 0;
  filter: blur(10px);
  border-radius: 24px;
  background: #EFC6A9;
  z-index: 0;
}

.homeHeaderContainer {
  display: flex;
  flex-direction: column;
}

.homeHeader1 {
  margin: 10px 10px 30px 10px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
}

@media only screen and (min-width: 416px) {
  .homeHeader1 {
    width: 388px;
  }
  .homeHeaderImageDiv {
    width: 430px;
  }
}

@media only screen and (max-width: 500px) {
  .headerImages {
    width: 100%;
  }
}

@media only screen and (min-width: 501px) {
  .headerImages {
    width: 500px;
  }
}

@media only screen and (min-width: 750px) {
  .homeHeaderContainer {
    flex-direction: row;
    justify-content: space-between;
  }
  .homeHeader1 {
    margin: auto 10px;
  }
  .headerImages {
    width: 400px;
  }
  .missionDiv {
    margin-top: -100px;
  }
}

@media only screen and (min-width: 850px) {
  .headerImages {
    width: 450px;
  }
}

@media only screen and (min-width: 920px) {
  .headerImages {
    width: 500px;
  }
}

@media only screen and (min-width: 1000px) {
  .headerImages {
    width: 550px;
  }
}

@media only screen and (min-width: 1060px) {
  .headerImages {
    width: 600px;
  }
}

@media only screen and (min-width: 1100px) {
  .headerImages {
    width: 650px;
  }
}

@media only screen and (min-width: 1200px) {
  .headerImages {
    width: 750px;
    margin-right: 20px
  }
  .missionDiv {
    margin-top: 0px;
  }
}

/* @media only screen and (min-width: 840px) {
  .homeHeaderImageDiv {
    transform: translateX(100%) translateY(-210px);
    margin-top: 50px;
    
    top: 300px;
    left: 50px;
  }
  .missionDiv {
    transform: translateY(-210px);
  }
} */

.homeHeaderText {
  display: flex;
  flex-direction: row;
}

.homeHeaderText1, .homeHeaderText2 {
  font-family: 'Poppins';
  font-weight: 900;
  font-size: 36px;
}

.homeHeaderText1 {
  animation: flow 8s ease-in-out infinite;
  background: linear-gradient(-45deg, #739E82, #F0590A);
  background-size: 400%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes flow {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.homeHeaderText2 {
  margin-left: 10px;
}

.homeHeaderDescription, .missionDescription, .forPeopleDescription, .forEntrepreneursDescription {
  font-family: 'Poppins';
  font-weight: bolder;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.8);
}

.homeBtns {
  display: flex;
  flex-direction: row;
  align-items: center;
}

a {
  color: black;
}

.buyingWithUsBtn, .sellingWithUsBtn {
  display: flex;
  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  font-family: 'Poppins';
  background: #FF6E22;
  
  border-radius: 12px;
  height: 32px;
  width: 152px;
  font-weight: 700;
  font-size: 14px;
  padding: 0;
  cursor: pointer;
}

.buyingWithUsBtn:hover, .sellingWithUsBtn:hover {
  background: #EA5601;
}

.sellingWithUsBtn {
  margin-left: 10px;
}

.buyingWithUsIcon, .sellingWithUsIcon {
  width: 18px;
  margin-right: 5px;
  filter: brightness(0);
}

/* .headerImages, .missionImages {
  width: 100%;
} */

.missionDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 1300px;
  margin-top: -120px;
  background: #ECEDA6;
  border-radius: 30px;
  z-index: -100;
}

.missionHeader {
  margin-top: 140px;
}

.missionHeader, .forPeopleHeader {
  font-family: 'Poppins';
  font-weight: 900;
  font-size: 36px;
  color: #FD8700;
  margin-left: 15px;
}

.missionDescription, .forPeopleDescription {
  margin: 10px 20px 10px 15px;
}

.missionBtns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.problemStatementBtn, .nameMeaningBtn, .definitionsBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  font-family: 'Poppins';
  background: #FD8700;
  color: rgba(0, 0, 0, 0.5);
  
  border-radius: 12px;
  height: 32px;
  font-weight: 700;
  font-size: 14px;
  
  margin: 10px 5px 5px 12px;
  cursor: pointer;
}

.problemStatementBtn:hover, .nameMeaningBtn:hover, .definitionsBtn:hover {
  filter: brightness(0.9)
}

.problemStatementBtn {
  padding: 0 5px 0 5px;
  width: 152px;
}

.nameMeaningBtn {
  left: 175px;
  padding: 0 8px 0 8px;
  width: 152px;
}

.definitionsBtn {
  padding: 0;
  width: 112px;
}

.forPeopleHeader {
  margin-top: 10px;
}

.buyerDiv {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  margin: 0px;
  left: 0px;
  top: 1485px;
}

.buyerPromotionCards {
  margin: 20px 0 60px 0;
  height: fit-content;
  background: #ED8951;
  border-radius: 25px;
}

.buyerPromotionCardDiv {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.buyerImage1 {
  margin-left: 30px;
  margin-right: 30px;
  scroll-snap-align: center;
}

.buyerImage2, .buyerImage3, .buyerImage4 {
  margin-right: 30px;
  scroll-snap-align: center;
  height: 460px;
}

.recommendAppCard {
  margin: auto;
  margin-bottom: 20px;
  width: 338px;
  height: fit-content;
  background: #FF4500;
  border-radius: 18px;
}

.recommendAppCardText {
  margin: auto;
  padding: 15px 0 15px 0;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 14px;
  width: 302.81px;
  color: #ECEDA6;
}

.forEntrepreneursHeader {
  font-family: 'Poppins';
  font-weight: 900;
  font-size: 36px;
  color: #CB622A;
  margin: 0 0 15px 15px;
}

.sellerCategories {
  display: flex;
  height: 35px;
  flex-direction: row;
  overflow-x: scroll;

  margin: 0 0 20px 0;
  padding-left: 10px;
  width: 100%;
  overflow-y: hidden;
}

.homeCategoryTag {
  width: fit-content;
  max-width: fit-content;
  word-break: keep-all;

  font-family: 'Poppins';
  font-weight: bold;
  padding: 3px 10px 3px 10px;
  margin: 0 5px 5px 5px;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.7);
  background-color: #FF6E22;
  cursor: default;
  white-space: nowrap;
}

.saleNotificationsHeader, .purchaseNotificationsHeader {
  font-weight: bold;
  font-size: 19.2px;
  font-family: 'Poppins';
  margin: 10px;
  color: #BFB25F;
}

.saleNotifications, .purchaseNotifications {
  position: relative;
  padding: 5px;
  margin: 10px;
  border-radius: 20px;
  background-color: #585123
}

.homeWhereToStartContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px 20px 0 0;
  padding: 10px;
  background: #585123;
  width: 100vw;
  box-sizing: border-box;
  bottom: 0;
}

.homeCreateStoreDiv {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: fit-content;
  border-radius: 15px;
  background-color: #BFB25F;
  box-sizing: border-box;
  font-family: 'Poppins';
  z-index: 1;
}

.homeStoreBtn {
  border-radius: 10px;
  background: black;

  color: white;
  padding: 10px 5px;
  text-align: center;
  align-content: center;
  font-size: 14px;

  font-family: 'Poppins';
  font-weight: 700;
  cursor: pointer;

  width: 100%;
  border: none;
}

.homeCreateStorePopupText {
  margin: 5px 5px 10px 5px;
  color: black;
}

.homeExplainerContainer {
  display: inline-block; 
  position: relative;
  margin-bottom: 10px;
}

