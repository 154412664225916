.step-indicator {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 10px;
  right: 0px;
  width: calc(100% - 90px);
  margin-bottom: 30px;
  font-family: 'Poppins';
}

.step {
  text-align: center;
  position: relative;
  flex: 1;
  z-index: 1;
}

.step:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 35%;
  right: -50%;
  width: 100%;
  height: 10px;
  background-color: #F5C56B;
  z-index: -1;
}

.step.completed::after {
  background-color: #f47a42;
}

.step-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  margin: 0 auto 5px;
  border-radius: 30%;
  background-color: #F5C56B;
  color: rgba(0, 0, 0, 0.4);
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
}

.step.currentlySelected .step-number,
.step.completed .step-number {
  background-color: #f47a42;
}

.step-label {
  font-size: 12px;
}
