.vintage-haze {
  position: absolute;
  top: 0;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  transition: opacity 0.5s ease, filter 0.5s ease;
  animation: jitter 0.5s infinite;
}

@keyframes jitter {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-1px);
  }
}

@keyframes flicker {
  0%, 100% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.9;
  }
}
