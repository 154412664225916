.bundleContainer {
  display: flex;
  flex-direction: column;

  align-items: center;
  background: #f5f5f55e;
  min-height: 65px;
  max-height: fit-content;
  padding: 5px;
  border-radius: 15px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  margin: 10px 0 0 0;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.bundleProductsRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.bundledGroupText {
  font-weight: bold;
}

.ordersDropdownIcon {
  width: 12px;
  height: 7.5px;
  margin-right: 12px;
  cursor: pointer;
  transform: rotate(180deg)
}

.ordersDropdownIcon.rotate {
  transform: none
}

.bundleSummaryDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Products Details Grid */
.productsDetailsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

/* Individual Product Detail Item */
.productDetailItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  border-radius: 12px;
  padding: 5px;
  margin: 5px 5px 0 5px;
  background-color: #b894ca8e;
}

.detailProductImg {
  width: 60px; 
  height: 60px;
  object-fit: cover;
  border-radius: 12px;
  cursor: pointer;
}

.productInfo {
  display: flex;
  flex-direction: column;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .detailProductImg {
    width: 60px;
    height: 60px;
  }
}

.bundledProductInfo {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins';
  font-weight: bold;
  margin-left: 10px;
}

.trackingImage {
  height: 160px;
  width: 112px;
  position: left;
  object-fit: cover;
  border-radius: 10px;
  margin: 10px 15px 10px 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  z-index: 1;
}

.waybillContainer, .trackingImagesContainer {
  border-radius: 10px;
  margin: 5px;
  background-color: #b894ca8e;
}

.waybillContainer {
  padding: 10px;
}

.trackingImagesContainer {
  padding: 10px 10px 0 10px;
}
