.deliveryOptionPopup {
  position: fixed;
  z-index: 1004;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

  width: calc(100% - 20px);
  backdrop-filter: blur(12px);
  padding: 10px;
  border-radius: 15px;
  font-family: 'Poppins';
  box-sizing: border-box;
  bottom: 65px;
}

.deliveryOptionsContainer {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px;

}