.frameBoundaryDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.textColourPopupContainer {
  background: rgba(208, 215, 215, 0.4);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
}

.textPopupContainer, .textPopupContainer2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 95%;
  height: fit-content;
}

.textPopupContainer {
  margin: 0 0 10px 0;
}

.textPopupContainer2 {
  margin: 5px 0 0 0;
}

.frameBoundaryContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@font-face {
  font-family: 'myCustomFont';
  src: url('https://firebasestorage.googleapis.com/v0/b/boot-strapt.appspot.com/o/fonts%2F36daysofhype-Regular.otf?alt=media&token=a664b9f3-3e43-4f65-9fb7-cfca316e84b6') format('opentype');
}

.frameTextArea {
  height: 100%;
  padding: 10px;
  width: 100%;
  border: none;
  resize: none;
  background-color: transparent;
  border-radius: inherit;
  box-sizing: border-box;
  overflow: hidden;
  font-family: 'Poppins';
  font-size: 16px;
}

.lined {
  border: 3px solid black;
  transition: height 0.2s ease-out;
}

#whiteFramePlusIcon {
  filter: invert(0.8);
  cursor: pointer;
}

.textareaOptionsContainer, .imageFrameOptionsContainer {
  display: flex;
  justify-content: right;
  position: relative;

  height: fit-content;
  z-index: 1000;
  border-radius: 15px;
}

.textareaOptionsContainer {
  margin-left: 10px;
}

.imageFrameOptionsContainer {
  margin-top: 15px;
}

.textareaOptions, .imageFrameOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  height: 50px;
  padding-left: 5px;

  border: solid rgba(255, 255, 255, 0.2) 2px;
  border-radius: 15px;
  background: rgba(208, 215, 215, 0.5);

  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

.textareaDoneBtn, .imageFrameDoneBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: black;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  width: fit-content;
  height: 40px;
  padding: 0 5px 0 10px;
}

.textareaDoneBtn:hover, .imageFrameDoneBtn:hover {
  filter: invert(1);
}

.textareaCheckIcon, .imageFrameCheckIcon {
  width: 20px;
  height: 20px;
  filter: invert(1);
  margin: 5px 5px 5px 10px;
}

.textareaElementBarIcon, .imageFrameElementBarIcon {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.frameTextArea:focus {
  outline: none;
}

.hidden {
  display: none;
}

.frameResizeHandler:hover, .react-resizable-handle:hover, 
.frameResizeHandler.whiteHandle:hover {
  background-color: rgb(62, 62, 62);
}

.frameResizeHandler.orangeHandle:hover {
  background-color: rgb(255, 90, 30);
}

.frameResizeHandler, .react-resizable-handle {
  /* background-color: black; */
  position: absolute;
  z-index: 1000;
  border: none;
}

.frameResizeHandler {
  width: 30px;
  height: 15px;
  bottom: -8px;
  left: 50%;
  border-radius: 15px;
  transform: translateX(-50%);
  cursor: ns-resize;
  background-color: rgb(31, 31, 31)
}

/* Style for the north handler */
.nHandle, 
.react-resizable-handle.react-resizable-handle-n {
  width: 30px;
  height: 15px;
  top: -10px;
  left: 50%;
  border-radius: 15px;
  transform: translateX(-50%);
  cursor: ns-resize;
}

/* Style for the east handler */
.eHandle, 
.react-resizable-handle.react-resizable-handle-e {
  height: 30px;
  width: 15px;
  right: -10px;
  top: 50%;
  border-radius: 15px;
  transform: translateY(-50%);
  cursor: ew-resize;
}

/* Style for the south handler */
.sHandle, 
.react-resizable-handle.react-resizable-handle-s {
  width: 30px;
  height: 15px;
  bottom: -10px;
  left: 50%;
  border-radius: 15px;
  transform: translateX(-50%);
  cursor: ns-resize;
}

/* Style for the west handler */
.wHandle, 
.react-resizable-handle.react-resizable-handle-w {
  height: 30px;
  width: 15px;
  left: -10px;
  top: 50%;
  border-radius: 15px;
  transform: translateY(-50%);
  cursor: ew-resize;
}

.frameResizeHandler.whiteHandle, 
.dark-background-handle {
  background-color: white;
}

.frameResizeHandler.orangeHandle, 
.light-background-handle {
  background-color: black;
}

.topBar {
  top: 0;
  margin-top: -4px; /* Half the height of the bar to center it on the edge */
}

.bottomBar {
  bottom: 0;
  margin-bottom: -4px; /* Half the height of the bar to center it on the edge */
}

.frame1 {
  border-radius: 60px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  position: relative;
  box-sizing: border-box;
}

.imageframe1 {
  border-radius: 60px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  object-fit: cover;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
}

.frame2 {
  border-radius: 20px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  position: relative;
  box-sizing: border-box,
}

.imageframe2 {
  border-radius: 20px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  object-fit: cover;
  cursor: pointer;
  position: relative;
  box-sizing: border-box,
}

.frame3 {
  height: 200px;
  border-radius: 20px;  
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  position: relative;
  box-sizing: border-box,
}

.imageframe3 {
  height: 200px;
  border-radius: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  object-fit: cover;
  cursor: pointer;
  box-sizing: border-box,
}

.frame4 {
  aspect-ratio: 1/1;
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  position: relative;
}

.imageframe4 {
  aspect-ratio: 1/1;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  object-fit: cover;
  cursor: pointer;
  position: relative;
}

.frame5 {
  aspect-ratio: 2/3;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  position: relative;
}

.imageframe5 {
  aspect-ratio: 2/3;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  cursor: pointer;
  object-fit: cover;
  position: relative;
  box-sizing: border-box;
}

.frame6 {
  aspect-ratio: 2/3;
  border-radius: 250px 250px 20px 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  position: relative;
  box-sizing: border-box;
}

.imageframe6 {
  aspect-ratio: 2/3;
  border-radius: 250px 250px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  cursor: pointer;
  object-fit: cover;
  position: relative;
  box-sizing: border-box;
}

.frame7 {
  aspect-ratio: 3/5;
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  position: relative;
  box-sizing: border-box;
}

.imageframe7 {
  aspect-ratio: 3/5;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  cursor: pointer;
  object-fit: cover;
  position: relative;
  box-sizing: border-box;
}

.frame8 {
  aspect-ratio: 3/5;
  border-radius: 300px 300px 20px 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  position: relative;
  box-sizing: border-box;
}

.imageframe8 {
  aspect-ratio: 3/5;
  border-radius: 300px 300px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  cursor: pointer;
  object-fit: cover;
  position: relative;
  box-sizing: border-box;
}

.frame9 {
  aspect-ratio: 2.7/5;
  border-radius: 300px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  position: relative;
}

.imageframe9 {
  aspect-ratio: 2.7/5;
  border-radius: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  cursor: pointer;
  object-fit: cover;
  position: relative;
  box-sizing: border-box;
}

.frame10 {
  aspect-ratio: 3/4;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  position: relative;
}

.imageframe10 {
  aspect-ratio: 3/4;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  cursor: pointer;
  object-fit: cover;
  position: relative;
  box-sizing: border-box;
}

.frame11 {
  aspect-ratio: 3/4;
  border-radius: 300px 300px 20px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  position: relative;
}

.imageframe11 {
  aspect-ratio: 3/4;
  border-radius: 300px 300px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  cursor: pointer;
  object-fit: cover;
  position: relative;
  box-sizing: border-box;
}

.frame12 {
  aspect-ratio: 2.7/4;
  border-radius: 300px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  position: relative;
}

.imageframe12 {
  aspect-ratio: 2.7/4;
  border-radius: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  cursor: pointer;
  object-fit: cover;
  position: relative;
  box-sizing: border-box;
}

.frame13 {
  aspect-ratio: 1/1;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  position: relative;
}

.imageframe13 {
  aspect-ratio: 1/1;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  cursor: pointer;
  object-fit: cover;
  position: relative;
  box-sizing: border-box;
}

.frame14 {
  aspect-ratio: 1/1;
  border-radius: 300px 300px 20px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  position: relative;
}

.imageframe14 {
  aspect-ratio: 1/1;
  border-radius: 300px 300px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  cursor: pointer;
  object-fit: cover;
  position: relative;
  box-sizing: border-box;
}

.frame15 {
  aspect-ratio: 1/1;
  border-radius: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  position: relative;
}

.imageframe15 {
  aspect-ratio: 1/1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  cursor: pointer;
  object-fit: cover;
  position: relative;
  box-sizing: border-box;
}

.frame16 {
  aspect-ratio: 1/1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  position: relative;
}

.imageframe16 {
  aspect-ratio: 1/1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  cursor: pointer;
  object-fit: cover;
  position: relative;
}

.frame17 {
  aspect-ratio: 1/1;
  border-radius: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  box-sizing: border-box;
  position: relative;
}

.imageframe17 {
  aspect-ratio: 1/1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  box-sizing: border-box;
  cursor: pointer;
  object-fit: cover;
  position: relative;
}

.frame18 {
  height: 130px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  position: relative;
}

.imageframe18 {
  height: 130px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  cursor: pointer;
  object-fit: cover;
  position: relative;
}

.frame19 {
  height: 180px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  position: relative;
}

.imageframe19 {
  height: 180px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  cursor: pointer;
  object-fit: cover;
  position: relative;
}

.frame20 {
  aspect-ratio: 4/3;
  border-radius: 50% 20px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  position: relative;
}

.imageframe20 {
  aspect-ratio: 4/3;
  border-radius: 50% 20px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  cursor: pointer;
  object-fit: cover;
  position: relative;
}

.frame21 {
  aspect-ratio: 4/3;
  border-radius: 20px 50% 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  position: relative;
}

.imageframe21 {
  aspect-ratio: 4/3;
  border-radius: 20px 50% 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  cursor: pointer;
  object-fit: cover;
  position: relative;
}

.frame22 {
  aspect-ratio: 4/3;
  border-radius: 20px 20px 50% 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  position: relative;
}

.imageframe22 {
  aspect-ratio: 4/3;
  border-radius: 20px 20px 50% 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  cursor: pointer;
  object-fit: cover;
  position: relative;
}

.frame23 {
  aspect-ratio: 4/3;
  border-radius: 20px 20px 20px 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  position: relative;
}

.imageframe23 {
  aspect-ratio: 4/3;
  border-radius: 20px 20px 20px 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  cursor: pointer;
  object-fit: cover;
  position: relative;
}

.frame24 {
  aspect-ratio: 4/3;
  border-radius: 50% 20px 50% 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  position: relative;
}

.imageframe24 {
  aspect-ratio: 4/3;
  border-radius: 50% 20px 50% 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  cursor: pointer;
  object-fit: cover;
  position: relative;
}

.frame25 {
  aspect-ratio: 4/3;
  border-radius: 20px 50% 20px 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  position: relative;
}

.imageframe25 {
  aspect-ratio: 4/3;
  border-radius: 20px 50% 20px 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-sizing: border-box;
  cursor: pointer;
  object-fit: cover;
  position: relative;
}

.frame26 {
  aspect-ratio: 3/5;
  border-radius:  20px 20px 300px 300px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  position: relative;
}

.imageframe26 {
  aspect-ratio: 3/5;
  border-radius:  20px 20px 300px 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  cursor: pointer;
  object-fit: cover;
  position: relative;
  box-sizing: border-box;
}

.frame27 {
  aspect-ratio: 3/4;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  position: relative;
}

.imageframe27 {
  aspect-ratio: 3/4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  cursor: pointer;
  object-fit: cover;
  position: relative;
  box-sizing: border-box;
}

.frame28 {
  aspect-ratio: 3/4;
  border-radius: 20px 20px 300px 300px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  position: relative;
}

.imageframe28 {
  aspect-ratio: 3/4;
  border-radius: 20px 20px 300px 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  cursor: pointer;
  object-fit: cover;
  position: relative;
  box-sizing: border-box;
}

.frame29 {
  height: 90px;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  position: relative;
}

.imageframe29 {
  height: 90px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  cursor: pointer;
  object-fit: cover;
  position: relative;
  box-sizing: border-box;
}

.frame30 {
  aspect-ratio: 1/1;
  border-radius: 20px 20px 300px 300px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  position: relative;
}

.imageframe30 {
  aspect-ratio: 1/1;
  border-radius: 20px 20px 300px 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  cursor: pointer;
  object-fit: cover;
  position: relative;
  box-sizing: border-box;
}

.image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000; /* Adjust as needed */
}
