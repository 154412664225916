.imageFrameBar {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: fixed;

  width: calc(99.9% - 20px);

  top: 0;
  left: 0;
  
  border: solid rgba(255, 255, 255, 0.1) 2px;
  border-top: none;
  background: rgba(208, 215, 215, 0.6);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 1100;

  border-radius: 0 0 20px 20px;
  padding: 10px;

  box-shadow: 
    rgba(0, 0, 0, 0.18) 10px 5px 15px -10px, 
    rgba(0, 0, 0, 0.05) 0px 5px 10px -10px, 
    rgba(0, 0, 0, 0.155) 0px 25px 40px -5px, 
    rgba(0, 0, 0, 0.01) 0px 35px 10px -10px, 
    rgba(0, 0, 0, 0.08) 0px 15px 15px -10px;
}

.imageFrameBarSection1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.imageFrameBorderBtn  {
  height: 36px;
  width: 36px;
  cursor: pointer;
}

.imageFrameDeleteIcon {
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.imageFrameOpacityBtn {
  height: 28px;
  width: 28px;
  cursor: pointer;
}

.imageFrameBorderPopupDiv {
  width: calc(100% - 20px);
  margin: 0 10px;
  box-sizing: border-box;
  padding: 5px;
  max-height: calc(100vh - 100px);
  overflow: scroll
}

.imageFrameBorderPopupDiv, .frameTypesPopup {
  position: fixed;
  top: 70px;
  left: 0;
  font-family: 'Poppins';
  height: fit-content;
  background: rgba(208, 215, 215, 0.7);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 1005;
  border-radius: 18px;
  border: solid rgba(255, 255, 255, 0.3) 2px;
  padding: 5px;
  cursor: pointer;
  box-shadow: 
    rgba(0, 0, 0, 0.5) 15px 15px 15px -10px, 
    rgba(0, 0, 0, 0.25) 0px 5px 10px -10px, 
    rgba(0, 0, 0, 0.155) 0px 25px 40px -5px, 
    rgba(0, 0, 0, 0.01) 0px 35px 10px -10px, 
    rgba(0, 0, 0, 0.08) 0px 15px 15px -10px;
}

.frameBorderWidth, .imageFrameBorderRadius {
  display: flex;
  flex-direction: column;
  margin: 8px 0;
}

.imageFrameBorderRadiusFirstSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.frameBorderStyleText, .frameBorderWidthText, 
.imageFrameBorderRadiusText, .frameBorderColorText {
  font-weight: bold;
  margin-left: 10px;
}

.frameBorderWidthInputSection, 
.imageFrameBorderRadiusInputSection {
  display: flex;
  flex-direction: row;
}

.frameBorderWidthInput, .imageFrameBorderRadiusInput {
  height: 25px;
  width: 25px;
  border-radius: 5px;
  border: none;
  box-shadow: -2px -2px 2px rgb(255 255 255 / 25%), 2px 2px 4px rgb(0 0 0 / 45%); 
}

.frameBorderWidthInput, .imageFrameBorderRadiusInput, 
.topLeftCornerInput, .topRightCornerInput, 
.bottomLeftCornerInput, .bottomRightCornerInput {
  border-radius: 8px;
  font-family: 'Poppins';
  text-align: center;
  height: 25px;
  width: 25px;
  border: none;
}

.imageFrameBorderRadius, .frameBorderColor {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: rgb(240, 238, 238);
  box-shadow: -2px -2px 2px rgb(255 255 255 / 25%), 2px 2px 4px rgb(0 0 0 / 45%); 
  padding-top: 8px;
  padding-bottom: 8px;
}

.frameBorderWidthInput, .frameBorderWidthPixelText, 
.imageFrameBorderRadiusInput, .imageFrameBorderRadiusPixelText {
  margin-right: 5px;
}

.imageFrameBorderRadiusCornerDropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  margin: 5px 5px 0px 5px;
  border-radius: 10px;
  box-shadow: -2px -2px 2px rgb(255 255 255 / 25%), 2px 2px 4px rgb(0 0 0 / 45%); 
  width: calc(100% - 10px);
  box-sizing: border-box
}

.imageFrameCornerSectionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.imageFrameCornerDropdownContainer {
  width: 100%;
  margin: 10px 5px;
  box-sizing: border-box;
}

.imageFrameCornerDropdownTopSection, .imageFrameCornerDropdownBottomSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
}

.imageFrameCornerSection1 {
  display: flex;
  flex-direction: row;
}

.imageFrameCornerIcon {
  height: 20px;
  width: 20px;
  margin: 5px;
  filter: invert(1)
}

.imageFrameCornerSectionText {
  margin: 2px;
}

.imageFrameCornerSectionDropdownIcon {
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 7.5px;

  cursor: pointer;
  transform: rotate(180deg);
  filter: invert(1);
  margin: 5px;
}

.frameBorderWidthInputSection, .frameBorderRadiusInputSection {
  display: flex;
  flex-direction: row;
}

.frameBorderWidthInput, .frameBorderWidthPixelText, 
.imageFrameBorderRadiusInput, .imageFrameBorderRadiusPixelText {
  margin-right: 5px;
}

.topLeftCornerIcon, .topRightCornerIcon, 
.bottomLeftCornerIcon, .bottomRightCornerIcon {
  filter: invert(1);
  height: 30px;
  width: 30px;
}

.topLeftCornerDiv, .topRightCornerDiv, 
.bottomLeftCornerDiv, .bottomRightCornerDiv {
  display: flex;
  align-items: center;
  height: fit-content;
  margin: 10px;
}

.frameTypeColour {
  margin-top: 10px
}