.assetPopupDiv {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;

  padding: 10px;
  margin: 0 5px;
  width: calc(100% - 10px);
  box-sizing: border-box;
  top: 70px;

  height: fit-content;
  border: solid rgba(255, 255, 255, 0.2) 2px;
  border-radius: 20px;
  background: rgba(208, 215, 215, 0.4);
  
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  font-family: 'Poppins';
  font-weight: bold;

  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  z-index: 1000;
}

.assetPopupHeading {
  text-align: center;
  margin: 0px 10px 10px 10px;
}

.imageAssetUploadDiv {
  flex-direction: column;
}

.imageAssetPlusContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 140px;
  height: 200px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  object-fit: cover;
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px; */
  cursor: pointer;
}

.imageAssetUploadProgressBar {
  width: 90%;
  height: 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 3;
  -webkit-appearance: none;
  appearance: none;
  bottom: 10px;
}

.imageAssetUploadProgressBar::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.imageAssetUploadProgressBar::-moz-progress-bar {
  background-color: rgba(238, 238, 238, 0.651);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.imageAssetUploadProgressBar::-webkit-progress-value {
  background-color: #D75759;
  box-shadow: 1px 1px 5px 3px rgba( 0, 0, 0, 0.1 );
  border-radius: 5px;
}

.imageAssetUploadProgressBar::-moz-progress-value {
  background-color: #D75759;
  box-shadow: 1px 1px 5px 3px rgba( 0, 0, 0, 0.1 );
  border-radius: 5px;
}

.uploadedImagePreview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 140px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
  cursor: pointer; 
}

.imageAssetDoneButton {
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  background-color: black;
  color: white;
  border-radius: 10px;
  margin-top: 20px;
  padding: 5px;
  width: 100%;
  border: none;
  cursor: pointer;
}

.imageAssetDoneButton:hover {
  filter: invert(1)
}

.stickerAssetDiv {
  display: flex;
  width: 100%;
  border-radius: 18px;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

.stickerAssetDivHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 10px;
}

.assetPopupLoadingDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  filter: brightness(0);
  margin: 80px auto;
  animation: spin2 2s infinite linear;
}

.assetPopupLoadingIcon {
  width: 40px;
  height: 40px;
}

.stickerAssetDivHeading, .imageAssetDivHeading, .assetPopupHeading {
  font-size: 20px;
}

.stickerAssetDivHeading, .imageAssetDivHeading, .stickerAssetDivBtn {
  color:rgba(0, 0, 0, 0.7);
  font-size: 16px;
  margin: 0 10px 10px 10px;
  text-align: center;
}

.imageAssetPlusIcon {
  width: 30px;
  height: 30px;
}

.stickerAssetDiv {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  position: relative;
  align-items: left;
  margin-bottom: 5px
}

.stickerAssetPreviewDiv {
  display: flex;
  flex-direction: row;
  width: calc(100% - 10px);
  overflow-x: scroll;
  margin-bottom: 10px;
  padding-left: 10px;
}

.stickerAssetPreview {
  width: 150px;
  height: 150px;
  cursor: pointer;
}

.stickerAssetPreviewArrow {
  display: flex;
  position: absolute;
  top: 50%;
  right: 5px;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;
}

.stickerAssetPreviewArrowIcon {
  height: 30px;
  width: 30px;
  transform: rotate(180deg);
  filter: invert(1);
}