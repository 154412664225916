.shippingOptions {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 98%;
  border-radius: 20px;
  margin: 20px 0 20px 0;
  background: rgba(208, 215, 215, 0.4);
  border: solid rgba(255, 255, 255, 0.1) 2px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 2;
}

.courierOptionsHeader {
  font-family: 'Poppins';
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  margin: 10px 0;
  cursor: default;
  font-weight: bold;
}

.shippingInfoIconContainer {
  position: absolute;
  top: 10px;
  right: 10px;

  margin-left: 8px;
  cursor: pointer;
  z-index: 1;
}

.shippingInfoIcon {
  width: 20px;
  height: 20px;
}

.shippingInfoIcon:hover {
  filter: invert(1)
}

.shippingInfoDiv {
  text-align: left;
  border-radius: 15px;
  padding: 5px 10px 10px 10px;
  background-color: white;
  color: black;
  margin: 0 10px 10px 10px;
  font-family: 'Poppins';
  z-index: 1;
}

.shippingInfoLogo {
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border-radius: 10px;
}

.shippingInfoText {
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0 10px 0;
}

.shippingInfoLogosContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.shippingMethodSteps {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0 0 0;
}

.aramexExplainerStep, .paxiExplainerStep, .pudoExplainerStep {
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
}

.partnerStoreLogos {
  display: flex;
  flex-direction: column
}

.aramexStores {
  width: 300px;
  height: 126.6px;
}

.paxiStores {
  width: 320px;
  height: 110.8px;
}

.aramex {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  width: 100%;
}

.aramexLogo, .pudoLogo, .paxiLogo {
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  margin-left: 9px;
}

.aramexOptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  background-color: rgb(241, 240, 240);
  border-radius: 10px;
  font-family: 'Poppins';
  align-items: center;
  font-size: 12px;
  margin: 0 9px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
}

.aramexOption1Text {
  margin: 10px 0 0 0;
  cursor: default;
}

.addProductAramexOption1 {
  margin-bottom: 0px;
  margin-left: 8px;
  cursor: default;
}

.pudoPrice, .paxiPrice {
  margin-top: 0px;
  margin-left: 8px;
  font-weight: bold;
  cursor: default;
}

.aramexPrice {
  margin-top: 0px;
  font-weight: bold;
  cursor: default;
}

.aramexcheckBox, .pudoCheckbox {
  width: 20px;
  height: 20px;
  margin: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  border: none;
}

.pudo {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  width: 100%;
}

.pudoLogo {
  height: 82px;
  margin-left: 9px;
}

.pudoSelections {
  outline: none;
  position: relative;
  z-index: 1;
  border: none;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: fit-content;
  background-color: rgb(241, 240, 240);
  border-radius: 10px;
  padding: 10px 0 10px 0;
  font-family: 'Poppins';
  font-weight: bold;
  align-items: left;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
}

.pudoOptionsDiv, .paxiOptionsDiv {
  flex-grow: 1;
  margin: 0 9px;
}

.pudoOptionsLockerToLocker, .pudoOptionsDoorToLocker, 
.pudoOptionsLockerToDoor, .paxiOptionsFastDelivery, 
.paxiOptionsSlowerDelivery {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: rgb(241, 240, 240);
  border-radius: 0 0 10px 10px;
  font-family: 'Poppins';
  padding-top: 10px;
  align-items: left;
  font-size: 12px;
  margin: 0 0 5px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  z-index: 1;
  top: -8px;
}

.pudoOption1, .pudoOption2, .pudoOption3, .pudoOption4, .pudoOption5 {
  margin-left: 0px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pudoOption1Text, .pudoOption2Text, .pudoOption3Text, .pudoOption4Text, .pudoOption5Text {
  margin: 10px 0 0 8px;
  cursor: default;
}

.pudoOption1Dimensions, .pudoOption3Dimensions, .pudoOption5Dimensions, 
.paxiOption1Dimensions, .paxiOption2Dimensions {
  margin: 0 0 0 8px;
  cursor: default;
}

.pudocheckBox1, .pudocheckBox2, .pudocheckBox3, .pudocheckBox4, .pudocheckBox5 {
  width: 20px;
  height: 20px;
  margin: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
}

.paxi {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  width: 100%;
}

.paxiLogo {
  height: 82px;
}

.paxiOptions {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: fit-content;
  background-color: rgb(241, 240, 240);
  border-radius: 10px;
  font-family: 'Poppins';
  align-items: left;
  font-size: 12px;
  margin: 0 9px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
}

.paxiOption1, .paxiOption2 {
  margin-left: 0px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.paxiOption1Text, .paxiOption2Text {
  margin: 10px 0 0 8px;
  cursor: default;
} 

.paxicheckBox1 {
  width: 20px;
  height: 20px;
  margin: 10px 10px 10px 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
}

.paxicheckBox2 {
  width: 20px;
  height: 20px;
  margin: 10px 10px 10px 36px;
  background-color: white;
  border-radius: 5px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
}

.selectAllDiv, .preselectFutureDiv, .preselectedAllDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 5px 10px;
  width: calc(100% - 20px);
  margin: 0 10px 10px 10px;
  font-family: 'Poppins';
  box-sizing: border-box;

}

.selectAllDivText {
  margin: 10px 0;
}

.preselectFutureDivText, .preselectedAllText {
  margin: 5px 0;
}

.preselectedAllText {
  width: 180px;
}

.selectAllDivText, .preselectFutureDivText, .preselectedAllText {
  font-size: 14px;
  font-weight: bold;
}

.selectAllButtons, .preselectFutureButtons {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-left: 20px;
}

.courierBtn {
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 14px;
  background-color: black;
  color: white;
  padding: 5px 15px 5px 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer; 
}

.courierBtn.selected {
  background-color: white; 
  color: black; 
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.2) 0px 10px 10px -5px;
}