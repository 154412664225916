/* PortraitLock.css */

.portraitLockContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.landscapeOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  font-family: 'Poppins';
  font-weight: bold;
  color: #fff;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  backdrop-filter: blur(40px);
}

.landscapeOverlay p {
  font-size: 1.5em;
  max-width: 80%;
}

/* Optional: Adjust font size for smaller screens */
@media (max-width: 600px) {
  .landscapeOverlay p {
    font-size: 1.2em;
  }
}
