.socialMediaManagerContainer {
  width: 95%;
  min-height: auto;
  height: auto;
  padding: 10px;
  border-radius: 20px;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  background: rgba(208, 215, 215, 0.3);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  margin-top: 20px;
  margin-right: 2%;

  font-family: 'Poppins';
  font-size: 14px;
  overflow-y: auto;
}

.socialMediaManagerHeader {
  background: #edd8fa;
  border-radius: 20px 20px 0px 0px;
  
  position: fixed;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  text-align: center;
  margin: -10px 0 0 -10px;
  font-weight: bold;
  font-size: 16px;
}

.socialMediaLinkInputDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0 0;
  box-sizing: border-box;
}

.socialMediaManagerLinkInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  border: none;
  background: white;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  font-family: 'Poppins';
  font-size: 14px;

  box-sizing: border-box;
  padding: 10px 0 10px 10px;

  cursor: pointer
}

.socialMediaManagerLinksList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 50px 0 10px 0;
  height: 40px;
}