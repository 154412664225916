.cartStoreProductObject {
  width: 46%;
  margin: 10px;
  height: 250px;
  position: relative;
}

.cartStoreProduct {
  position: absolute;
  height: 100%;
  width: 100%;
}

.shippingNeededButton {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
}

.shippingNeededIcon {
  width: 35px;
  height: 35px
}

.cartProductPrice {
  display: flex;
  position: absolute;
  justify-content: right;
  align-items: right;
  top: 5px;
  right: 5px;

  padding: 0 13.26px 0 13.26px;
  background-color: white;
  color: black;
  border-radius: 10px;

  margin-left: auto; 
  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 2px 2px;
  font-family: 'Poppins';
  font-weight: bold;
  cursor: pointer;
}

.cartProductPrice:hover {
  background-color: #ED8951;
}

.cartStoreProductImage {
  height: 250px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
}

.cartProductIconDiv {
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  margin: 10px;
  width: calc(100% - 20px);
}

.cartStoreProductDelete {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.cartStoreProductDelete:hover {
  filter: drop-shadow(0px 5px 5px #831d2580);
}