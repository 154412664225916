.vendorOpeningPopupDiv {
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  bottom: 0px;
  width: 100%;

  border-radius: 20px 20px 0 0;
  background: #E87674;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  font-family: 'Poppins';
}

/* .explainerPaperTexture {
  position: fixed;
  height: 110%;
  width: 100%;
  z-index: 1;
  mix-blend-mode: multiply;
  filter: contrast(150%) opacity(50%) brightness(100%) invert(15%);

} */

@media only screen and (min-width: 450px) {
  .vendorOpeningPopupDiv {
    width: 450px;
    height: 490px;
  }
}

@media only screen and (min-width: 620px) {
  .vendorOpeningPopupDiv {
    width: 550px;
    height: 470px;
  }
}

@media only screen and (min-width: 900px) {
  .vendorOpeningPopupDiv {
    width: 700px;
    height: 460px;
  }
}

.vODHeader {
  color: #9E1C1A;
  font-weight: 900;
  margin: 15px 5px 5px 5px;
  font-size: 25px;
  cursor: default;
}

.vODDescription {
  font-size: 16px;
  margin: 0 10px;
  cursor: default;
}

.createStoreBtn, .vendorLoginBtn {
  border-radius: 10px;
  margin: 3px 8px 3px 0;
  padding: 10px 0;
  background: black;
  color: white;

  text-align: center;
  align-content: center;
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 700;
  cursor: pointer;

  width: 100%;
  border: none;
}

.noStoreExistsDiv {
  text-align: left;
}

.storeExistsDiv {
  text-align: center;
}

.noStoreExistsDiv, .storeExistsDiv {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 10px;
  margin-top: 10px;
  font-size: 13px;
}

.storeExistsText, .noStoreExistsText1 {
  margin: 5px 0;
}

.noStoreExistsText {
  padding: 0 10px;
}

.storeExistsText {
  text-align: center;
}

@keyframes flow {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.vendorLoginBtn:hover, .createStoreBtn:hover {
  filter: invert(1);
}