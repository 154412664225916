.stickerBar {
  display: flex;
  flex-direction: row;
  position: fixed;

  width: calc(100% - 20px);
  height: 52px;
  top: 0;
  left: 0;
  
  border: solid rgba(255, 255, 255, 0.1) 2px;
  border-top: none;
  background: rgba(208, 215, 215, 0.6);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 1000;

  border-radius: 0 0 25px 25px;
  padding: 5px 10px;

  box-shadow: 
    rgba(0, 0, 0, 0.18) 10px 5px 15px -10px, 
    rgba(0, 0, 0, 0.05) 0px 5px 10px -10px, 
    rgba(0, 0, 0, 0.155) 0px 25px 40px -5px, 
    rgba(0, 0, 0, 0.01) 0px 35px 10px -10px, 
    rgba(0, 0, 0, 0.08) 0px 15px 15px -10px;
  
  justify-content: space-between;
  align-items: center;
}

.stickerBarOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.stickerZIndexBtn, .stickerDimensions {
  font-family: 'Poppins';
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
  cursor: pointer;
}

.stickerZIndexBtn:hover {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: rgba(50, 50, 93, 0.1) 0px 15px 25px -12px inset, rgba(0, 0, 0, 0.2) 0px 18px 36px -18px inset, 
    rgba(0, 0, 0, 0.1) inset 0 2px 10px 0,   /* Top border */
    rgba(0, 0, 0, 0.1) inset 2px 0 10px 0,   /* Left border */
    rgba(0, 0, 0, 0.04) inset 0 -1px 0 0,  /* Bottom border */
    rgb(0, 0, 0, 0.04) inset -1px 0 0 0  /* Right border */ ;
}

.stickerDimensionPopup {
  display: flex;
  flex-direction: row;
  font-family: 'Poppins';
  position: fixed;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Poppins';
  height: fit-content;
  width: fit-content;
  background: rgba(208, 215, 215, 0.7);
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  z-index: 1005;
  border-radius: 15px;
  border: solid rgba(255, 255, 255, 0.1) 2px;
  padding: 5px;
  gap: 10px;
  cursor: pointer;
  box-shadow: 
    rgba(0, 0, 0, 0.18) 10px 5px 15px -10px, 
    rgba(0, 0, 0, 0.05) 0px 5px 10px -10px, 
    rgba(0, 0, 0, 0.155) 0px 25px 40px -5px, 
    rgba(0, 0, 0, 0.01) 0px 35px 10px -10px, 
    rgba(0, 0, 0, 0.08) 0px 15px 15px -10px;
}

.stickerHeight, .stickerWidth {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.591);
  padding: 10px;
  border-radius: 10px;
}

.vendorStickerDeleteIcon {
  margin: 5px;
  width: 36px;
  height: 36px;
  cursor: pointer;
}
