.stickerDiv {
  position: absolute;
  background-color: transparent;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.hide-handles .react-resizable-handle {
  display: none;
}

/* Ensure handles are on top and can be grabbed */
.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 4px;
  z-index: 9999;        /* 1) make sure they appear above the image */
  pointer-events: auto; /* 2) they must be clickable */
  touch-action: none;   /* 3) helps on touch devices to let you 'drag' */
}

/* corners with correct cursors */
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: nesw-resize;
}

.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: nwse-resize;
}

.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nwse-resize;
}

.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: nesw-resize;
}

.sticker {
  position: absolute;
  width: 250px;
  height: 250px;
  object-fit: fill;
  background-color: transparent;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  /* pointer-events: auto or none depending on your use-case 
     If you keep auto, you can drag from the image. 
     If you set none, you have to drag from the parent container. */
  pointer-events: auto;
  cursor: pointer;
}
