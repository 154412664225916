.createStoreDiv {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
}

.cSNoStore {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;

  background: rgba(208, 215, 215, 0.3);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border: solid rgba(255, 255, 255, 0.1) 2px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  border-radius: 20px;
  margin: 10px;
  z-index: 4;
  height: fit-content;
  width: calc(100% - 200px);

  padding: 10px 15px;
  font-size: 14px;
  font-family: 'Poppins';
}

.cSNoStoreBtn {
  border-radius: 15px;
  margin: 10px 8px 3px 0;
  padding: 10px 0;
  background: black;
  color: white;

  text-align: center;
  align-content: center;
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 700;
  cursor: pointer;

  width: 100%;
  border: none;
}

.createStoreHeader {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0
}

.createStoreBack {
  white-space: nowrap;
  width: fit-content;
  border-radius: 15px;
  margin-top: 10px;
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.8);
  background-color: #F5C56B;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
}

.createStoreBackIcon {
  width: 35px;
  height: 35px;
  padding: 5px 15px;
  filter: opacity(0.6);
}

.createStoreBack:hover {
  filter: brightness(50%);
  box-shadow: none;
}

.createStoreBackIcon:hover {
  filter: opacity(0.8);
}

.createStoreFirstSection, .createStoreSecondSection, .createStoreThirdSection{
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.basicInfoDiv, .businessInfoDiv {
  display: flex;
  flex-direction: column;
  align-items: center;

  background: rgba(208, 215, 215, 0.3);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border: solid rgba(255, 255, 255, 0.1) 2px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  border-radius: 20px;
  margin: 10px;
  z-index: 4;
  height: fit-content;
  width: 300px;
}

.businessLogoDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: rgba(208, 215, 215, 0.3);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border: solid rgba(255, 255, 255, 0.1) 2px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  border-radius: 20px;
  margin: 10px;
  z-index: 4;
  height: fit-content;
  width: 300px;
}

.createStoreNoProductImageDiv {
  font-family: 'Poppins';
  font-size: 14px;
  margin: 10px 10px 20px 10px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.714);
  cursor: default;
}

.passwordDiv {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

#nameSurname, #email, #password, #businessName {
  background: #f5f5f5;
  width: 90%;
  height: 30px;
  border-radius: 5px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
}

.showPasswordIcon {
  position: absolute;
  width: 23px;
  height: 23px;
  bottom: 11px;
  right: 20px;
  float: right;
  cursor: pointer
}

.basicInfoHeader, .businessInfoHeader, .passwordText {
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90%;
  color: rgba(0, 0, 0, 0.7);
}

.secondSectionButtonDiv, .thirdSectionButtonDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nextBtn1, .nextBtn2, .backBtnCS, .backBtnCS2 {
  width: 100px;
}

.completeStoreCreationBtn {
  width: 120px;
}

.nextBtn1, .nextBtn2, .backBtnCS, .backBtnCS2, .completeStoreCreationBtn {
  margin: 10px;
  position: relative;
  top: 0px;
  float: right;
  background-color: black;
  color: white;
  border: none;
  height: 30px;
  border-radius: 15px;
  font-family: 'Poppins';
  font-weight: 500;
  cursor: pointer;
  z-index: 100;
}


.nextBtn2:disabled, .completeStoreCreationBtn:disabled {
  background-color: rgba(0, 0, 0, 0.353);
  border: none;
  cursor: not-allowed;
}

#businessDescription {
  background: #f5f5f5;
  min-width: 90%;
  max-height: 130px;
  min-height: 130px;
  border-radius: 5px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 10px;
}

#businessName {
  margin-top: 10px;
}

.storeNameExistsDiv {
  font-family: 'Poppins';
  font-size: 14px;
  margin: 0px 10px 15px 10px;
  border-radius: 10px;
  padding: 5px 8px;
  background-color: #E87674;
}

#industries {
  background: #f5f5f5;
  width: 91%;
  height: 30px;
  border-radius: 5px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  overflow: scroll;
  cursor: pointer;
}

.chooseImageButton {
  display: flex;
  flex-direction: row;
  font-family: 'Poppins';
  width: fit-content;
  font-size: 14px;
  background-color: black;
  border-radius: 12px;
  color: white;
  margin: 10px 0px 10px 10px;
  padding: 6px;
  cursor: pointer;
}

#inputField {
  display: none;
}

#uploadDiv {
  display: flex;
  height: fit-content;
  width: 100%;
  margin-top: 10px;
}

.createStorePlusIcon {
  margin-left: 10px;
  width: 18px;
  height: 18px;
  filter: invert(1);
}

.createStorePlusIcon:hover {
  filter: invert(32%) sepia(79%) saturate(1574%) hue-rotate(5deg) brightness(102%) contrast(107%);
}

#uploadedImage {
  height: 150px;
  width: 150px;
  position: left;
  object-fit: cover;
  border-radius: 10px;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  z-index: 1;
}

#delete {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: rgba(208, 215, 215, 0.6);
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 25px -5px, rgba(0, 0, 0, 0.25) 0px 10px 10px -5px;

  margin-left: -25px;
  margin-top: -5px;

  height: 30px;
  width: 30px;
  border-radius: 15px;
  cursor: pointer;
  z-index: 2;
  
}

#delete:hover {
  filter: invert(1);
}

#deleteIcon {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 15px;
  width: 15px;
}

.cSAlreadyLoggedIn {
  display: none;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;

  background: rgba(208, 215, 215, 0.3);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border: solid rgba(255, 255, 255, 0.1) 2px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  border-radius: 20px;
  margin: 10px;
  z-index: 4;
  height: fit-content;
  width: fit-content;

  padding: 10px 25px;
  font-family: 'Poppins';
}

.returnToVendor { 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';

  width: 100%;
  font-size: 14px;
  background-color: black;
  border-radius: 12px;
  color: white;
  margin: 15px 0 0 0;
  padding: 10px 15px;
  cursor: pointer;
  border: none;
}

.createStoreProgressBar {
  width: 130px;
  height: 10px;
  border-radius: 5px;
  position: relative;
  z-index: 3;
  -webkit-appearance: none;
  appearance: none;
  top: 140px;
  right: 150px;
}

.createStoreProgressBar::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.createStoreProgressBar::-moz-progress-bar {
  background-color: rgba(238, 238, 238, 0.651);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.createStoreProgressBar::-webkit-progress-value {
  background-color: #D75759;
  box-shadow: 1px 1px 5px 3px rgba( 0, 0, 0, 0.1 );
  border-radius: 5px;
}

.createStoreProgressBar::-moz-progress-value {
  background-color: #D75759;
  box-shadow: 1px 1px 5px 3px rgba( 0, 0, 0, 0.1 );
  border-radius: 5px;
}