.stickerPopupSticker {
  margin: 0px 5px 10px 5px;
  width: 30%;
  aspect-ratio: 1/1;
  border-radius: 10px;
  cursor: pointer;
}

.stickerPopupSticker:hover {
  border-radius: 10px;
  background-color: #00000032;
}

.stickerPopupBackArrow {
  display: flex;
  position: absolute;
  top: 0px;
  left: 10px;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
}

.stickerPopupBackArrowIcon {
  height: 30px;
  width: 30px;
  filter: invert(1);
}

.stickerPopup {
  user-select: none;
  position: fixed;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  padding: 5px 5px 0px 5px;

  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: left;
  align-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  z-index: 1000;
}

.stickerSelectorHeader {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  color: rgba(0, 0, 0, 0.8);
}

.stickerTags {
  position: absolute;
  top: 50px;
  height: fit-content;
  width: calc(100% - 10px);
  left: 5px;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.StickerTag3D, .vintageStickerTag, .cartoonStickerTag {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px 3px 5px;
  margin: 0 5px 5px 5px;
  border-radius: 10px;
  color: white;
  background-color: black;
  cursor: pointer;
}

.redStickerTag {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px 3px 5px;
  margin: 0 5px 5px 5px;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.5);
  background-color: #E74441;
  cursor: pointer;
}

.orangeStickerTag {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px 3px 5px;
  margin: 0 5px 5px 5px;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.5);
  background-color: #FD8700;
  cursor: pointer;
}

.yellowStickerTag {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px 3px 5px;
  margin: 0 5px 5px 5px;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.5);
  background-color: #F5C56B;
  cursor: pointer;
}

.greenStickerTag {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px 3px 5px;
  margin: 0 5px 5px 5px;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.5);
  background-color: #739E82;
  cursor: pointer;
}

.blueStickerTag {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px 3px 5px;
  margin: 0 5px 5px 5px;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.5);
  background-color: #79b4fc;
  cursor: pointer;
}

.purpleStickerTag {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px 3px 5px;
  margin: 0 5px 5px 5px;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.5);
  background-color: #B083C6;
  cursor: pointer;
}

.pinkStickerTag {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px 3px 5px;
  margin: 0 5px 5px 5px;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.5);
  background-color: #F8948F;
  cursor: pointer;
}

.beigeStickerTag {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px 3px 5px;
  margin: 0 5px 5px 5px;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.5);
  background-color: #EFC6A9;
  cursor: pointer;
}

.whiteStickerTag {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px 3px 5px;
  margin: 0 5px 5px 5px;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.5);
  background-color: #FFFFFF;
  cursor: pointer;
}

.brownStickerTag {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px 3px 5px;
  margin: 0 5px 5px 5px;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.8);
  background-color: #523936;
  cursor: pointer;
}

.blackStickerTag {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px 3px 5px;
  margin: 0 5px 5px 5px;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.8);
  background-color: #000000;
  cursor: pointer;
}

.stickerSection {
  display: flex;
  flex-direction: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: left;
  overflow-y: scroll;
  border-radius: 0 0 16px 16px;
  margin: 45px 0px 0px 0px;
  left: 0px;
  height: fit-content;
  max-height: 100%;
  width: 100%;
}

.stickerInfoIcon {
  width: 20px;
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 8px;
}

.stickerInfoIcon:hover {
  filter: invert(0.5);
}

.stickerLoadMoreDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
}

.stickerLoadMoreBtn {
  border-radius: 25px;
  margin: 3px 8px 8px 0;
  background-color: black;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  color: white;
  padding: 3px 12px 3px 12px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  font-family: 'Poppins';
  width: fit-content;
}