/* ParcelUploadComponent.css */

.parcelUploadContainer {
  position: relative;
  margin: 5px;
  padding: 10px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.1);
}

.parcelUploadInfoDiv {
  position: absolute;
  right: 10px;
  top: 40px;
  border-radius: 10px;
  width: 350px;
  padding: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 40px 25px -5px, 
              rgba(0, 0, 0, 0.259) 0px 15px 10px -5px;
  z-index: 3;
}

.parcelUploadImgInfoIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
}

.parcelUploadHeader {
  font-weight: bold;
  margin-bottom: 8px;
}

.parcelImagesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
}

.parcelImageWrapper {
  position: relative;
  display: inline-block; /* or appropriate display */
}

.parcelImage {
  height: 160px;
  width: 112px;
  position: left;
  object-fit: cover;
  border-radius: 10px;
  margin: 10px 15px 10px 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  z-index: 1;
}

#parcelImgUploadDeleteBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2px;
  right: 2px;

  background: rgba(208, 215, 215, 0.6);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 25px -5px, rgba(0, 0, 0, 0.25) 0px 10px 10px -5px;

  margin-left: -25px;
  margin-top: -5px;

  height: 30px;
  width: 30px;
  border-radius: 15px;
  cursor: pointer;
  z-index: 2;
}

#parcelImgUploadDeleteBtn:hover {
  filter: invert(1);
}

.parcelDeleteIcon {
  height: 15px;
  width: 15px;
  margin: 5px;
  cursor: pointer;
}

.parcelProgressBar {
  width: 92px;
  height: 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 3;
  -webkit-appearance: none;
  appearance: none;
  bottom: 20px;
  left: 10px;
}

.parcelProgressBar::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.parcelProgressBar::-moz-progress-bar {
  background-color: rgba(238, 238, 238, 0.651);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.parcelProgressBar::-webkit-progress-value {
  background-color: #D75759;
  box-shadow: 1px 1px 5px 3px rgba( 0, 0, 0, 0.1 );
  border-radius: 5px;
}

.parcelProgressBar::-moz-progress-value {
  background-color: #D75759;
  box-shadow: 1px 1px 5px 3px rgba( 0, 0, 0, 0.1 );
  border-radius: 5px;
}

.parcelAddImageLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 112px;
  height: 160px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.parcelAddImageIcon {
  width: 24px;
  height: 24px;
}

.parcelUploadError {
  color: red;
  margin-top: 8px;
}

