/* Overlay covering the entire screen */
.fullScreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.7); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  backdrop-filter: blur(40px);
}

/* Popup container */

.fullScreenPopupGradientCanvas {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 150%;
  margin: 0;
  filter: blur(40px);
  background: #EFC6A9;
  z-index: 0;
}

.fullScreenPopupText {
  margin: 8px 0 16px 0;
}

.fullScreenPopup {
  background-color: rgba(215, 206, 206, 0.35);
  font-family: 'Poppins', sans-serif;
  padding: 10px 20px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 25px;
  text-align: center;
  box-shadow: 
    rgba(0, 0, 0, 0.18) 10px 5px 15px -10px, 
    rgba(0, 0, 0, 0.05) 0px 5px 10px -10px, 
    rgba(0, 0, 0, 0.155) 0px 25px 40px -5px, 
    rgba(0, 0, 0, 0.01) 0px 35px 10px -10px, 
    rgba(0, 0, 0, 0.08) 0px 15px 15px -10px;
}

.QRCode {
  border-radius: 15px;
  width: 350px;
  height: 350px
}
