.opacityPopupContainer {
  position: absolute;
  top: 80px; 
  left: 50%;
  transform: translateX(-50%);
  border-radius: 18px;
  padding: 5px 10px;
  z-index: 1500; /* Ensure it's above other elements */
  width: 300px; 

  border: solid rgba(255, 255, 255, 0.1) 2px;
  background: rgba(208, 215, 215, 0.8);
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);

  box-shadow: 
    rgba(0, 0, 0, 0.18) 10px 5px 15px -10px, 
    rgba(0, 0, 0, 0.05) 0px 5px 10px -10px, 
    rgba(0, 0, 0, 0.155) 0px 25px 40px -5px, 
    rgba(0, 0, 0, 0.01) 0px 35px 10px -10px, 
    rgba(0, 0, 0, 0.08) 0px 15px 15px -10px;
}

.opacityPopupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.opacityPopupHeading {
  font-family: 'Poppins';
  font-weight: bold
}

.closeOpacityPopupBtn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.opacityPopupContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.opacityInputSection {
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: center;
}

.opacityInput {
  border-radius: 8px;
  font-family: 'Poppins';
  text-align: center;
  height: 30px;
  width: 30px;
  border: none; 
  box-shadow: -2px -2px 2px rgb(255 255 255 / 25%), 2px 2px 4px rgb(0 0 0 / 45%); 
  margin-right: 5px;
}

.opacityPercentage {
  font-family: 'Poppins';
  font-size: 16px;
  margin-left: 3px
}
