.websiteTextDivMessage {
  position: fixed;
  border-radius: 12px;
  font-family: 'Poppins';
  z-index: 1002;

  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  height: fit-content;
  left: 0;   
  right: 0;        
  margin: 0 auto;
  top: 10px;

  width: 350px;
  padding: 5px 10px;

  border: solid rgba(255, 255, 255, 0.05) 2px;
  background: rgba(255, 255, 255, 0.5);

  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  cursor: pointer;
}

.websiteProductSectionTop {
  position: relative;
  width: 100%;
  top: 0;
  left: 0
}

.websiteProductSectionClose {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 1
}

.websiteProductSection {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  position: fixed;
  max-width: 600px;
  width: calc(100% - 20px);
  height: fit-content;
  max-height: calc(100% - 100px);
  overflow-y: scroll;
  border-radius: 20px;
  background: rgba(244, 246, 246, 0.5);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
  z-index: 1500;
  padding: 5px 5px 0 5px;
  
  bottom: 1.5%;
  transform: translateX(-50%);
  box-sizing: border-box;
  
  column-count: 2;
  -moz-column-count: 2; 
  -webkit-column-count: 2;
  column-gap: 0px;
}

@media (min-width: 1000px) {
  .websiteProductSection {
    width: 700px;
    height: 100%;
    top: 10px;
    right: 10px;
    transform: none;
    z-index: 1100;
    
    /* Optional: Adjust left to better position on the right half */
    /* left: calc(50% + 150px); */
  }
  .websiteProductSectionTop {
    position: absolute;
  }
}

.websiteProductSectionHeader {
  font-family: 'Poppins';
  font-size: 22px;
  font-weight: bold;
  margin: 5px 10px 10px 15px;
  width:fit-content
}


.websiteProduct {
  width: 48%;
  max-width: 470px;
  height: 240px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  margin-left: 7px;
  margin-bottom: 15px;

  position: relative;
  display: inline-block;
}

.websiteProductPrice {
  position: absolute;
  padding: 0 13.26px 0 13.26px;
  
  justify-content: right;
  align-items: right;
  background-color: white;
  color: black;
  border-radius: 10px;
  top: 5px;
  right: 0px;
  height: fit-content;
  margin-left: auto; 
  margin-right: 5px;
  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 2px 2px;
  font-family: 'Poppins';
  font-weight: bold;
  cursor: pointer;
  z-index: 1;
}

.websitePopupStoreProduct {
  width: 100%;
  height: inherit;
  object-fit: cover;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.emptyWebsiteProductSection {
  padding: 5px 10px 30px 10px;
  font-family: 'Poppins';
  font-size: 14px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.websiteCardPopupDiv {
  display: flex;
  flex-direction: column;
  position: fixed;
  justify-content: center;
  align-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(80px);
  -webkit-backdrop-filter: blur(80px);

  z-index: 1001;
}

.websiteCardPopupContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: 'Poppins'
}

.websiteCardClose {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;

  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 15px;
  cursor: pointer
}

#websiteBackIcon {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 30px;
  width: 30px;
  padding: 5px;
}