.storelessUserGradientCanvas {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 150%;
  margin: 0;
  filter: blur(40px);
  background: #EFC6A9;
  z-index: 0;
}

.storelessUserLoadingDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  filter: brightness(0);
  margin: 40px auto;
  transition: opacity 1s ease-in-out;
  animation: spin 2s infinite linear;
}

.storelessUserLoadingDiv.hidden {
  opacity: 0;
  pointer-events: none;
}

.storelessUserLoadingIcon {
  width: 40px;
  height: 40px;
}

.createStoreDiv2 {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 100;
}

.createStore2Header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0
}

.createStoreBack2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  white-space: nowrap;
  width: fit-content;
  
  border-radius: 15px;
  margin: 10px;
  color: rgba(255, 255, 255, 0.8);
  background-color: #F5C56B;
  cursor: pointer;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
  z-index: 2
}

.createStoreBackIcon2 {
  width: 35px;
  height: 35px;
  padding: 5px 15px;
  filter: opacity(0.6);
}

.createStoreBack2:hover {
  filter: brightness(50%);
  box-shadow: none;
}

.createStoreBackIcon2:hover {
  filter: opacity(0.8);
}

.createStoreAccountHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: fit-content;
  padding: 10px;
  border-radius: 15px;
  background: #C9DCB3;
  font-family: 'Poppins';
  font-size: 14px;

  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px
}

.logoPatternDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%
}

.logoPattern {
  height: 33px;
  width: 130.5px;
}

.createStoreAccountDiv {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 95vw;
}

@media only screen and (min-width: 450px) {
  .createStoreAccountDiv {
    width: 435px
  }
}

.cSAlreadyLoggedIn2 {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;

  background: rgba(208, 215, 215, 0.3);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border: solid rgba(255, 255, 255, 0.1) 2px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  border-radius: 20px;
  margin: 10px;
  z-index: 4;
  height: fit-content;
  width: fit-content;

  padding: 10px 25px;
  font-size: 14px;
  font-family: 'Poppins';
}

.returnToVendor2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';

  width: 100%;
  font-size: 14px;
  background-color: black;
  border-radius: 12px;
  color: white;
  margin: 10px 0 5px 0;
  padding: 10px 15px;
  cursor: pointer;
  border: none;
}

.returnToVendor2:hover {
  filter: invert(1);
}

.returnToVendorIcon {
  width: 15px;
  height: 15px;
  filter: brightness(250%);
  margin: 0 10px 0 5px;
}

.createStoreNoProductImageDiv2 {
  font-family: 'Poppins';
  font-size: 14px;
  margin: 10px 10px 20px 15px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.714);
  cursor: default;
}
