.sharePopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(14px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.sharePopupContent {
  position: fixed;
  border-radius: 20px;
  font-family: 'Poppins';
  z-index: 1002;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: fit-content;
  left: 0;   
  right: 0;        
  margin: 0 auto;
  top: 75px;

  width: 350px;
  padding: 10px;

  border: solid rgba(255, 255, 255, 0.05) 2px;
  background: rgba(255, 255, 255, 0.5);

  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  cursor: pointer;
}

.closeSharePopupBtn {
  position: absolute;
  top: 15px;
  right: 13px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}


.shareBtnGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-around;
}

.shareButtonLink, .shareButton {
  background: #f5f5f5;
}

.shareButtonLink, .QRCodeDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.downloadQRCodeBtn {
  background-color: #C9DCB3;
}

.shareButton, .downloadQRCodeBtn, .shareButtonLink {
  padding: 10px;
  cursor: pointer;
  font-family: 'Poppins';
  font-weight: bold;

  border-radius: 10px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  text-align: left;
}

.shareButtonLinkIcon {
  width: 25px;
  height: 25px
}

.shareButtonQRIcon {
  width: 30px;
  height: 30px
}

.downloadQRCodeBtn {
  width: 100%;
  margin-top: 10px;
}

.sharePopupHeader {
  font-family: 'Poppins';
  font-weight: bold;
  margin: 0 0 15px 5px;
  text-align: left;
  font-size: 22px;
}

.qrCodeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0 0;
}
