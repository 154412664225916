.marketSearchDiv, .scoutSearchDiv {
  display: flex;
  height: fit-content;
  position: relative;
  z-index: 4;
}

.marketSearch {
  width: 100%;
  height: 35px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 10px;
  margin: 0 0 0 auto ;
  overflow-y: hidden;
  z-index: 3;
  background: rgba(208, 215, 215, 0.5);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.35) 0px 10px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
  border: none;
  font-family: 'Poppins';
  font-weight: bold;
  outline: none;
}

.marketSearchIconActive {
  right: 35px;
}

.marketSearchIcon {
  right: 8px;
}

.marketSearchIconActive, .marketSearchIcon {
  position: absolute;
  top: 50%;

  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  filter: invert(1);
  cursor: pointer;
  z-index: 3;
}

.marketClearTextIcon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  cursor: pointer;
  z-index: 3;
}