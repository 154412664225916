.storeUploadedProductsHeader, .storeProductStatusHeader, .bundlingSettingsHeader {
  background: #edd8fa;
  border-radius: 20px 20px 0px 0px;
  
  position: fixed;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  text-align: center;
  margin: -10px 0 0 -10px;
  font-weight: bold;
  font-size: 16px;
}


.storeUploadedProducts, .storeProductStatus {
  padding: 10px;
}

.storeUploadedProducts, .storeProductStatus, .bundlingSettingsDiv {
  width: 95%;
  height: fit-content;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  background: rgba(208, 215, 215, 0.3);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  margin-top: 30px;
  margin-right: 2%;
  margin-bottom: 30px;

  font-family: 'Poppins';
  font-size: 14px;
}

.productOptionsButtonsDiv, .productStatusOptionsButtonsDiv, .bundlingOptionsButtonsDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}


.bundlingSettingsBtnActive, .bundlingProductsBtnActive, .productsPublishedBtnActive, .productsPendingBtnActive, .productsEditingBtnActive, .productsSentBtnActive {
  background-color: #B894CA;
  color: rgba(0, 0, 0, 0.9);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.2) 0px 10px 10px -5px;
  width: 43.5%;
  cursor: pointer;
  font-family: 'Poppins';
  font-size: 14px;
  border-radius: 12px;
  margin: 5px 0px;
  cursor: pointer;
  border: none;
  padding: 5px 10px;
  text-align: center;
}

.bundlingSettingsBtn, .bundlingProductsBtn, .bundlingEditSaveButton, .bundlingEditCancelButton, .productsPublishedBtn, .productsPendingBtn, .productsEditingBtn, .productsSentBtn {
  width: 43.5%;
  cursor: pointer;
  background-color: black;
  font-family: 'Poppins';
  font-size: 14px;
  color: white;
  border-radius: 12px;
  margin: 5px 0px;
  cursor: pointer;
  border: none;
  padding: 5px 10px;
  text-align: center;
}

.bundlingSettingsBtn:hover, .bundlingProductsBtn:hover, .bundlingSettingsBtnActive, .bundlingProductsBtnActive, .productsPublishedBtn:hover, .productsPendingBtn:hover, .productsEditingBtn:hover, .productsSentBtn:hover {
  background-color: #B894CA;
  color: rgba(0, 0, 0, 0.9);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.2) 0px 10px 10px -5px;
}


.dashboardNoProducts {
  font-family: 'Poppins';
  text-align: center;
  margin: 20px 5px 20px 5px;
}

.storesProducts {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f5f5f55e;
  min-height: 65px;
  max-height: fit-content;
  padding: 5px;
  border-radius: 15px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  margin: 10px 5px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  width: inherit;
}

.storesProductsSection1 {
  width: calc(100% - 75px);
}

.editStoresProductsSection1 {
  width: calc(100% - 55px);
}

.storesProductsSection1, .editStoresProductsSection1, .storesProductsSection2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.storesProductImg {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
  margin-right: 12px;
}

.storesProductInfo {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins';
  font-weight: bold;
  width: calc(100% - 65px);
  margin-right: 5px;
}

.storesProductQuantity, .storesProductBundlingQuantity {
  padding: 10px 20px 10px 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background: #B894CA;
  border-radius: 10px;
  margin-right: 10px;
}

.eyeIcon {
  width: 40px;
  height: 40px;
}

.deleteProductIcon {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.editProductIcon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-left: 5px;
}