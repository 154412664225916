.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.popupContent {
  position: absolute;
  bottom: 0;
  left: 0;
  height: fit-content;
  font-family: 'Poppins';
  border-radius: 25px 25px 0 0;
  height: 150px;
  background-color: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.deleteConfirmationBtnDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.deleteConfirmationNoBtn, .deleteConfirmationYesBtn {
  width: 48%;
  font-family: 'Poppins';
  font-size: 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.deleteConfirmationYesBtn {
  background-color: #D75759;
}
