.createStorePopupDiv {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  margin: auto;

  padding: 5px;
  width: fit-content;
  height: fit-content;
  border-radius: 15px;
  background: #E87674;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  
  font-family: 'Poppins';
}

.createStorePopupText {
  margin: 5px;
}

.createStoreBtn2 {
  border-radius: 10px;
  margin: 3px;
  background: black;

  color: white;
  padding: 5px;
  text-align: center;
  align-content: center;
  font-size: 14px;

  font-family: 'Poppins';
  font-weight: 700;
  cursor: pointer;

  width: 315px;
  border: none;
}

.createStoreBtn2:hover {
  filter: invert(1);
}