/* BundlingDiv.css */

/* Container Styles */
.bundlingSettingsDiv {
  padding: 10px 10px 0 10px;
}

.bundlingSettingsHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  box-sizing: border-box;
  /* Additional styles if needed */
}

.bundlingDivContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bundlingOptionsButtonsDiv {
  display: flex;
  flex-direction: row;
}

/* Button Styles */
.bundlingSettingsBtn,
.bundlingProductsBtn {
  cursor: pointer;
  /* Base styles for buttons */
  padding: 5px 10px;
  border-radius: 12px;
  margin: 0 5px;
  background-color: black;
  transition: background-color 0.3s;
}

.bundlingSettingsBtnActive,
.bundlingProductsBtnActive {
  background-color: #B083C6;
  color: black;
}

.bundlingSettingsBtn:hover,
.bundlingProductsBtn:hover {
  background-color: #B083C6;
}

/* Description Styles */
.bundlingSettingsDescriptionDiv {
  margin: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  background-color: #f1e7f7aa;
  border-radius: 15px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
}

.bundlingDescriptionText {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #473550;
}

/* Edit Button Styles */
.bundlingEditButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bundlingEditSaveButton,
.bundlingEditCancelButton {
  cursor: pointer;
  background-color: black;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: white;
  border-radius: 10px;
  margin: 5px 0px 2px 10px;
  border: none;
  padding: 5px 10px;
}

.bundlingEditSaveButton:hover,
.bundlingEditCancelButton:hover {
  background-color: #333;
}

/* Product List Styles */
.bundlingProductsDiv {
  width: 100%;
}

.bundledStoresProducts {
  display: flex;
  justify-content: space-between;
  background: #f5f5f55e;
  min-height: 65px;
  padding: 5px;
  border-radius: 15px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 
              2px 2px 4px rgba(0, 0, 0, 0.45);
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  margin-top: 10px;
}

.storesProductsSection1 {
  display: flex;
  align-items: center;
}

.storesProductImg {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 10px;
}

.storesProductInfo {
  display: flex;
  flex-direction: column;
}

.storesProductName {
  font-weight: bold;

}

.storesProductsSection2 {
  display: flex;
  align-items: center;
}

.storesProductBundlingQuantity {
  font-weight: bold;
  color: #B083C6;
}

/* Bundling Settings Container */
.bundlingSettingContainer {
  width: 100%;
  margin-top: 10px;
}

/* Input Styles */
#dashboardBundlingBox {
  height: 40px;
  width: 40px;
  text-align: center;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  color: black;
  background: #B894CA;
  border-radius: 10px;
  margin-right: 10px;
  border: none;
}

/* Shipping Method Styles */
.aramexBundling,
.pudo,
.paxi {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  width: 100%;
}

.aramexBundlingLogo,
.pudoBundlingLogo,
.paxiBundlingLogo {
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 
              2px 2px 4px rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  height: 83px;
  width: 102px;
}

.aramexBundlingOptions,
.paxiBundlingOptions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  background-color: rgb(241, 240, 240);
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  align-items: center;
  font-size: 12px;
  margin: 0 0 0 9px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 
              2px 2px 4px rgba(0, 0, 0, 0.45);
}

.paxiBundlingOptions {
  flex-direction: column;
  padding: 10px 0;
}

.aramexOption1,
.paxiBundlingOption1,
.paxiBundlingOption2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-left: 0px;
}

.paxiBundlingOption1 {
  margin-bottom: 10px;
}

.aramexOption1Container,
.paxiBundlingOption1Text,
.paxiBundlingOption2Text,
.pudoBundlingText {
  margin: 0 0 0 8px;
  cursor: default;
}

.aramexBundling1Text {
  margin: 0;
}

.pudaBundlingPrice {
  margin: 0 0 0 8px;
  font-weight: bold;
}

.aramexBundlingPrice {
  font-weight: bold;
  margin: 0
}

.pudoBundling, .paxiBundling {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.pudoBundlingOptionsDiv {
  width: 100%;
  margin-left: 9px;
  height: fit-content;
}

.pudoBundlingSelections {
  height: fit-content;
  margin-bottom: 10px;
}

.pudoSelectionsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: none;
  border-radius: 10px;
  background-color: rgb(241, 240, 240);
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  padding: 10px 5px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 
              2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  position: relative;
  z-index: 1001;
}

.paxiBundlingOptionsDiv {
  margin-left: 10px;
  width: 100%
}

.bundlingDropdownIcon,
.bundlingDropdownIcon2 {
  width: 12px;
  height: 7.5px;
  margin-right: 12px;
  cursor: pointer;
}

.bundlingDropdownIcon {
  transform: rotate(180deg);
}

.pudoBundlingOptionsLockerToLocker,
.pudoBundlingOptionsDoorToLocker,
.pudoBundlingOptionsLockerToDoor, 
.paxiBundlingOptionsFastDelivery,
.paxiBundlingOptionsSlowerDelivery {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgb(241, 240, 240);
  border-radius: 0 0 10px 10px;
  font-family: 'Poppins', sans-serif;
  padding-top: 20px;
  font-size: 12px;
  margin: -8px 0 5px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 
              2px 2px 4px rgba(0, 0, 0, 0.45);
  z-index: 900;
}

.pudoOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}

.pudoOption1Container,
.pudoOption2Container {
  display: flex;
  flex-direction: column;
}

.pudoOption1Dimensions, .pudoOption2Dimensions, .pudoOption3Dimensions, 
.pudoOption4Dimensions, .pudoOption5Dimensions {
  margin: 0 0 0 8px
}

.pudoBundlingPrice, .paxiBundlingPrice {
  margin: 0 0 0 8px;
  font-weight: bold;
}

.paxiOption {
  margin-bottom: 15px;
}

.bundlingEditButton {
  position: absolute;
  width: 22px;
  z-index: 101;
  right: 10px;
  cursor: pointer;
}

.bundlingEditButton:hover {
  filter: invert(28%) sepia(38%) saturate(1624%) hue-rotate(246deg) brightness(88%) contrast(84%);
}

