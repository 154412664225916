.notificationPopupContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  height: fit-content;
  position: absolute;
  font-family: 'Poppins';
  width: 90%;
  top: 60px;
  right: 10px;
  margin-top: -5px;
  border: solid rgba(255, 255, 255, 0.05) 2px; 
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 15px;
  box-sizing: border-box;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  z-index: 20;
}

@media only screen and (min-width: 450px) {
  .notificationPopupContainer {
    width: 435px;
  }
}

.readNotificationsGroup {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  height: fit-content;
  border-radius: 15px;
  justify-content: center;
  align-items: left;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
  margin: 5px;
  background: rgba(208, 215, 215, 0.3);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  font-family: 'Poppins';
  font-size: 14px;
}

.emptyUnreadyNotificationsMessage {
  margin: 5px 10px;
}

.notificationsDropdownContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.unreadNotificationsHeading, .readNotificationsHeading {
  font-size: 16px;
  font-weight: 600;
  margin: 10px;
  opacity: 80%;
}

.notificationItem, .noSalesMessage {
  font-family: 'Poppins';
  position: relative;
  background-color: white;
  border-radius: 12px;
  font-size: 13px;
  margin: 5px;
}

.notificationItem {
  padding: 10px 10px 40px 10px;
}

.noSalesMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: rgb(203, 192, 124);
}

.notificationTime {
  position: absolute;
  bottom: 0;
  right: 5px;
  font-weight: bold;
  font-size: 11px;
  opacity: 80%;
  margin: 10px 5px;
}

.favouritesDropdownIcon {
  transform: rotate(180deg);
}

.favouritesDropdownIcon, .favouritesDropdownIcon2 {
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 7.5px;
  cursor: pointer;
  margin-right: 10px;
}