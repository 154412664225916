.marketTextDivMessage {
  border-radius: 12px;
  font-family: 'Poppins';
  z-index: 1002;

  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  height: fit-content;
  left: 0;   
  right: 0;        
  margin: 0 auto;
  top: 10px;

  width: 350px;
  padding: 5px 10px;

  border: solid rgba(255, 255, 255, 0.05) 2px;
  background: rgba(255, 255, 255, 0.5);

  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  cursor: pointer;
}

.marketScoutCardContainer {
  position: absolute; 
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.marketBackArrow {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;

  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 14px;
  cursor: pointer
}

.marketCardBlurredBackground {
  border-radius: inherit;
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
}

.marketBackArrow:hover {
  filter: invert(1);
}

#marketBackArrowIcon {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 30px;
  width: 30px;
}

.marketProductDiv {
  position: relative;
  display: inline-block;
  width: 93.5%;
  margin-bottom: 10px;
  font-family: 'Poppins';
}

.marketProductImage {
  font-family: 'Poppins';
  width: 100%;
  height: fit-content;
  object-fit: cover;
  border-radius: 20px; 
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 25px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -user-drag: none;
}

.marketProductImage:hover {
  box-shadow: none;
}

.marketPrice {
  position: absolute;
  padding: 0 13.26px 0 13.26px;
  
  justify-content: right;
  align-items: right;
  background-color: white;
  color: black;
  border-radius: 12px 15px 3px 12px;
  top: 5px;
  right: 0px;
  margin-left: auto; 
  margin-right: 5px;
  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 2px 2px;
  font-family: 'Poppins';
  font-weight: bold;
  cursor: pointer;
  z-index: 1;
}

.marketPrice:hover {
  background-color: #ED8951;
}

.gridDiv {
  width: 100%;
} 

.marketCardPopupDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  font-family: 'Poppins';
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  z-index: 1001;
}

.marketCardPopupContent {
  display: flex; /* Add this */
  justify-content: center; /* Add this */
  align-items: center; /* Add this */
  width: 100%;
  height: 100%;
}

.marketCardCloseBtn {
  position: absolute;
  bottom: 50px;
  width: 111px;
  cursor: pointer;
}

.marketCardCloseBtn:hover {
  filter: brightness(95%);
}

.marketProductContainer {
  position: relative;
  display: inline-block;
}
 
.marketProductPopupDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;

  width: 90px;
  height: 48px;
  border-radius: 15px;

  border: solid rgba(255, 255, 255, 0.2) 2px;
  border-radius: 15px;
  background: rgba(208, 215, 215, 0.5);

  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  transform: translate(-50%, -50%);
}

.popupDivShareIcon, .popupDivDeleteIcon {
  width: 30px;
  height: 30px;
  margin: 2px;
  padding: 5px;
  cursor: pointer;
}

.popupDivShareIcon:hover, .popupDivDeleteIcon:hover {
  padding: 5px;
  border-radius: 11px;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 15px 25px -12px inset, rgba(0, 0, 0, 0.2) 0px 18px 36px -18px inset, 
    rgba(0, 0, 0, 0.1) inset 0 2px 10px 0,   /* Top border */
    rgba(0, 0, 0, 0.1) inset 2px 0 10px 0,   /* Left border */
    rgba(0, 0, 0, 0.04) inset 0 -1px 0 0,  /* Bottom border */
    rgb(0, 0, 0, 0.04) inset -1px 0 0 0  /* Right border */ ;
}
