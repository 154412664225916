.pendingPaymentsSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  
  height: fit-content;
  width: calc(100% - 20px);
  margin: 10px;
  padding: 10px;
  border-radius: 20px;
  border: solid rgba(255, 255, 255, 0.1) 2px;
  background: rgba(208, 215, 215, 0.5);
  box-shadow: 7px 7px 25px rgba(0, 0, 0, 0.25);
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  z-index: 1;
}

.pendingPaymentsSectionHeader {
  margin: 5px 10px 10px 10px;
  font-size: 16px;
}

.singlePaymentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #f5f5f55e;
  min-height: 65px;
  padding: 5px;
  border-radius: 15px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25),
    2px 2px 4px rgba(0, 0, 0, 0.45);
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.singlePaymentTopRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.singlePaymentImagesWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.singlePaymentImage {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10px;
}

.extraProductsOverlay {
  background-color: rgba(0,0,0,0.7);
  color: #fff;
  margin-left: -10px;
  font-size: 0.8rem;
  padding: 5px;
  border-radius: 4px;
  align-self: center;
}

.singlePaymentHeading {
  flex: 1;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownIcon {
  transition: transform 0.3s ease;
}

.dropdownIcon.rotate {
  transform: rotate(180deg);
}

.singlePaymentDetails {
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  margin: 5px 5px 0 5px;
  background: #CB952A;
  box-sizing: border-box;
}

.singlePaymentDetailRow {
  margin: 5px 0;
  font-size: 0.9rem;
}

/* New row for Product Sent and Product Received */
.sentReceivedRow {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 8px;
}

/* Each box in that row */
.statusBox {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  font-weight: 600;
  border-radius: 5px;
  gap: 6px;
}

/* Icons inside those boxes */
.statusIcon {
  width: 12px;
  height: 12px;
  margin-left: 5px;
}

/* Conditional classes to highlight red/green */
.statusTrue {
  background-color: #C9DCB3;
  color: black;
}

.statusFalse {
  background-color: #dc4141;
  color: black;
}

.pendingPaymentsHeaderContainer {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative; /* Make sure the child can position absolutely */
}

.paymentInfoIcon {
  position: absolute;
  right: 0;
  top: 0;
  width: 18px;
  height: 18px;
  margin-left: auto; /* Push it to the far right */
  cursor: pointer;
}

.paymentInfoDiv {
  position: absolute;
  top: 40px; /* Adjust as necessary */
  right: 0;
  width: 250px; 
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  z-index: 3;
}

.paymentInfoText {
  margin-bottom: 10px;
}

.paymentInfoBtn {
  background-color: #CB952A;
  color: #fff;
  text-align: centre;
  padding: 5px 10px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.8rem;
}
