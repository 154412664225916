/* Layout.css */
button, a, img, .interactive-element {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
}

.hiddenNavBar {
  visibility: hidden;
}

body {
  margin: 0;
  padding: 0;
  background-color: black;
}

html {
  height: 100vh;
  width: 100%;
}

.navBarParent {
  position: fixed;
  display: flex;
  justify-content:center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  left: 50%;
  bottom: calc(1.25 * var(--vh));
  transform: translateX(-50%);
  z-index: 1003;
}

.navBarParentveryWide {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  left: 35px;
  top: 10px;
  transform: translateX(-50%);
  z-index: 1003;
}

.navBarHidden {
  display: none;
}

.navBarContainer, .navBarContainerveryWide {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  height: fit-content;
  width: 100%;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  background: rgba(255, 255, 255, 0.5);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.navBar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: fit-content;
  gap: 5px;
  height: 44px;
  padding-left: 15px;
  margin: 0;
}

.navBarveryWide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  height: 90%;
  margin-top: 15px;
  padding-left: 15px;
}

.navBar, #popup {
  transition: width 0.4s ease, height 0.4s ease;
}

#popupveryWide {
  transform: rotate(180deg);
}

.active {
  filter: brightness(0%);
}

#navBarIcons {
  list-style: none;
  border: 0;
  background-color: transparent;
  height: 30px;
  width: 30px;
  margin: 0px 14px 0 0; 
  align-items: center;
  justify-content: center;
}

#navBarIcons img {
  height: 30px;
  width: 30px;
  filter: brightness(110%);
}

#navBarIcons:active {
  filter: brightness(0%);
}

#navBarIcons:hover {
  filter: brightness(0%);
}

a {
  text-decoration: none;
}

#popup, #popupveryWide {
  display: flex;
  justify-content:center;
  align-items: center;
  background-color: transparent;
  width: 50px;
  height: 30px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

#popup img{
  height: 15px;
  width: 30px;
  cursor: pointer;
}

#popupveryWide img{
  height: 15px;
  width: 30px;
  cursor: pointer;
}

#popup:hover {
  filter: invert(1);
  background-color: white;
}

.popup::before {
  content: '';
  position: absolute;
  top: -15px;
  bottom: -15px;
  left: -10px;
  right: -10px;
  /* No background to keep it invisible */
  /* Optional: Add background with transparency for debugging */
  /* background: rgba(255, 0, 0, 0.1); */
  /* Ensures the pseudo-element captures the clicks */
}

.offlineMessagePopupContainer, .productSavedMessagePopupContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0;
  height: 41px;
  z-index: 1001;
}

.offlineMessagePopupContainer {
  position: absolute;
}

.productSavedMessagePopupContainer {
  position: fixed;
  bottom: 130px;
  z-index: 1004;
}

.offlinePopupMessageDiv, .productSavedMessageDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 95vw;
  max-width: 470px;
  height: fit-content;
  border-radius: 15px;
  font-family: 'Poppins';
  text-align: center;
  font-size: 14px;
  background-color: #C9DCB3;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px;
  z-index: 1001;
  margin-top: 10px;
}
