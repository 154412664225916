.ethicalChecksPopupContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  backdrop-filter: blur(12px);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1002;
}

.ethicalChecksPopupDiv {
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: fit-content;
  background-color: #CAFFBD;
  border-radius: 20px 20px 0px 0px;
  font-family: 'Poppins';
  padding-bottom: 50px;
}

.ethicalChecksPopupHeader {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  width: 100%;
  margin: 10px 0;
}

.ethicalStickerImg1 {
  position: absolute;
  height: 120px;
  width: 120px;
  right: 0px;
  top: -50px;
  cursor: pointer;
  rotate: 10deg;
}

.ethicalChecksTagDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  margin: 5px 0;
}

.ethicalChecksContent {
  box-sizing: border-box;
  width: calc(100% - 20px);
  margin: 10px;
  padding: 5px;
  border-radius: 15px;
  background-color: #80B26F;
}

.ethicalChecksDescription {
  margin: 5px;
}