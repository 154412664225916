/* src/admin/components/Payments.css */

.payments-component {
  padding: 10px;
  font-family: 'Poppins';
}

.payments-description {
  font-size: 16px;
  margin-bottom: 15px;
  color: #555;
}

.download-button {
  padding: 10px 20px;
  background-color: #28a745; /* Green color */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #218838; /* Darker green on hover */
}

.payments-container {
  display: flex;
  flex-direction: column;
}

/* Table Styles */
.payments-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.payments-table th,
.payments-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.payments-table th {
  background-color: #f2f2f2;
}

.payments-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.payments-table tr:hover {
  background-color: #ddd;
}

/* Card Layout Styles */
.payments-cards {
  display: none; /* Hidden by default, shown on smaller screens */
  flex-direction: column;
  gap: 15px;
}

.payment-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.payment-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.payment-label {
  font-weight: bold;
  color: #333;
}

.payment-value {
  color: #555;
}

.error-message {
  color: red;
  font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 768px) {
  /* Hide table and show cards on screens <= 768px */
  .payments-table {
    display: none;
  }

  .payments-cards {
    display: flex;
  }
}
