.rounded-slider-container {
  width: 100%;
  padding: 10px 0;
  margin-right: 10px
}

.rounded-slider {
  width: 100%;
  height: 12px;
  border-radius: 6px;
  background: #d3d3d3;
  outline: none;
  padding: 0;
  margin: 0;
  transition: background 0.3s;
  -webkit-appearance: none;
}

.rounded-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: black;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  border: 2px solid #ffffff;
}

.rounded-slider::-webkit-slider-thumb:hover {
  background: rgb(140, 140, 140);
  transform: scale(1.1);
}

.rounded-slider::-moz-range-thumb {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgb(140, 140, 140);
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  border: 2px solid #ffffff;
}

.rounded-slider::-moz-range-thumb:hover {
  background: #45a049;
  transform: scale(1.1);
}
