#colourSquare {
  width: 100%;
  aspect-ratio: 1 / 1; /* Maintains square shape */
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25),
              2px 2px 4px rgba(0, 0, 0, 0.45);
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

#colourSquare:hover {
  transform: scale(1.05);
  box-shadow: -1px -1px 3px rgba(255, 255, 255, 0.3),
              1px 1px 6px rgba(0, 0, 0, 0.5);
}

.colourSquare.selected::before {
  display: block;
  position: absolute;
  top: 0px; /* Adjust as needed */
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 3px solid #00000091; /* Default border color */
  border-radius: 8px; /* Adjust to match parent */
  box-sizing: border-box;
}

.colourSquare.selected.dark::before {
  border-color: #ffffffe2; /* Use white border for dark colors */
}


/* Unique Background Colors */
.colour1 { background-color: #000000; }
.colour2 { background-color: #2E2E2E; }
.colour3 { background-color: #545454; }
.colour4 { background-color: #A6A6A6; }
.colour5 { background-color: #D9D9D9; }
.colour6 { background-color: #E8E8E8; }
.colour7 { background-color: #FFFFFF; }
.colour8 { background-color: #6D0E10; }
.colour9 { background-color: #961316; }
.colour10 { background-color: #BF181D; }
.colour11 { background-color: #FF0000; }
.colour12 { background-color: #E32227; }
.colour13 { background-color: #E84B4F; }
.colour14 { background-color: #FF8A8A; }
.colour15 { background-color: #7B3F00; }
.colour16 { background-color: #A36A00; }
.colour17 { background-color: #D18700; }
.colour18 { background-color: #F28500; }
.colour19 { background-color: #FFA500; }
.colour20 { background-color: #E8A14D; }
.colour21 { background-color: #FFD280; }
.colour22 { background-color: #757500; }
.colour23 { background-color: #A3A300; }
.colour24 { background-color: #D1D100; }
.colour25 { background-color: #F7C331; }
.colour26 { background-color: #FFD700; }
.colour27 { background-color: #FFFF00; }
.colour28 { background-color: #FDE890; }
.colour29 { background-color: #048037; }
.colour30 { background-color: #00A86B; }
.colour31 { background-color: #0EC75A; }
.colour32 { background-color: #00D100; }
.colour33 { background-color: #28FF00; }
.colour34 { background-color: #77DD77; }
.colour35 { background-color: #C8E163; }
.colour36 { background-color: #007575; }
.colour37 { background-color: #00A3A3; }
.colour38 { background-color: #53B0AE; }
.colour39 { background-color: #00D1D1; }
.colour40 { background-color: #89CFF0; }
.colour41 { background-color: #00FFFF; }
.colour42 { background-color: #B2FFFF; }
.colour43 { background-color: #191970; }
.colour44 { background-color: #212196; }
.colour45 { background-color: #2A2ABB; }
.colour46 { background-color: #3F3FD4; }
.colour47 { background-color: #6464DC; }
.colour48 { background-color: #8A8AE5; }
.colour49 { background-color: #AFAFED; }
.colour50 { background-color: #51087E; }
.colour51 { background-color: #6C0BA9; }
.colour52 { background-color: #880ED4; }
.colour53 { background-color: #A020F0; }
.colour54 { background-color: #8B52FE; }
.colour55 { background-color: #C576F6; }
.colour56 { background-color: #D7A1F9; }
.colour57 { background-color: #55002A; }
.colour58 { background-color: #820041; }
.colour59 { background-color: #B00058; }
.colour60 { background-color: #EF3A5D; }
.colour61 { background-color: #FE65C4; }
.colour62 { background-color: #F39AA5; }
.colour63 { background-color: #FFD1DC; }
.colour64 { background-color: #362624; }
.colour65 { background-color: #523936; }
.colour66 { background-color: #A07671; }
.colour67 { background-color: #923806; }
.colour68 { background-color: #E8A14D; }
.colour69 { background-color: #DA9E73; }
.colour70 { background-color: #FFE5B4; }
