.marketStoreInfoContainer {
  display: flex;
  flex-direction: column;
  width: 120px;
}

.marketStoreLogo {
  width: 105px;
  height: 105px;
  border-radius: 20px;
  margin: 5px 10px 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
  cursor: pointer;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -user-drag: none;
}

.marketStoreLogo:hover {
  box-shadow: none;
  width: 100px;
  height: 100px;
  margin: 13px;
}