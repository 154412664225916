.ethicalChecksContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin: 10px 10px 10px 10px;
  z-index: 4;
  height: fit-content;
  width: 98%;
  background: rgba(208, 215, 215, 0.4);
  border: solid rgba(255, 255, 255, 0.1) 2px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-sizing: border-box;
  backdrop-filter: blur(7px);
}

.ethicalChecksHeader {
  font-family: 'Poppins';
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
  margin: 10px 0;
  cursor: default;
}

.ethicalTagsDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 95%;
  margin-bottom: 20px;
}

.ethicalTagItem {
  background: black;
  color: white;
}

.ethicalTagItemActive {
  background: white;
  color: black;
}

.ethicalTagItem, .ethicalTagItemActive {
  border-radius: 10px;
  margin: 5px 8px 5px 0;
  padding: 5px;
  text-align: center;
  align-content: center;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
  cursor: pointer;
}

.ethicalTagText {
  margin: 0 10px 0 10px;
}

.ethicalChecksText {
  background: #f5f5f5;
  resize: none;
  width: calc(100% - 20px); /* Adjust the 10px to the sum of left and right margins */
  max-height: 130px;
  min-height: 130px;
  border-radius: 15px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box; /* Makes sure padding and border are included in the width */
}

.ethicalChecksIconContainer {
  position: absolute;
  top: 10px;
  right: 10px;

  margin-left: 8px;
  cursor: pointer;
  z-index: 1;
}

.ethicalChecksInfoIcon {
  width: 20px;
  height: 20px;
}

.ethicalChecksInfoIcon:hover {
  filter: invert(1)
}

.ethicalCheckInfoDiv {
  text-align: left;
  border-radius: 10px;
  padding: 10px;
  background-color: white;
  color: black;
  margin: 0 10px 10px 10px;
  font-family: 'Poppins';
  z-index: 1;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px
}